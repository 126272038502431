import { useCallback } from 'react';
import { Form, useField } from 'formik';
import { date, object, string } from 'yup';

import RemoveIcon from '@mui/icons-material/Remove';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import FormActions from 'components/FormActions';
import FormButton from 'components/FormButton';
import FormDatePicker from 'components/FormDatePicker';
import FormTextField from 'components/FormTextField';
import Section from 'components/Section';

import { ClubAttendantDetails } from 'schema';

type UserFields = {
  email: string;
  password: string;
  phone: string;
};

export type Fields = {
  firstName: string;
  lastName: string;
  dateOfBirth: Date;
  user?: UserFields;
};

type Props = {
  attendant: ClubAttendantDetails;
  loading?: boolean;
};

export const schema = object({
  firstName: string().required('Prašome įvesti vardą'),
  lastName: string().required('Prašome įvesti pavardę'),
  dateOfBirth: date().nullable().required('Prašome pasirinkti gimimo datą'),
  user: object({
    email: string()
      .email('Neteisingas el. pašto adreso formatas')
      .required('Prašome įvesti el. pašto adresą'),
    password: string(),
    phone: string(),
  })
    .nullable()
    .default(null),
});

const AttendantPersonalInfoForm = ({ attendant, loading = false }: Props) => {
  const [{ value }, , { setValue }] = useField<UserFields | undefined>('user');

  const handleOpenClick = useCallback(() => {
    setValue({
      email: '',
      password: '',
      phone: '',
    });
  }, [setValue]);

  const handleRemoveClick = useCallback(() => {
    setValue(undefined);
  }, [setValue]);

  return (
    <Form>
      {!value ? (
        <Alert
          severity="info"
          sx={{ mb: 2 }}
          action={
            <Button startIcon={<AccountCircleIcon />} onClick={handleOpenClick}>
              Sukurti
            </Button>
          }>
          Šis dalyvis dar neturi paskyros
        </Alert>
      ) : (
        <Section sx={{ mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h6" sx={{ flexGrow: 1, mb: 2 }}>
              Paskyra
            </Typography>

            {!attendant.user && (
              <IconButton onClick={handleRemoveClick}>
                <RemoveIcon />
              </IconButton>
            )}
          </Box>

          <Stack spacing={4}>
            <FormTextField name="user.email" label="El. pašto adresas" />

            <FormTextField
              name="user.password"
              type="password"
              label="Slaptažodis"
            />

            <FormTextField name="user.phone" label="Telefono numeris" />
          </Stack>
        </Section>
      )}

      <Section sx={{ mb: 2 }}>
        <Typography variant="h6">Dalyvio duomenys</Typography>

        <Stack spacing={4}>
          <Grid container spacing={2}>
            <Grid xs={6} sx={{ pl: 0 }}>
              <FormTextField fullWidth name="firstName" label="Vardas" />
            </Grid>

            <Grid xs={6} sx={{ pr: 0 }}>
              <FormTextField fullWidth name="lastName" label="Pavardė" />
            </Grid>
          </Grid>

          <FormDatePicker fullWidth name="dateOfBirth" label="Gimimo data" />
        </Stack>
      </Section>

      <Section>
        <FormActions>
          <FormButton type="submit" loading={loading}>
            Saugoti
          </FormButton>
        </FormActions>
      </Section>
    </Form>
  );
};

export default AttendantPersonalInfoForm;
