import Box, { BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ClubMemberRoles } from 'schema';

type Props = BoxProps & {
  title: string;
  roles: ClubMemberRoles;
};

const MemberRoles = ({ title, roles, ...props }: Props) => (
  <Box {...props}>
    <Typography variant="h6" color="text.secondary">
      {title}
    </Typography>

    {roles.isCoach && (
      <Typography color="text.secondary">- Treneris</Typography>
    )}

    {roles.isAdmin && (
      <Typography color="text.secondary">- Administratorius</Typography>
    )}
  </Box>
);

export default MemberRoles;
