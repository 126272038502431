import LogoutIcon from '@mui/icons-material/Logout';
import CircularProgress from '@mui/material/CircularProgress';
import ListItemButton, {
  ListItemButtonBaseProps,
} from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { useLogout } from 'features/Auth';
import { useCallback } from 'react';

const LogoutMenuItem = (props: ListItemButtonBaseProps) => {
  const logout = useLogout();

  const handleLogout = useCallback(() => {
    logout.mutate();
  }, [logout]);

  return (
    <ListItemButton {...props} onClick={handleLogout}>
      <ListItemIcon>
        {logout.isPending ? <CircularProgress size={24} /> : <LogoutIcon />}
      </ListItemIcon>

      <ListItemText primary="Atsijungti" />
    </ListItemButton>
  );
};

export default LogoutMenuItem;
