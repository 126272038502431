import Link from '@mui/material/Link';
import TableCell from '@mui/material/TableCell/TableCell';
import TableRow from 'components/TableRow';
import { formatDate } from 'lib';
import { ClubAttendantRegistrationListView } from 'schema';

type Props = {
  registration: ClubAttendantRegistrationListView;
};

const AttendantRegistrationsTableRow = ({ registration }: Props) => {
  const { session, startDate } = registration;

  return (
    <TableRow>
      <TableCell component="th">
        <Link
          href={`/activities/${session.activity.id}/sessions/${session.id}`}>
          {session.name}
        </Link>
      </TableCell>

      <TableCell component="th" align="right">
        {formatDate(startDate)}
      </TableCell>
    </TableRow>
  );
};

export default AttendantRegistrationsTableRow;
