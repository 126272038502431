import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from 'components/DialogTitle';
import Dialog from 'components/Dialog';
import InvoiceSyncDetails from 'components/InvoiceSyncDetails';
import InvoiceSyncDetailsSkeleton from 'components/InvoiceSyncDetailsSkeleton';

import { useDialog } from 'components/Dialogs';
import { useInvoice, useInvoiceSync } from 'features/Invoice';
import { ClubInvoiceListView } from 'schema';
import DialogContent from 'components/DialogContent';

type Props = {
  invoice: ClubInvoiceListView;
};

export type InvoiceSyncDialogProps = Props;

const InvoiceSyncDialog = ({ invoice }: Props) => {
  const [, { close }] = useDialog('invoiceSync');
  const details = useInvoice(invoice.id);
  const sync = useInvoiceSync(invoice.id);

  return (
    <Dialog dialog="invoiceSync" maxWidth="md">
      <DialogTitle onClose={close}>
        Sąskaitos {invoice.invoiceNumber} CRM sinchronizacija
      </DialogTitle>

      <DialogContent>
        {!details.data || !sync.data ? (
          <InvoiceSyncDetailsSkeleton />
        ) : (
          <InvoiceSyncDetails invoice={details.data} sync={sync.data} />
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={close} variant="contained">
          Uždaryti
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InvoiceSyncDialog;
