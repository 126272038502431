import { useCallback, MouseEvent } from 'react';

import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';

import { useDialog } from 'components/Dialogs';
import { CoachSessionDetailsView } from 'schema';

type Props = MenuItemProps & {
  session: CoachSessionDetailsView;
};

const SessionSendMessageMenuItem = ({ onClick, session, ...props }: Props) => {
  const [, { open }] = useDialog('sessionSendMessage');

  const handleClick = useCallback(
    (event: MouseEvent<HTMLLIElement>) => {
      open({ activity: session.activity, session });
      onClick && onClick(event);
    },
    [onClick, open, session]
  );

  return (
    <MenuItem onClick={handleClick} {...props}>
      Rašyti pranešimą
    </MenuItem>
  );
};

export default SessionSendMessageMenuItem;
