import { useDialog } from 'components/Dialogs';
import { useTabs } from 'components/Tabs';
import ToolbarButton from 'components/ToolbarButton';
import { useCallback } from 'react';
import { ActivityDetailsView } from 'schema';

type Props = {
  activity: ActivityDetailsView;
};

const ActivityEditAddButton = ({ activity }: Props) => {
  const [, { open: openSession }] = useDialog('sessionAdd');
  const [, { open: openShift }] = useDialog('shiftAdd');
  const [, { open: openDiscount }] = useDialog('quantityDiscountAdd');
  const { currentHash } = useTabs();

  const handleAddSessionClick = useCallback(() => {
    openSession({ activity });
  }, [activity, openSession]);

  const handleAddShiftClick = useCallback(() => {
    openShift({ activity });
  }, [activity, openShift]);

  const handelAddDiscountClick = useCallback(() => {
    openDiscount({ activity });
  }, [activity, openDiscount]);

  switch (currentHash) {
    case 'groups':
      return (
        <ToolbarButton tooltip="Nauja grupė" onClick={handleAddSessionClick} />
      );

    case 'shifts':
      return (
        <ToolbarButton tooltip="Nauja pamaina" onClick={handleAddShiftClick} />
      );

    case 'discounts':
      return (
        <ToolbarButton
          tooltip="Nauja kiekio nuolaida"
          onClick={handelAddDiscountClick}
        />
      );

    default:
      return null;
  }
};

export default ActivityEditAddButton;
