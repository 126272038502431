import { useField } from 'formik';
import { boolean, object, string } from 'yup';

import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import FormCheckbox from 'components/FormCheckbox';
import FormTextField from 'components/FormTextField';
import FormHelperText from '@mui/material/FormHelperText';
import Stack from '@mui/material/Stack';

type RoleFields = {
  isCoach: boolean;
  isAdmin: boolean;
};

export type Fields = {
  email: string;
  roles: RoleFields;
};

export const schema = object({
  email: string()
    .required('Prašome įvesti el. pašto adresą')
    .email('Neteisingas el. pašto formatas'),

  roles: object({
    isCoach: boolean(),
    isAdmin: boolean(),
  }).test({
    message: 'Prašome pasirinkti bent vieną rolę',
    test: ({ isCoach, isAdmin }) => isCoach || isAdmin,
  }),
});

const InviteMemberDialogForm = () => {
  const [, { error, touched }] = useField<RoleFields>('roles');

  const hasError = error && touched;

  return (
    <Stack spacing={4}>
      <FormTextField fullWidth name="email" label="El. pašto adresas" />

      <Grid container sx={{ color: hasError ? 'error.main' : undefined }}>
        <Grid xs={12}>
          <Typography
            variant="caption"
            color={hasError ? 'error.main' : undefined}>
            Rolės
          </Typography>
        </Grid>

        <Grid xs={6}>
          <FormControlLabel
            control={<FormCheckbox name="roles.isCoach" />}
            label="Treneris"
          />
        </Grid>

        <Grid xs={6}>
          <FormControlLabel
            control={<FormCheckbox name="roles.isAdmin" />}
            label="Administratorius"
          />
        </Grid>

        {hasError && (
          <Grid xs={12}>
            <FormHelperText sx={{ color: 'error.main' }}>
              {error}
            </FormHelperText>
          </Grid>
        )}
      </Grid>
    </Stack>
  );
};

export default InviteMemberDialogForm;
