import { useCallback } from 'react';

import Link from '@mui/material/Link';

import { useDialog } from 'components/Dialogs';
import {
  CoachAttendantRegistrationListView,
  CoachSessionDetailsView,
} from 'schema';

type Props = {
  session: CoachSessionDetailsView;
  registration: CoachAttendantRegistrationListView;
};

const SessionAttendantLink = ({ session, registration }: Props) => {
  const [, { open }] = useDialog('sessionRegistration');
  const { fullName } = registration.attendant;

  const handleClick = useCallback(() => {
    open({ session, registration });
  }, [open, registration, session]);

  return (
    <Link component="a" sx={{ cursor: 'pointer' }} onClick={handleClick}>
      {fullName}
    </Link>
  );
};

export default SessionAttendantLink;
