import Select, { SelectProps } from 'components/Select';

import { useClubSessions } from 'features/Club';
import { useClub } from 'components/ClubProvider';
import { SessionDropdownOption } from 'schema';

type Props = Omit<SelectProps<SessionDropdownOption>, 'options'>;

export type SessionSelectProps = Props;

const SessionSelect = (props: Props) => {
  const club = useClub();
  const query = useClubSessions(club.id);

  return <Select {...props} options={query.data ?? []} />;
};

export default SessionSelect;
