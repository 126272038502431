import { SyntheticEvent, useCallback } from 'react';
import MuiDialog, { DialogProps as MuiDialogProps } from '@mui/material/Dialog';

import { DialogType, useDialog } from 'components/Dialogs';

type Props = Omit<MuiDialogProps, 'open'> & {
  dialog: DialogType;
};

export type DialogProps = Props;

const Dialog = ({ dialog, onClose, ...props }: Props) => {
  const [isOpen, { close }] = useDialog(dialog);

  const handleClose = useCallback(
    (event: SyntheticEvent, reason: 'backdropClick' | 'escapeKeyDown') => {
      onClose && onClose(event, reason);
      close();
    },
    [close, onClose]
  );

  return (
    <MuiDialog
      fullWidth
      maxWidth="sm"
      open={isOpen}
      onClose={handleClose}
      scroll="body"
      {...props}
    />
  );
};

export default Dialog;
