import Typography from '@mui/material/Typography';

type Props = {
  user: {
    fullName: string;
    email: string;
    phone: string | null;
  };
};

const UserWithContacts = ({ user }: Props) => (
  <>
    <Typography>{user.fullName}</Typography>

    <Typography color="text.secondary" variant="body2">
      {user.email}
    </Typography>

    <Typography color="text.secondary" variant="body2">
      {user.phone}
    </Typography>
  </>
);

export default UserWithContacts;
