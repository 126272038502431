import TableCell from '@mui/material/TableCell';
import TableRow, { TableRowProps } from 'components/TableRow';
import { formatDateTime } from 'lib';
import { ClubOrderPayment } from 'schema';

type Props = TableRowProps & {
  payment: ClubOrderPayment;
};

const OrderPaymentsTableRow = ({ payment, ...props }: Props) => {
  return (
    <TableRow {...props}>
      <TableCell>{payment.paidAt && formatDateTime(payment.paidAt)}</TableCell>

      <TableCell align="center">Neopay</TableCell>
      <TableCell align="center">{payment.info}</TableCell>
      <TableCell align="right">{payment.amount} €</TableCell>
    </TableRow>
  );
};

export default OrderPaymentsTableRow;
