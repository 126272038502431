import Tab from '@mui/material/Tab';
import Tabs, { TabsProps } from '@mui/material/Tabs';

import { useCountsByStatus } from 'features/Order';
import { ClubOrderCountsByStatusParams, OrderStatus } from 'schema';

type Props = TabsProps & {
  value: OrderStatus;
  filter: ClubOrderCountsByStatusParams;
};

const OrdersTableStatusTabs = ({ filter, value, ...props }: Props) => {
  const counts = useCountsByStatus(filter).data;

  return (
    <Tabs value={value} {...props}>
      <Tab value="draft" label={`Ruošiamas [${counts?.draft ?? 0}]`} />
      <Tab value="payment" label={`Mokėjimas [${counts?.payment ?? 0}]`} />
      <Tab value="finalise" label={`Dokumentai [${counts?.finalise ?? 0}]`} />

      <Tab
        value="confirmation"
        label={`Patvirtinimas [${counts?.confirmation ?? 0}]`}
      />

      <Tab value="invoice" label={`Sąskaita [${counts?.invoice ?? 0}]`} />
      <Tab value="complete" label={`Užbaigtas [${counts?.complete ?? 0}]`} />
      <Tab value="canceled" label={`Atšauktas [${counts?.canceled ?? 0}]`} />
    </Tabs>
  );
};

export default OrdersTableStatusTabs;
