import { ReactNode } from 'react';

import Box, { BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextLabel from 'components/TextLabel';

type Props = BoxProps & {
  label: string;
  children: ReactNode;
};

const TextWithLabel = ({ label, children, ...props }: Props) => (
  <Box {...props}>
    <TextLabel>{label}</TextLabel>

    <Typography sx={{ pb: 2 }} color="text.secondary">
      {children}
    </Typography>
  </Box>
);

export default TextWithLabel;
