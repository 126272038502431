import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Skeleton from '@mui/material/Skeleton';

type Props = {
  tabs?: number;
};

const TabberSkeleton = ({ tabs = 2 }: Props) => (
  <Paper sx={{ mb: 2 }}>
    <Tabs value={0}>
      {Array(tabs)
        .fill(0)
        .map((_, i) => (
          <Tab label={<Skeleton width={150} height="2.2em" />} key={i} />
        ))}
    </Tabs>
  </Paper>
);

export default TabberSkeleton;
