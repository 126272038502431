import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

const EditorSkeleton = () => (
  <Box sx={{ p: '0 60px' }}>
    <Skeleton width={500} />
    <Skeleton width={400} />
    <Skeleton width={450} />
    <Skeleton width={300} />
    <Skeleton width={600} />
    <Skeleton width={250} />
    <Skeleton width={400} />
  </Box>
);

export default EditorSkeleton;
