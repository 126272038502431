import { array, boolean, date, number, object, string } from 'yup';
import { format } from 'date-fns/format';

import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormCheckbox from 'components/FormCheckbox';
import FormTextField from 'components/FormTextField';
import CoachSelect from 'components/CoachSelect';
import CitySelect from 'components/CitySelect';
import FormShiftSelect from 'components/FormShiftSelect';
import WeekDayScheduleField, {
  WeekDayScheduleFieldType,
} from 'components/WeekDayScheduleField';

import { makeTime, stringToNumber, Weekday } from 'lib';
import { TIME_FORMAT } from 'const';

import { SelectOption } from 'components/Select';
import EarlyBirdPricesField, {
  EarlyBirdPrice,
} from 'components/EarlyBirdPricesField';

import {
  ActivityDetailsView,
  ActivityType,
  CoachSessionDetailsView,
  ShiftView,
} from 'schema';

// TODO: infer types from yup schema definition
export type FormFields = {
  activity: { type: ActivityType };
  name: string;
  shift: ShiftView | null;
  coach: SelectOption | null;
  published: boolean;
  capacity: string;
  ageGroupFrom: string | null;
  ageGroupTo: string | null;
  city: SelectOption | null;
  address: string;
  weekdaySchedule: WeekDayScheduleFieldType[];
  price: string;
  prices: EarlyBirdPrice[];
};

export type Props = {
  activity: ActivityDetailsView;
};

export function initDayScheduleField(
  session?: CoachSessionDetailsView
): WeekDayScheduleFieldType[] {
  const fields = [];

  for (let i = 0; i <= 6; i++) {
    const weekday: Weekday = i + 1;

    const existing = session?.weekSchedule.find(
      item => item.weekDay === weekday
    );

    fields.push({
      enabled: !!existing || false,
      weekday,
      startTime: existing ? makeTime(existing.startTime) : null,
      endTime: existing?.endTime ? makeTime(existing.endTime) : null,
    });
  }

  return fields;
}

export function makeFormFields(
  values: FormFields,
  activity: ActivityDetailsView
) {
  const {
    coach,
    capacity,
    ageGroupFrom,
    ageGroupTo,
    city,
    price,
    weekdaySchedule,
    prices,
    ...form
  } = values;

  return {
    ...form,
    activity,
    coach: coach!,
    capacity: stringToNumber(capacity),
    ageGroupFrom: stringToNumber(ageGroupFrom),
    ageGroupTo: stringToNumber(ageGroupTo),
    city: city!,
    price: Number(price),
    weekdaySchedule: weekdaySchedule
      .filter(({ enabled }) => enabled)
      .map(({ weekday, startTime, endTime }) => ({
        weekday,
        startTime: format(startTime!, TIME_FORMAT),
        endTime: endTime && format(endTime!, TIME_FORMAT),
      })),
    prices: prices.map(({ price, ...rest }) => ({
      price: Number(price),
      ...rest,
    })),
  };
}

export const schema = object({
  activity: object({ type: string() }),
  name: string().nullable().required('Prašome įvesti grupės pavadinimą'),
  shift: object().nullable().required('Prašome pasirinkti pamainą'),
  coach: object().nullable().required('Prašome pasirinkti trenerį'),
  capacity: string().nullable().matches(/^\d+$/, 'Prašome įvesti skaičių'),
  city: object().nullable().required('Prašome pasirinkti miestą'),
  address: string().nullable(),
  weekdaySchedule: array().of(
    object({
      enabled: boolean(),
      startTime: date()
        .nullable()
        .typeError('Formatas hh:mm')
        .when('enabled', {
          is: true,
          then: schema => schema.required('Prašome įvesti pradžios laiką'),
        }),
      endTime: date().typeError('Formatas hh:mm').nullable(),
    })
  ),
  prices: array().of(
    object({
      validUntil: date()
        .nullable()
        .typeError('Formatas yyyy-mm-dd')
        .required('Prašome įvesti galiojimo datą')
        .when('$shift.start', ([start], schema) =>
          schema.test({
            message: 'Data turi būti prieš pamainos pradžią',
            test: value => value < start,
          })
        ),
      price: number()
        .typeError('Prašome įvesti skaičių')
        .required('Prašome įvesti kainą'),
    })
  ),
});

const SessionFormFields = ({ activity }: Props) => {
  return (
    <Box>
      <Stack spacing={3} sx={{ mb: 3 }}>
        <FormControlLabel
          label="Publikuota"
          control={<FormCheckbox name="published" />}
        />

        <FormTextField name="name" label="Grupės pavadinimas" />
        <FormShiftSelect activity={activity} name="shift" label="Pamaina" />
        <CoachSelect name="coach" label="Treneris" />
        <FormTextField name="capacity" label="Vietų skaičius" />

        <Grid container>
          <Grid xs={6} sx={{ pr: 1 }}>
            <FormTextField fullWidth name="ageGroupFrom" label="Amžius nuo" />
          </Grid>

          <Grid xs={6} sx={{ pl: 1 }}>
            <FormTextField fullWidth name="ageGroupTo" label="Amžius iki" />
          </Grid>
        </Grid>

        <Grid container>
          <Grid xs={6} sx={{ pr: 1 }}>
            <CitySelect name="city" label="Miestas" />
          </Grid>

          <Grid xs={6} sx={{ pl: 1 }}>
            <FormTextField fullWidth name="address" label="Adressas" />
          </Grid>
        </Grid>

        {activity.type === 'regular' && (
          <FormTextField
            name="price"
            label="Kaina"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">€/mėn</InputAdornment>
              ),
            }}
          />
        )}
      </Stack>

      {activity.type === 'camp' && (
        <>
          <Typography variant="overline">Kainodara</Typography>
          <Divider sx={{ mb: 1 }} />
          <EarlyBirdPricesField />
        </>
      )}

      {activity.type === 'regular' && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="overline">Tvarkaraštis</Typography>
          <Divider sx={{ mb: 3 }} />
          <WeekDayScheduleField name="weekdaySchedule" />
        </Box>
      )}
    </Box>
  );
};

export default SessionFormFields;
