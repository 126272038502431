import { forwardRef, ForwardRefRenderFunction } from 'react';

import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';

type Props = Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] };
type ComponentType = ForwardRefRenderFunction<HTMLAnchorElement, Props>;

const LinkBehavior: ComponentType = (props, ref) => {
  const { href, ...other } = props;

  return <RouterLink ref={ref} to={href} {...other} />;
};

export default forwardRef(LinkBehavior);
