import ClubMessagingForm from 'components/ClubMessagingForm';
import { useClub } from 'components/ClubProvider';
import { useClubSettings } from 'features/Club';

const ClubMessaging = () => {
  const settings = useClubSettings(useClub().id);

  if (!settings.data) return <>Kraunasi...</>;

  return <ClubMessagingForm settings={settings.data} />;
};

export default ClubMessaging;
