import BlockIcon from '@mui/icons-material/Block';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import FullScreen from 'components/FullScreen';

const MemberInvitationInvalid = () => (
  <FullScreen>
    <Paper sx={{ textAlign: 'center', p: 4 }}>
      <Box sx={{ fontSize: 96 }}>
        <BlockIcon fontSize="inherit" />
      </Box>

      <Typography variant="h2" sx={{ mb: 3 }}>
        Nėra tokio pakvietimo
      </Typography>
    </Paper>
  </FullScreen>
);

export default MemberInvitationInvalid;
