import { useCallback } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import ConfirmableIconButton from 'components/ConfirmableIconButton';
import TableRow from 'components/TableRow';
import UserAvatar from 'components/UserAvatar';

import { useDeleteClubMember } from 'features/Club';
import { useClub } from 'components/ClubProvider';
import { ClubMemberListView } from 'schema';
import Link from '@mui/material/Link';

type Props = {
  member: ClubMemberListView;
};

const ClubMembersTableRow = ({ member }: Props) => {
  const { id, user, isCoach, isAdmin } = member;
  const remove = useDeleteClubMember(useClub().id, member.id);

  const handleDelete = useCallback(() => {
    remove.mutate();
  }, [remove]);

  return (
    <TableRow>
      <TableCell>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <UserAvatar user={user} sx={{ mr: 1 }} />
          <Link href={id.toString()}>{user.fullName}</Link>
        </Box>
      </TableCell>

      <TableCell align="center">{user.email}</TableCell>
      <TableCell align="center">{isCoach ? 'Taip' : ''}</TableCell>
      <TableCell align="center">{isAdmin ? 'Taip' : ''}</TableCell>

      <TableCell align="right">
        <ConfirmableIconButton
          text={`Ar tikrai norite ištrinti "${user.fullName}"`}
          onConfirm={handleDelete}>
          <DeleteIcon fontSize="small" color="error" />
        </ConfirmableIconButton>
      </TableCell>
    </TableRow>
  );
};

export default ClubMembersTableRow;
