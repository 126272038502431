import Link from '@mui/material/Link';
import { useDialog } from 'components/Dialogs';
import { useCallback } from 'react';
import {
  CoachSessionDetailsView,
  CoachSessionReservationListView,
} from 'schema';

type Props = {
  session: CoachSessionDetailsView;
  reservation: CoachSessionReservationListView;
};

const SessionReservationLink = ({ session, reservation }: Props) => {
  const [, { open }] = useDialog('sessionReservation');

  const handleClick = useCallback(() => {
    open({ session, reservation });
  }, [open, reservation, session]);

  return (
    <Link component="a" sx={{ cursor: 'pointer' }} onClick={handleClick}>
      {reservation.user.email}
    </Link>
  );
};

export default SessionReservationLink;
