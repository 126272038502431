import StoreIcon from '@mui/icons-material/Store';
import Avatar, { AvatarProps } from '@mui/material/Avatar';
import { MediaView } from 'schema';

type Club = {
  logo: MediaView | null;
};

type Props = AvatarProps & {
  club: Club;
};

const ClubAvatar = ({ club, ...props }: Props) => {
  return (
    <Avatar
      variant="rounded"
      src={club.logo?.publicUrl && club.logo.publicUrl}
      children={!club.logo?.publicUrl && <StoreIcon />}
      {...props}
    />
  );
};

export default ClubAvatar;
