import { useCallback } from 'react';

import LogoutIcon from '@mui/icons-material/Logout';
import BlockIcon from '@mui/icons-material/Block';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import FullScreen from 'components/FullScreen';

import { useLogout } from 'features/Auth';

const AppNotCoach = () => {
  const logout = useLogout();

  const handleLogout = useCallback(() => {
    logout.mutate();
  }, [logout]);

  return (
    <FullScreen>
      <Paper sx={{ textAlign: 'center', p: 4 }}>
        <Box sx={{ fontSize: 96 }}>
          <BlockIcon fontSize="inherit" />
        </Box>

        <Typography variant="h2" sx={{ mb: 3 }}>
          Jūs nesate treneris jokiame klube
        </Typography>

        <LoadingButton
          variant="contained"
          startIcon={<LogoutIcon />}
          loading={logout.isPending}
          onClick={handleLogout}>
          Atsijungti
        </LoadingButton>
      </Paper>
    </FullScreen>
  );
};

export default AppNotCoach;
