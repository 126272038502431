import { useCallback } from 'react';
import { Form, Formik } from 'formik';

import AddIcon from '@mui/icons-material/Add';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from 'components/DialogTitle';
import Dialog, { DialogProps } from 'components/Dialog';
import DialogContent from 'components/DialogContent';
import QuantityDiscountFormFields, {
  Fields,
  schema,
} from 'components/QuantityDiscountFormFields';

import { useDialog } from 'components/Dialogs';
import { useCreateQuantityDiscount } from 'features/QuantityDiscount';
import { ActivityDetailsView } from 'schema';

type Props = DialogProps & {
  activity: ActivityDetailsView;
};

export type AddQuantityDiscountDialogProps = Props;

const initial = {
  quantity: '',
  amount: '',
};

const AddQuantityDiscountDialog = ({ activity, ...props }: Props) => {
  const [, { close }] = useDialog('quantityDiscountAdd');
  const create = useCreateQuantityDiscount(activity.id);

  const handleSubmit = useCallback(
    ({ quantity, amount }: Fields) => {
      const form = {
        quantity: Number(quantity),
        amount: Number(amount),
      };

      create.mutate(form, {
        onSuccess: close,
      });
    },
    [close, create]
  );

  return (
    <Dialog {...props} dialog="quantityDiscountAdd">
      <Formik<Fields>
        initialValues={initial}
        onSubmit={handleSubmit}
        validationSchema={schema}>
        <Form>
          <DialogTitle onClose={close}>Nauja kiekio nuolaidos</DialogTitle>

          <DialogContent>
            <QuantityDiscountFormFields />
          </DialogContent>

          <DialogActions>
            <Button onClick={close} variant="text">
              Atšaukti
            </Button>

            <LoadingButton
              loading={create.isPending}
              variant="contained"
              type="submit"
              startIcon={<AddIcon />}>
              Pridėti
            </LoadingButton>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

export default AddQuantityDiscountDialog;
