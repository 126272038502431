import { useClub } from 'components/ClubProvider';
import Select, { SelectProps } from 'components/Select';

import { useClubActivities } from 'features/Club';
import { ActivityListView } from 'schema';

type Props = Omit<SelectProps<ActivityListView>, 'options'>;

const ActivitySelect = (props: Props) => {
  const club = useClub();
  const query = useClubActivities(club.id);

  return <Select {...props} options={query.data ?? []} />;
};

export default ActivitySelect;
