import ClubDetails from 'components/ClubDetails';
import Tabber from 'components/Tabber';
import PageSkeleton from 'components/PageSkeleton';
import Page from 'components/Page';

import { useClub } from 'components/ClubProvider';
import { useClubDetails } from 'features/Club';
import ClubMessaging from 'components/ClubMessaging';
import Tabs from 'components/Tabs';

const ClubEditPage = () => {
  const { id } = useClub();
  const query = useClubDetails(id);

  if (!query.data) return <PageSkeleton>Kraunasi...</PageSkeleton>;

  return (
    <Tabs
      tabs={[
        {
          title: 'Klubo informacija',
          component: <ClubDetails club={query.data} />,
          paper: false,
          hash: 'groups',
        },
        {
          title: 'Privatumo politika',
          component: <div>privacy</div>,
          hash: 'activity',
        },
        {
          title: 'Pranešimai',
          component: <ClubMessaging />,
          hash: 'messaging',
          paper: false,
        },
      ]}>
      <Page title={query.data.name}>
        <Tabber />
      </Page>
    </Tabs>
  );
};

export default ClubEditPage;
