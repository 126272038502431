import { useEffect } from 'react';
import EditorJS, { OutputData } from '@editorjs/editorjs';

import NestedList from '@editorjs/nested-list';
import Header from '@editorjs/header';

const tools = {
  list: {
    class: NestedList,
    inlineToolbar: true,
    config: {
      defaultStyle: 'ordered',
    },
  },
  header: Header,
};

type Props = {
  initial?: OutputData | null;
  onChange?: (value: OutputData) => void;
};

// FIXME: would be nice to be able to run react in strict mode, but
// need to find out a way to render it propely with silly react strict mode
// double renders.
const Editor = ({ initial, onChange }: Props) => {
  useEffect(() => {
    const editor = new EditorJS({
      minHeight: 50,
      tools,
      autofocus: true,
      data: initial ?? undefined,
      onChange: async () => {
        onChange && onChange(await editor.save());
      },
    });

    return () => {
      editor.isReady.then(() => {
        editor.destroy();
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div id="editorjs" />;
};

export default Editor;
