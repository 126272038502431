import Select, { SelectProps } from 'components/Select';

import { AttendantDropdownOption } from 'schema';
import { useAttendantsDropdownOptions } from 'features/Attendant';

type Props = Omit<SelectProps<AttendantDropdownOption>, 'options'>;

const ClubAttendantSelect = (props: Props) => {
  const attendants = useAttendantsDropdownOptions();

  return (
    <Select
      loading={attendants.isPending}
      options={attendants.data ?? []}
      {...props}
    />
  );
};

export default ClubAttendantSelect;
