import { Form, useField } from 'formik';

import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import FormActions from 'components/FormActions';
import FormButton from 'components/FormButton';
import FormCheckbox from 'components/FormCheckbox';
import TemplateEditor from 'components/TemplateEditor';

import { ActivityConfirmationEmailSettings as ActivityConfirmationEmailSettingsType } from 'schema';

type Props = {
  settings: ActivityConfirmationEmailSettingsType;
  loading: boolean;
};

const ActivityConfirmationEmailSettingsForm = ({
  settings,
  loading,
}: Props) => {
  const [{ value: useDefault }] = useField('useDefault');

  return (
    <Form>
      <Stack spacing={2}>
        <Paper sx={{ p: 2 }}>
          <FormControlLabel
            label="Naudoti standartinį šabloną"
            control={<FormCheckbox name="useDefault" />}
          />
        </Paper>

        {!useDefault && (
          <TemplateEditor settings={settings} templateTitle="Laiško tekstas" />
        )}

        <Paper sx={{ p: 2 }}>
          <FormActions>
            <FormButton loading={loading} />
          </FormActions>
        </Paper>
      </Stack>
    </Form>
  );
};

export default ActivityConfirmationEmailSettingsForm;
