import ActivityAgreementSettings from 'components/ActivityAgreementSettings';
import TemplateEditorSkeleton from 'components/TemplateEditorSkeleton';

import { useActivityAgreement } from 'features/Activity';
import { ActivityDetailsView } from 'schema';

type Props = {
  activity: ActivityDetailsView;
};

const ActivityAgreement = ({ activity }: Props) => {
  const settings = useActivityAgreement(activity.id);

  return settings.data ? (
    <ActivityAgreementSettings activity={activity} settings={settings.data} />
  ) : (
    <TemplateEditorSkeleton />
  );
};

export default ActivityAgreement;
