import { useParams } from 'react-router-dom';

import Tabber from 'components/Tabber';
import SessionDetailsMenu from 'components/SessionDetailsMenu';
import SessionDetails from 'components/SessionDetails';
import SessionRegistrations from 'components/SessionRegistrations';
import SessionReservations from 'components/SessionReservations';
import SessionInvitations from 'components/SessionInvitations';
import Page from 'components/Page';
import PageSkeleton from 'components/PageSkeleton';
import TabberSkeleton from 'components/TabberSkeleton';
import SessionDetailsSkeleton from 'components/SessionDetailsSkeleton';

import { useActivity, useActivitySession } from 'features/Activity';
import Tabs from 'components/Tabs';
import SessionAttendantsExportButton from 'components/SessionAttendantsExportButton';

const SessionEdit = () => {
  const { activity, session } = useParams();

  const sessionQuery = useActivitySession(Number(activity), Number(session));
  const activityQuery = useActivity(Number(activity));

  if (!sessionQuery.data || !activityQuery.data)
    return (
      <PageSkeleton>
        <TabberSkeleton />
        <SessionDetailsSkeleton />
      </PageSkeleton>
    );

  return (
    <Tabs
      tabs={[
        {
          title: 'Nariai',
          component: <SessionRegistrations session={sessionQuery.data} />,
          hash: 'attendants',
          paper: false,
        },
        {
          title: 'Grupė',
          component: (
            <SessionDetails
              activity={activityQuery.data}
              session={sessionQuery.data}
            />
          ),
          hash: 'session',
        },
        {
          title: 'Rezervas',
          component: <SessionReservations session={sessionQuery.data} />,
          hash: 'reservations',
          paper: false,
        },
        {
          title: 'Pakvietimai',
          component: <SessionInvitations session={sessionQuery.data} />,
          hash: 'invitations',
          paper: false,
        },
      ]}>
      <Page
        title={sessionQuery.data.name}
        actions={<SessionAttendantsExportButton session={sessionQuery.data} />}>
        <Tabber
          tabsAction={
            <SessionDetailsMenu
              activity={activityQuery.data}
              session={sessionQuery.data}
            />
          }
        />
      </Page>
    </Tabs>
  );
};

export default SessionEdit;
