import {
  QueryFunctionContext,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useNotification } from 'components/Notifications';
import { useErrorHandler } from 'lib';
import {
  pageCreate,
  pageDelete,
  PageDetails,
  PageForm,
  pageIndex,
  PageListView,
  pageShow,
  PageShowInput,
  pageUpdate,
} from 'schema';

export const pageKeys = {
  all: [{ scope: 'pages' }] as const,

  lists: () => [{ ...pageKeys.all[0], entity: 'list' }] as const,
  list: () => [{ ...pageKeys.lists()[0] }] as const,

  details: () => [{ ...pageKeys.all[0], entity: 'detail' }] as const,
  detail: (params: PageShowInput) =>
    [{ ...pageKeys.details()[0], params }] as const,
};

type PageDetailsContext = QueryFunctionContext<
  ReturnType<(typeof pageKeys)['detail']>
>;

export const usePages = () =>
  useQuery({
    queryKey: pageKeys.list(),
    queryFn: pageIndex,
  });

export const usePage = (page: number) =>
  useQuery({
    queryKey: pageKeys.detail({ page }),
    queryFn: async ({ queryKey: [{ params }] }: PageDetailsContext) =>
      await pageShow(params),
  });

export const useCreatePage = () => {
  const client = useQueryClient();
  const queryKey = pageKeys.list();

  return useMutation({
    mutationFn: (form: PageForm) => pageCreate({ form }),
    onSuccess: data => {
      client.setQueryData<PageListView[]>(queryKey, old => [
        ...(old ?? []),
        data,
      ]);
    },
    onError: useErrorHandler(),
  });
};

export const useUpdatePage = (page: number) => {
  const client = useQueryClient();
  const { pop } = useNotification();

  return useMutation({
    mutationFn: (form: PageForm) => pageUpdate({ page, form }),

    onSuccess: data => {
      client.setQueryData<PageDetails>(pageKeys.detail({ page }), data);

      client.setQueryData<PageListView[]>(pageKeys.list(), current =>
        (current ?? []).map(item => (data.id === item.id ? data : item))
      );

      client.invalidateQueries({ queryKey: pageKeys.detail({ page }) });
      client.invalidateQueries({ queryKey: pageKeys.list() });

      pop('Išsaugota');
    },

    onError: useErrorHandler(),
  });
};

export const useDeletePage = (page: number) => {
  const client = useQueryClient();
  const queryKey = pageKeys.list();
  const handleError = useErrorHandler();

  return useMutation({
    mutationFn: () => pageDelete({ page }),
    onMutate: async () => {
      const snapshot = client.getQueryData<PageListView[]>(queryKey);

      client.setQueryData<PageListView[]>(
        queryKey,
        items => items?.filter(item => item.id !== page) ?? []
      );

      return { snapshot };
    },

    onError: (error: any, __, context) => {
      handleError(error);
      client.setQueryData(queryKey, context?.snapshot);
    },

    onSettled: () => {
      client.invalidateQueries({ queryKey });
    },
  });
};
