import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { STORAGE_ACCESS_TOKEN } from 'const';
import { useErrorHandler } from 'lib';
import { useCallback } from 'react';
import { CreateTokenForm, authCreateToken, authMe, authRevoke } from 'schema';

export const authKeys = {
  all: [{ scope: 'auth' }] as const,
  me: () => [{ ...authKeys.all[0], entity: 'me' }] as const,
};

export const useMeQuery = () =>
  useQuery({
    queryKey: authKeys.me(),
    queryFn: authMe,
    enabled: !!localStorage.getItem(STORAGE_ACCESS_TOKEN),
    retry: false,
    refetchOnWindowFocus: false,
  });

export const useLogin = () =>
  useMutation({
    mutationFn: (form: CreateTokenForm) => authCreateToken({ form }),
    onError: useErrorHandler(),

    onSuccess: ({ accessToken }) => {
      localStorage.setItem(STORAGE_ACCESS_TOKEN, accessToken);
      window.location.reload();
    },
  });

export const useLogout = () =>
  useMutation({
    mutationFn: authRevoke,
    onSuccess: () => {
      localStorage.removeItem(STORAGE_ACCESS_TOKEN);
      window.location.reload();
    },
  });

export const useClearAuth = () => {
  const client = useQueryClient();
  const queryKey = authKeys.me();

  return useCallback(async () => {
    localStorage.removeItem(STORAGE_ACCESS_TOKEN);

    await client.invalidateQueries({ queryKey });
  }, [client, queryKey]);
};

export const hasToken = () =>
  Boolean(localStorage.getItem(STORAGE_ACCESS_TOKEN));
