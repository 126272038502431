import { useCallback } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import WarningIcon from '@mui/icons-material/Warning';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { useDialog } from 'components/Dialogs';
import { ClubInvoiceListView } from 'schema';

type Props = {
  invoice: ClubInvoiceListView;
};

const icons = {
  pending: (
    <Tooltip title="CRM sinchronizacija vykdoma">
      <HourglassTopIcon color="warning" fontSize="small" />
    </Tooltip>
  ),

  complete: (
    <Tooltip title="CRM sinchronizacija sėkminga">
      <CheckIcon color="success" fontSize="small" />
    </Tooltip>
  ),

  aborted: (
    <Tooltip title="CRM sinchronizacija nepavyko">
      <WarningIcon color="error" fontSize="small" />
    </Tooltip>
  ),
};

const InvoiceSyncStatus = ({ invoice }: Props) => {
  const [, { open }] = useDialog('invoiceSync');

  const handleClick = useCallback(() => {
    open({ invoice });
  }, [invoice, open]);

  return (
    <IconButton size="small" onClick={handleClick}>
      {icons[invoice.syncStatus]}
    </IconButton>
  );
};

export default InvoiceSyncStatus;
