import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import InvoiceItemRow from 'components/InvoiceItemRow';
import { InvoiceView } from 'schema';

type Props = {
  invoice: InvoiceView;
};

const colSpan = 4;

const InvoiceTable = ({ invoice }: Props) => {
  const { items, totalItems, total, discount } = invoice;

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell component="th">Paslauga</TableCell>
            <TableCell component="th">Pastabos</TableCell>

            <TableCell component="th" align="center">
              Kiekis
            </TableCell>

            <TableCell component="th" align="center">
              Kaina
            </TableCell>

            <TableCell component="th" align="right">
              Suma
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {items.map((item, i) => (
            <InvoiceItemRow key={i} item={item} />
          ))}

          <TableRow>
            <TableCell colSpan={colSpan} align="right">
              {!discount ? 'Viso mokėti' : 'Viso'}
            </TableCell>

            <TableCell align="right">
              {!discount ? total.toFixed(2) : totalItems.toFixed(2)} €
            </TableCell>
          </TableRow>

          {discount ? (
            <>
              <TableRow>
                <TableCell colSpan={colSpan} align="right">
                  Nuolaida
                </TableCell>

                <TableCell align="right">{discount.toFixed(2)} €</TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={colSpan} align="right">
                  Viso mokėti
                </TableCell>

                <TableCell align="right">{total.toFixed(2)} €</TableCell>
              </TableRow>
            </>
          ) : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InvoiceTable;
