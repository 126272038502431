import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Section from 'components/Section';

import FormTextField from 'components/FormTextField';

const MenuEditorExternalUrlForm = () => (
  <Section>
    <Typography variant="h6">Meniu nuoroda</Typography>

    <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
      <FormTextField fullWidth name="url" label="Nuoroda" />
    </Box>
  </Section>
);

export default MenuEditorExternalUrlForm;
