import MenuItem from '@mui/material/MenuItem';
import { useClub } from 'components/ClubProvider';
import SelectMulti, {
  SelectOption,
  SelectMultiProps,
} from 'components/SelectMulti';
import UserAvatar from 'components/UserAvatar';
import { useClubMembers } from 'features/Club';
import { UserListView } from 'schema';

export type Option = SelectOption & {
  user: UserListView;
};

type Props = Omit<SelectMultiProps<Option>, 'options'> & {};

export type ClubMemberSelectProps = Props;

const ClubMemberSelect = (props: Props) => {
  const members = useClubMembers(useClub().id);

  return (
    <SelectMulti<Option>
      multiple
      options={members.data ?? []}
      loading={members.isPending}
      renderOption={(props, { name, user }, { selected }) => (
        <MenuItem {...props} selected={selected}>
          <UserAvatar user={user} sx={{ mr: 1 }} /> {name}
        </MenuItem>
      )}
      {...props}
    />
  );
};

export default ClubMemberSelect;
