import { useHash } from 'lib/useHash';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';

type TabDefinition = {
  title: ReactNode;
  component: ReactNode;
  hash: string;
  paper?: boolean;
};

type Props = {
  tabs: TabDefinition[];
  navigate?: boolean;
  children: ReactNode;
};

type TabberContextType = {
  tabs: TabDefinition[];
  currentIndex: number;
  currentHash: string;
  changeTab: (hash: string) => void;
};

const TabberContext = createContext<TabberContextType>({
  tabs: [],
  currentHash: '',
  currentIndex: 0,
  changeTab: () => {},
});

const index = (tabs: TabDefinition[], hash: string) => {
  let index = 0;

  for (let tab of tabs) {
    if (tab.hash === hash) return index;

    index++;
  }

  return 0;
};

export const useTabs = () => useContext(TabberContext);

const Tabs = (props: Props) => {
  const { tabs, navigate = true, children } = props;
  const location = useLocation();

  const [currentHash, setCurrentHash] = useState(useHash() ?? tabs[0].hash);

  const changeTab = useCallback(
    (hash: string) => {
      setCurrentHash(hash);

      if (!navigate) return;

      window.history.replaceState(
        {},
        document.title,
        `${location.pathname}#${hash}`
      );
    },
    [location.pathname, navigate]
  );

  const currentIndex = index(tabs, currentHash);

  return (
    <TabberContext.Provider
      value={{ tabs, currentIndex, currentHash, changeTab }}>
      {children}
    </TabberContext.Provider>
  );
};

export default Tabs;
