import FormSimpleSelect, {
  FormSimpleSelectProps,
} from 'components/FormSimpleSelect';

import { MenuItemType } from 'schema';

type Option = {
  value: MenuItemType;
  label: string;
};

type Props = Omit<FormSimpleSelectProps<MenuItemType>, 'options'>;

const options: Option[] = [
  {
    value: 'page',
    label: 'Puslapis',
  },
  {
    value: 'external',
    label: 'Išorinė nuoroda',
  },
];

const MenuItemTypeSelect = (props: Props) => {
  return <FormSimpleSelect {...props} options={options} />;
};

export default MenuItemTypeSelect;
