import { useCallback } from 'react';
import DiscountFormFields, {
  Fields,
  schema,
} from 'components/DiscountFormFields';
import { Form, Formik } from 'formik';
import Section from 'components/Section';
import FormActions from 'components/FormActions';
import FormButton from 'components/FormButton';
import { useUpdateDiscount } from 'features/Discount';
import { DiscountDetails } from 'schema';
import { useClub } from 'components/ClubProvider';

type Props = {
  discount: DiscountDetails;
};

const DiscountDetailsEdit = ({ discount }: Props) => {
  const { quantity, amount, ...rest } = discount;
  const update = useUpdateDiscount(useClub().id, discount.id);

  const handleSubmit = useCallback(
    ({ quantity, amount, expiresAt, type, ...values }: Fields) => {
      update.mutate({
        quantity: quantity ? Number(quantity) : null,
        amount: Number(amount),
        expiresAt: expiresAt!,
        type: type!,
        ...values,
      });
    },
    [update]
  );

  const initial: Fields = {
    quantity: quantity?.toString() ?? '',
    amount: amount.toString(),
    ...rest,
  };

  return (
    <Formik<Fields>
      initialValues={initial}
      onSubmit={handleSubmit}
      validationSchema={schema}>
      <Form>
        <Section sx={{ mb: 2 }}>
          <DiscountFormFields discount={discount} />
        </Section>

        <Section>
          <FormActions>
            <FormButton loading={update.isPending}>Saugoti</FormButton>
          </FormActions>
        </Section>
      </Form>
    </Formik>
  );
};

export default DiscountDetailsEdit;
