import { useCallback } from 'react';
import { useField } from 'formik';

import {
  MobileTimePicker,
  MobileTimePickerProps,
} from '@mui/x-date-pickers/MobileTimePicker';

type Props = Omit<MobileTimePickerProps<Date>, 'value' | 'onChange'> & {
  name: string;
};

const FormTimePicker = ({ name, ...props }: Props) => {
  const [{ value }, { error, touched }, { setValue }] = useField<Date | null>(
    name
  );

  const hasError = Boolean(error && touched);

  const handleChange = useCallback(
    (value: Date | null) => {
      setValue(value);
    },
    [setValue]
  );

  return (
    <MobileTimePicker
      closeOnSelect
      value={value}
      onChange={handleChange}
      localeText={{
        toolbarTitle: 'Pasirinkite laiką',
        okButtonLabel: 'Pasirinkti',
        cancelButtonLabel: 'Atšaukti',
      }}
      slotProps={{
        textField: {
          variant: 'standard',
          error: hasError,
          helperText: hasError && error,
        },
      }}
      {...props}
    />
  );
};

export default FormTimePicker;
