import Box, { BoxProps } from '@mui/material/Box';

type Props = BoxProps;

const TableRowActions = ({ sx = [], children, ...props }: Props) => (
  <Box
    sx={[
      { display: 'flex', justifyContent: 'end' },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    {...props}>
    {children}
  </Box>
);

export default TableRowActions;
