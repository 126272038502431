import { useCallback } from 'react';
import { Form, Formik } from 'formik';

import SendIcon from '@mui/icons-material/Send';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from 'components/DialogTitle';
import Dialog from 'components/Dialog';
import DialogContent from 'components/DialogContent';
import InviteMemberDialogForm, {
  Fields,
  schema,
} from 'components/InviteMemberDialogForm';

import { useDialog } from 'components/Dialogs';
import { useClub } from 'components/ClubProvider';
import { useInviteToClub } from 'features/Club';

export type InviteMembersDialogProps = {};

const dialog = 'memberInvite';

const initial = {
  email: '',
  roles: {
    isCoach: false,
    isAdmin: false,
  },
};

const InviteMemberDialog = () => {
  const [, { close }] = useDialog(dialog);
  const send = useInviteToClub(useClub().id);

  const handleSubmit = useCallback(
    (values: Fields) => {
      send.mutate(values, {
        onSuccess: () => {
          close();
        },
      });
    },
    [close, send]
  );

  return (
    <Dialog dialog={dialog} maxWidth="sm">
      <Formik<Fields>
        initialValues={initial}
        onSubmit={handleSubmit}
        validationSchema={schema}>
        <Form>
          <DialogTitle onClose={close}>
            Pakviesti narius prisijungti prie klubo
          </DialogTitle>

          <DialogContent>
            <InviteMemberDialogForm />
          </DialogContent>

          <DialogActions>
            <Button onClick={close}>Uždaryti</Button>

            <LoadingButton
              type="submit"
              variant="contained"
              loading={send.isPending}
              startIcon={<SendIcon />}>
              Siųsti
            </LoadingButton>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

export default InviteMemberDialog;
