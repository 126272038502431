import FormSimpleSelect, {
  FormSimpleSelectProps,
} from 'components/FormSimpleSelect';
import { DiscountType } from 'schema';

type Option = {
  value: DiscountType;
  label: string;
};

type Props = Omit<FormSimpleSelectProps<DiscountType>, 'options'>;

const options: Option[] = [
  {
    value: 'absolute',
    label: 'Suma eur',
  },
  {
    value: 'percent',
    label: 'Procentinė',
  },
];

const DiscountTypeSelect = (props: Props) => (
  <FormSimpleSelect {...props} options={options} />
);

export default DiscountTypeSelect;
