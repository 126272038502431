import { createContext, useCallback, useContext, useState } from 'react';
import { Outlet } from 'react-router-dom';

import Box from '@mui/material/Box';
import MainMenu from 'components/MainMenu';
import AuthGuard from 'components/AuthGuard';
import ClubProvider from 'components/ClubProvider';
import Dialogs from 'components/Dialogs';

type LayoutContextType = {
  mainMenuOpen: boolean;
  toggleDrawer: () => void;
};

const LayoutContext = createContext<LayoutContextType>({
  mainMenuOpen: false,
  toggleDrawer: () => {},
});

export const useLayout = () => useContext(LayoutContext);

function Root() {
  const [open, setOpen] = useState(localStorage.getItem('mainMenuOpen') ?? '1');

  const toggleDrawer = useCallback(() => {
    setOpen(v => {
      const newValue = v === '1' ? '0' : '1';

      localStorage.setItem('mainMenuOpen', newValue);

      return newValue;
    });
  }, []);

  const mainMenuOpen = open === '1';

  return (
    <AuthGuard>
      <ClubProvider>
        <Dialogs>
          <LayoutContext.Provider value={{ mainMenuOpen, toggleDrawer }}>
            <Box sx={{ display: 'flex', height: '100vh' }}>
              <MainMenu open={open === '1'} onToggle={toggleDrawer} />

              <Box
                component="main"
                sx={{
                  backgroundColor: theme =>
                    theme.palette.mode === 'light'
                      ? theme.palette.grey[100]
                      : theme.palette.grey[900],
                  color: 'text.primary',
                  flexGrow: 1,
                  overflow: 'auto',
                  p: 2,
                }}>
                <Outlet />
              </Box>
            </Box>
          </LayoutContext.Provider>
        </Dialogs>
      </ClubProvider>
    </AuthGuard>
  );
}

export default Root;
