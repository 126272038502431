import { useTheme } from '@mui/material/styles';

import ImageIcon from '@mui/icons-material/Image';
import Box from '@mui/material/Box';

import { MediaView } from 'schema';

type Props = {
  image: MediaView | null;
};

const ImagePreview = ({ image }: Props) => {
  const {
    palette: { mode },
  } = useTheme();

  const bgcolor = mode === 'light' ? '#f5f5f5' : '#121212';

  return (
    <Box
      sx={{
        width: '100px',
        height: '100px',
        display: 'inline-block',
        '& img': {
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        },
      }}>
      {image ? (
        <img src={image.publicUrl} alt="" />
      ) : (
        <Box
          sx={{
            bgcolor,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 42,
            color: 'text.secondary',
          }}>
          <ImageIcon fontSize="inherit" color="inherit" />
        </Box>
      )}
    </Box>
  );
};

export default ImagePreview;
