import ShiftsTable from 'components/ShiftsTable';

import { useShifts } from 'features/Shift';
import { ActivityDetailsView } from 'schema';

type Props = {
  activity: ActivityDetailsView;
};

const ActivityShifts = ({ activity }: Props) => {
  const shifts = useShifts(activity.id);

  return (
    <ShiftsTable
      activity={activity}
      shifts={shifts.data ?? []}
      loading={shifts.isPending}
    />
  );
};

export default ActivityShifts;
