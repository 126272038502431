import { useField } from 'formik';
import TextField, { TextFieldProps } from '@mui/material/TextField';

type Props = TextFieldProps & {
  name: string;
};

function FormTextField({ name, helperText, ...rest }: Props) {
  const [field, { error, touched }] = useField(name);
  const hasError = Boolean(error && touched);

  return (
    <TextField
      variant="standard"
      error={hasError}
      helperText={(hasError && error) || helperText}
      {...field}
      {...rest}
    />
  );
}

export default FormTextField;
