import { ReactNode, useCallback, SyntheticEvent } from 'react';

import Tabs, { TabsProps } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TabberPanel from 'components/TabberPanel';

import { useTabs } from 'components/Tabs';

type Props = TabsProps & {
  dismount?: boolean;
  tabsAction?: ReactNode;
};

const Tabber = ({ tabsAction, dismount, ...props }: Props) => {
  const { tabs, currentIndex, changeTab } = useTabs();

  const handleTabClick = useCallback(
    (_: SyntheticEvent, i: number) => {
      changeTab(tabs[i].hash);
    },
    [changeTab, tabs]
  );

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flexGrow: 1 }}>
          <Tabs value={currentIndex} onChange={handleTabClick} {...props}>
            {tabs.map(({ title }, i) => (
              <Tab label={title} key={i} />
            ))}
          </Tabs>
        </Box>

        {tabsAction && <Box sx={{ mr: 1 }}>{tabsAction}</Box>}
      </Paper>

      {tabs.map(({ component, paper = true }, i) => (
        <TabberPanel
          value={currentIndex}
          dismount={dismount}
          key={i}
          index={i}
          paper={paper}>
          {component}
        </TabberPanel>
      ))}
    </Box>
  );
};

export default Tabber;
