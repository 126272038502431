import Button, { ButtonProps } from '@mui/material/Button';
import { ConfirmDialogProps, useConfirm } from 'components/ConfirmDialog';

type Props = ConfirmDialogProps & ButtonProps;

const ConfirmableIconButton = (props: Props) => {
  const { onConfirm, title, text, ...rest } = props;
  const confirm = useConfirm({ onConfirm, title, text });

  return <Button onClick={confirm} {...rest} />;
};

export default ConfirmableIconButton;
