import CircularProgress from '@mui/material/CircularProgress';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';

type Props = IconButtonProps & {
  loading?: boolean;
};

const LoadingIconButton = ({ loading = false, children, ...props }: Props) => (
  <IconButton {...props}>
    {loading ? <CircularProgress size={20} /> : children}
  </IconButton>
);

export default LoadingIconButton;
