import { useCallback } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import ConfirmableIconButton from 'components/ConfirmableIconButton';
import SessionReservationInviteButton from 'components/SessionReservationInviteButton';
import TableRowActions from 'components/TableRowActions';

import { useDeleteSessionReservation } from 'features/SessionReservation';
import { formatDateTime, truncate } from 'lib';
import {
  CoachSessionDetailsView,
  CoachSessionReservationListView,
} from 'schema';
import SessionReservationLink from 'components/SessionReservationLink';
import Tooltip from '@mui/material/Tooltip';

type Props = {
  session: CoachSessionDetailsView;
  reservation: CoachSessionReservationListView;
};

const SessionsReservationsTableRow = ({ session, reservation }: Props) => {
  const { user, created, notes } = reservation;

  const destroy = useDeleteSessionReservation(
    session.activity.id,
    session.id,
    reservation.id
  );

  const handleDelete = useCallback(() => {
    destroy.mutate();
  }, [destroy]);

  return (
    <TableRow>
      <TableCell>
        <SessionReservationLink session={session} reservation={reservation} />
      </TableCell>

      <TableCell align="center">{user.phone}</TableCell>
      <TableCell align="center">{formatDateTime(created)}</TableCell>

      <TableCell>
        {notes && (
          <Tooltip title={notes}>
            <span>{truncate(notes)}</span>
          </Tooltip>
        )}
      </TableCell>

      <TableCell align="right">
        <TableRowActions>
          <SessionReservationInviteButton
            session={session}
            reservation={reservation}
          />

          <ConfirmableIconButton
            text={`Ar tikrai norite ištrinti šį pakvietimą`}
            onConfirm={handleDelete}>
            <DeleteIcon fontSize="small" color="error" />
          </ConfirmableIconButton>
        </TableRowActions>
      </TableCell>
    </TableRow>
  );
};

export default SessionsReservationsTableRow;
