import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import ConfirmableMenuItem from 'components/ConfirmableMenuItem';

import { CoachSessionDetailsView } from 'schema';
import { useDeleteActivitySession } from 'features/Activity';

type Props = {
  session: CoachSessionDetailsView;
};

const SessionDeleteMenuItem = ({ session }: Props) => {
  const deleteSession = useDeleteActivitySession(
    session.activity.id,
    session.id
  );
  const navigate = useNavigate();

  const handleDelete = useCallback(() => {
    deleteSession.mutate();
    navigate(`/activities/${session.activity.id}`);
  }, [deleteSession, navigate, session]);

  return (
    <ConfirmableMenuItem
      onConfirm={handleDelete}
      text={`Ar tikrai norite ištrinti "${session.name}"`}>
      Trinti grupę
    </ConfirmableMenuItem>
  );
};

export default SessionDeleteMenuItem;
