import {
  QueryFunctionContext,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useNotification } from 'components/Notifications';
import { useErrorHandler } from 'lib';
import {
  ActivityShiftIndexInput,
  ShiftForm,
  ShiftView,
  activityShiftCreate,
  activityShiftDelete,
  activityShiftIndex,
  activityShiftUpdate,
} from 'schema';

export const shiftKeys = {
  all: [{ scope: 'shift' }] as const,

  lists: () => [{ ...shiftKeys.all[0], entity: 'list' }] as const,
  list: (params: ActivityShiftIndexInput) =>
    [{ ...shiftKeys.lists()[0], params }] as const,
};

type ShiftListContext = QueryFunctionContext<
  ReturnType<(typeof shiftKeys)['list']>
>;

export const useShifts = (activity: number) => {
  return useQuery({
    queryKey: shiftKeys.list({ activity }),
    queryFn: async ({ queryKey: [{ params }] }: ShiftListContext) =>
      await activityShiftIndex(params),
  });
};

export const useCreateShift = (activity: number) => {
  const client = useQueryClient();
  const queryKey = shiftKeys.list({ activity });
  const { pop } = useNotification();

  return useMutation({
    mutationFn: (form: ShiftForm) => activityShiftCreate({ activity, form }),
    onError: useErrorHandler(),

    onSuccess: () => {
      client.invalidateQueries({ queryKey });

      pop('Pamaina sukurta');
    },
  });
};

export const useUpdateShift = (activity: number, shift: number) => {
  const client = useQueryClient();
  const queryKey = shiftKeys.list({ activity });
  const { pop } = useNotification();

  return useMutation({
    mutationFn: (form: ShiftForm) =>
      activityShiftUpdate({ activity, shift, form }),

    onError: useErrorHandler(),

    onSuccess: () => {
      client.invalidateQueries({ queryKey });

      pop('Pamaina išsaugota');
    },
  });
};

export const useDeleteShift = (activity: number, shift: number) => {
  const client = useQueryClient();
  const queryKey = shiftKeys.list({ activity });
  const handleError = useErrorHandler();

  return useMutation({
    mutationFn: () => activityShiftDelete({ activity, shift }),
    onMutate: async () => {
      await client.cancelQueries({ queryKey });

      const snapshot = client.getQueryData<ShiftView[]>(queryKey);

      client.setQueryData<ShiftView[]>(
        queryKey,
        items => items?.filter(item => item.id !== shift) ?? []
      );

      return { snapshot };
    },

    onError: (error: any, __, context) => {
      handleError(error);
      client.setQueryData(queryKey, context?.snapshot);
    },

    onSettled: () => {
      client.invalidateQueries({ queryKey });
    },
  });
};
