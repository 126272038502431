import { useCallback } from 'react';

import ReplayIcon from '@mui/icons-material/Replay';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import HttpRequestTable from 'components/HttpRequestTable';
import LoadingIconButton from 'components/LoadingIconButton';

import { useRetrySync } from 'features/Invoice';
import { formatDateTime } from 'lib';
import { ClubInvoiceSync, InvoiceView } from 'schema';
import { useDialog } from 'components/Dialogs';

type Props = {
  invoice: InvoiceView;
  sync?: ClubInvoiceSync;
};

const statues = {
  pending: {
    color: 'warning.main',
    title: 'Vykdoma',
  },

  complete: {
    color: 'success.main',
    title: 'Atlikta',
  },

  aborted: {
    color: 'error.main',
    title: 'Nepavyko',
  },
} as const;

const InvoiceSyncDetails = ({ invoice, sync }: Props) => {
  const retry = useRetrySync(invoice.id);
  const [, { open }] = useDialog('invoiceSync');

  const handleRetryClick = useCallback(() => {
    retry.mutate(undefined, {
      onSuccess: () => open({ invoice }),
    });
  }, [invoice, open, retry]);

  return (
    <Box>
      <Grid container>
        <Grid xs={6}>
          <Typography variant="caption">Statusas</Typography>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography color={statues[invoice.syncStatus].color}>
              {statues[invoice.syncStatus].title}
            </Typography>

            {invoice.syncStatus === 'aborted' && (
              <LoadingIconButton
                size="small"
                sx={{ ml: 1 }}
                loading={retry.isPending}
                onClick={handleRetryClick}>
                <ReplayIcon fontSize="small" />
              </LoadingIconButton>
            )}
          </Box>
        </Grid>

        {sync?.lastAttemptAt && (
          <Grid xs={6}>
            <Typography variant="caption">
              {invoice.syncStatus === 'complete'
                ? 'Atlikta'
                : 'Paskutinis bandymas'}
            </Typography>

            <Typography color={statues[invoice.syncStatus]}>
              {formatDateTime(sync.lastAttemptAt)}
            </Typography>
          </Grid>
        )}
      </Grid>

      <HttpRequestTable messages={sync?.messages ?? []} sx={{ mt: 2 }} />
    </Box>
  );
};

export default InvoiceSyncDetails;
