import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import EditorSkeleton from 'components/EditorSkeleton';

const PageFormFieldsSkeleton = () => (
  <Stack spacing={4}>
    <Skeleton width={300} />
    <Skeleton width={400} />
    <EditorSkeleton />
  </Stack>
);

export default PageFormFieldsSkeleton;
