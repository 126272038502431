import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SessionDetailsSchedule from 'components/SessionDetailsSchedule';
import TextLabel from 'components/TextLabel';
import TextWithLabel from 'components/TextWithLabel';

import { ActivityDetailsView, CoachSessionDetailsView } from 'schema';

type Props = {
  activity: ActivityDetailsView;
  session: CoachSessionDetailsView;
};

const SessionDetails = ({ session }: Props) => {
  const {
    activity,
    coach,
    capacity,
    ageGroupFrom,
    ageGroupTo,
    numberOfAttendants,
    published,
  } = session;

  return (
    <Box>
      {activity.photo && (
        <Box
          sx={{
            pb: 2,
            '& img': {
              width: '100%',
              height: 300,
              objectFit: 'cover',
            },
          }}>
          <img src={activity.photo.publicUrl} alt={activity.name} />
        </Box>
      )}

      <Box sx={{ p: 2 }}>
        <TextWithLabel label="Veikla" sx={{ mb: 2 }}>
          {activity.name}
        </TextWithLabel>

        <TextWithLabel label="Treneris" sx={{ mb: 2 }}>
          {coach.user.firstName} {coach.user.lastName}
        </TextWithLabel>

        <TextLabel>Tvarkaraštis</TextLabel>
        <SessionDetailsSchedule
          activity={activity}
          session={session}
          sx={{ pb: 2 }}
        />

        <TextLabel>Vietos</TextLabel>
        {capacity ? (
          <>
            <Typography color="text.secondary">Viso: {capacity}</Typography>

            <Typography sx={{ pb: 2 }} color="text.secondary">
              Užpildyta: {numberOfAttendants}
            </Typography>
          </>
        ) : (
          <Typography sx={{ pb: 2 }} color="text.secondary">
            Neribotos
          </Typography>
        )}

        <TextWithLabel label="Amžiaus grupė" sx={{ mb: 2 }}>
          {ageGroupFrom && ageGroupTo
            ? `${ageGroupFrom} - ${ageGroupTo}`
            : !ageGroupFrom && ageGroupTo
            ? `iki ${ageGroupTo}`
            : !ageGroupTo && ageGroupFrom
            ? `nuo ${ageGroupFrom}`
            : 'Nenurodyta'}
        </TextWithLabel>

        <TextWithLabel label="Būklė" sx={{ mb: 2 }}>
          {published ? 'Publikuota' : 'Nepublikuota'}
        </TextWithLabel>
      </Box>
    </Box>
  );
};

export default SessionDetails;
