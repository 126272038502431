import {
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
} from '@mui/x-date-pickers/DatePicker';

type Props = MuiDatePickerProps<Date> & {
  fullWidth?: boolean;
  error?: boolean;
  helperText?: string;
};

export type DatePickerProps = Props;

const DatePicker = ({ fullWidth, error, helperText, ...rest }: Props) => (
  <MuiDatePicker
    showDaysOutsideCurrentMonth
    format="yyyy-MM-dd"
    slotProps={{
      textField: { variant: 'standard', error, helperText, fullWidth },
    }}
    {...rest}
  />
);

export default DatePicker;
