import DeleteIcon from '@mui/icons-material/Delete';
import Link from '@mui/material/Link';
import TableCell from '@mui/material/TableCell';
import ConfirmableIconButton from 'components/ConfirmableIconButton';
import TableRow from 'components/TableRow';
import { useDeletePage } from 'features/Page';
import { useCallback } from 'react';
import { PageListView } from 'schema';

type Props = {
  page: PageListView;
};

const PagesTableRow = ({ page }: Props) => {
  const { id, title, slug, isReadOnly } = page;
  const destroy = useDeletePage(id);

  const handleDelete = useCallback(() => {
    destroy.mutate();
  }, [destroy]);

  return (
    <TableRow>
      <TableCell>
        <Link href={id.toString()}>{title}</Link>
      </TableCell>
      <TableCell align="center">{slug}</TableCell>

      <TableCell align="right">
        <ConfirmableIconButton
          disabled={isReadOnly}
          text={`Ar tikrai norite ištrinti "${title}"`}
          onConfirm={handleDelete}>
          <DeleteIcon
            fontSize="small"
            color={isReadOnly ? 'disabled' : 'error'}
          />
        </ConfirmableIconButton>
      </TableCell>
    </TableRow>
  );
};

export default PagesTableRow;
