import FormSelect, { FormSelectProps } from 'components/FormSelect';

import { useCategories } from 'features/Category';
import { CategoryListView } from 'schema';

type Props = Omit<FormSelectProps<CategoryListView>, 'options'>;

const CategorySelect = (props: Props) => {
  const query = useCategories();

  return <FormSelect {...props} options={query.data ?? []} />;
};

export default CategorySelect;
