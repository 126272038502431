import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { OrderStatus } from 'schema';

type Order = {
  status: OrderStatus;
};

type Props = {
  order: Order;
};

type OrderStatusDefinition = {
  label: string;
  color: string; // TODO: should be able to get proper type from the guts of MUI, no???
};

type Statuses = Record<OrderStatus, OrderStatusDefinition>;

const statusMap: Statuses = {
  draft: {
    label: 'Ruošiamas',
    color: 'warning',
  },
  payment: {
    label: 'Mokėjimas',
    color: 'warning',
  },
  finalise: {
    label: 'Dokumentai',
    color: 'warning',
  },
  invoice: {
    label: 'Sąskaita',
    color: 'warning',
  },
  confirmation: {
    label: 'Patvirtinimas',
    color: 'warning',
  },
  complete: {
    label: 'Užbaigtas',
    color: 'success',
  },
  canceled: {
    label: 'Atšauktas',
    color: 'error',
  },
};

const OrderStatusText = ({ order }: Props) => {
  const definition = statusMap[order.status];
  const { palette } = useTheme();
  const modeText = `.${palette.mode}`;

  return (
    <Box component="span" color={definition.color + modeText}>
      {definition.label}
    </Box>
  );
};

export default OrderStatusText;
