import QuantityDiscountTable from 'components/QuantityDiscountTable';

import { useQuantityDiscounts } from 'features/QuantityDiscount';
import { ActivityDetailsView } from 'schema';

type Props = {
  activity: ActivityDetailsView;
};

const ActivityDiscounts = ({ activity }: Props) => {
  const discounts = useQuantityDiscounts(activity.id);

  return (
    <QuantityDiscountTable
      activity={activity}
      discounts={discounts.data ?? []}
      loading={discounts.isPending}
    />
  );
};

export default ActivityDiscounts;
