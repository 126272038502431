import { object, string } from 'yup';

import Stack from '@mui/material/Stack';
import FormEditor from 'components/FormEditor';
import FormTextField from 'components/FormTextField';

import { EditorJSData, PageDetails } from 'schema';

export type Fields = {
  title: string;
  content: EditorJSData | null;
  slug: string;
};

type Props = {
  page?: PageDetails;
};

export const schema = object({
  title: string().required('Prašome įvesti puslapio pavadinimą'),
  content: object().required('Prašome įvesti puslapio turinį'),
  slug: string().required('Prašome įvesti puslapio nuorodą'),
});

const PageFormFields = ({ page }: Props) => (
  <Stack spacing={4}>
    <FormTextField name="title" label="Pavadinimas" />
    <FormTextField name="slug" label="Nuoroda" disabled={page?.isReadOnly} />
    <FormEditor name="content" label="Turinys" />
  </Stack>
);

export default PageFormFields;
