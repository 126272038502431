import { ChangeEvent, KeyboardEvent, useCallback, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Paper, { PaperProps } from '@mui/material/Paper';
import TextField from '@mui/material/TextField';

import { MenuKey } from 'schema';

type MenuItem = {
  name: string;
};

type Props = PaperProps & {
  menuKey: MenuKey;
  onAdd: (item: MenuItem) => void;
};

const MenuEditorAddItem = ({ menuKey, onAdd, ...props }: Props) => {
  const [name, setName] = useState('');

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  }, []);

  const handleSubmit = useCallback(() => {
    if (name.length < 1) return;

    onAdd({ name });
    setName('');
  }, [name, onAdd]);

  const handleKeyUp = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter') {
        handleSubmit();
      }
    },
    [handleSubmit]
  );

  return (
    <Paper {...props}>
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ flexGrow: 1 }}>
          <TextField
            fullWidth
            placeholder="Naujas meniu punktas"
            value={name}
            onChange={handleChange}
            onKeyUp={handleKeyUp}
          />
        </Box>

        <Box sx={{ p: 1 }}>
          <IconButton onClick={handleSubmit}>
            <AddIcon />
          </IconButton>
        </Box>
      </Box>
    </Paper>
  );
};

export default MenuEditorAddItem;
