import { useCallback } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import Link from '@mui/material/Link';
import TableCell from '@mui/material/TableCell';
import TableRow from 'components/TableRow';
import ConfirmableIconButton from 'components/ConfirmableIconButton';
import ImagePreview from 'components/ImagePreview';

import { useDeleteCategory } from 'features/Category';
import { CategoryListView } from 'schema';

type Props = {
  category: CategoryListView;
};

const CategoryTableRow = ({ category }: Props) => {
  const { id, name, photo } = category;
  const deleteCategory = useDeleteCategory();

  const handleDelete = useCallback(() => {
    deleteCategory.mutate({ category: id });
  }, [deleteCategory, id]);

  return (
    <TableRow>
      <TableCell width={110}>
        <ImagePreview image={photo} />
      </TableCell>

      <TableCell>
        <Link href={id.toString()}>{name}</Link>
      </TableCell>

      <TableCell align="right">
        <ConfirmableIconButton
          text={`Ar tikrai norite ištrinti "${name}"`}
          onConfirm={handleDelete}>
          <DeleteIcon fontSize="small" color="error" />
        </ConfirmableIconButton>
      </TableCell>
    </TableRow>
  );
};

export default CategoryTableRow;
