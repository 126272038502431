import PaidIcon from '@mui/icons-material/Paid';
import LoadingIconButton from 'components/LoadingIconButton';
import { useMarkPaid } from 'features/Payment';
import { useCallback } from 'react';
import { AdminPaymentListView } from 'schema';

type Props = {
  payment: AdminPaymentListView;
};

const PaymentMarkPaidButton = ({ payment }: Props) => {
  const mark = useMarkPaid(payment.id);

  const handleClick = useCallback(() => {
    mark.mutate();
  }, [mark]);

  if (payment.status !== 'pending') return null;

  return (
    <LoadingIconButton
      loading={mark.isPending}
      onClick={handleClick}
      size="small">
      <PaidIcon color="primary" fontSize="small" />
    </LoadingIconButton>
  );
};

export default PaymentMarkPaidButton;
