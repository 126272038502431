import { useState, MouseEvent } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import SessionInviteAttendantsMenuItem from 'components/SessionInviteAttendantsMenuItem';
import SessionSendMessageMenuItem from 'components/SessionSendMessageMenuItem';
import SessionEditMenuItem from 'components/SessionEditMenuItem';
import SessionDeleteMenuItem from 'components/SessionDeleteMenuItem';

import { ActivityDetailsView, CoachSessionDetailsView } from 'schema';

type Props = {
  activity: ActivityDetailsView;
  session: CoachSessionDetailsView;
};

const SessionDetailsMenu = ({ activity, session }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <SessionInviteAttendantsMenuItem
          session={session}
          onClick={handleClose}
        />

        <SessionSendMessageMenuItem session={session} onClick={handleClose} />
        <SessionEditMenuItem
          activity={activity}
          session={session}
          onClick={handleClose}
        />
        <SessionDeleteMenuItem session={session} />
      </Menu>
    </>
  );
};

export default SessionDetailsMenu;
