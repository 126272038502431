import Box, { BoxProps } from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

type Props = BoxProps;

const InfoBoxWithLabelSkeleton = (props: Props) => {
  return (
    <Box {...props}>
      <Typography variant="caption">
        <Skeleton width={150} />
      </Typography>

      <Typography>
        <Skeleton width={250} />
      </Typography>
    </Box>
  );
};

export default InfoBoxWithLabelSkeleton;
