import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import TableCell from '@mui/material/TableCell';
import EuroPrice from 'components/EuroPrice';
import TableRow, { TableRowProps } from 'components/TableRow';

import { formatDateTime } from 'lib';
import { ClubOrderListItem } from 'schema';

type Props = TableRowProps & {
  order: ClubOrderListItem;
};

const OrdersTableRow = ({ order, ...props }: Props) => {
  const { id, number, receivedAt, attendants, user, total, payment, invoice } =
    order;

  return (
    <TableRow {...props}>
      <TableCell>
        <Link href={id.toString()}>{number}</Link>
      </TableCell>

      <TableCell align="center">
        {receivedAt && formatDateTime(receivedAt)}
      </TableCell>

      <TableCell align="left">
        {attendants.map(({ fullName, session }, i) => (
          <Box key={i}>
            {fullName}: {session?.name}
          </Box>
        ))}
      </TableCell>

      <TableCell align="left">{user.fullName}</TableCell>

      <TableCell align="right">
        <EuroPrice>{total}</EuroPrice>
      </TableCell>

      <TableCell align="center">
        {payment?.paidAt && formatDateTime(payment.paidAt)}
      </TableCell>

      <TableCell align="right">{invoice?.invoiceNumber}</TableCell>
    </TableRow>
  );
};

export default OrdersTableRow;
