import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { InvoiceItemView } from 'schema';

type Props = {
  item: InvoiceItemView;
};

const InvoiceItemRow = ({ item }: Props) => {
  const notes = item.attendants.map(({ fullName }) => fullName).join(', ');

  return (
    <TableRow>
      <TableCell>{item.name}</TableCell>
      <TableCell>{notes}</TableCell>
      <TableCell align="center">{item.amount}</TableCell>
      <TableCell align="center">{item.price.toFixed(2)} €</TableCell>
      <TableCell align="right">{item.total.toFixed(2)} €</TableCell>
    </TableRow>
  );
};

export default InvoiceItemRow;
