import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from 'components/DialogTitle';
import Dialog from 'components/Dialog';
import SessionAttendantDialogContent from 'components/SessionAttendantDialogContent';

import { useDialog } from 'components/Dialogs';
import {
  CoachAttendantRegistrationListView,
  CoachSessionDetailsView,
} from 'schema';

type Props = {
  session: CoachSessionDetailsView;
  registration: CoachAttendantRegistrationListView;
};

export type SessionRegistrationDialogProps = Props;

const SessionRegistrationDialog = ({ session, registration }: Props) => {
  const [, { close }] = useDialog('sessionRegistration');
  const { fullName } = registration.attendant;

  return (
    <Dialog dialog="sessionRegistration">
      <DialogTitle onClose={close}>{fullName}</DialogTitle>

      <SessionAttendantDialogContent
        session={session}
        registration={registration}
      />

      <DialogActions>
        <Button onClick={close} variant="text">
          Uždaryti
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SessionRegistrationDialog;
