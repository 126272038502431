import { FormEvent, useCallback } from 'react';

import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import FullScreen from 'components/FullScreen';
import FullscreenAppLogo from 'components/FullscreenAppLogo';

import { useLogin } from 'features/Auth';

function Login() {
  const login = useLogin();

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const htmlForm = event.target as HTMLFormElement;
      const form = {
        email: htmlForm.email.value,
        password: htmlForm.password.value,
      };

      login.mutate(form);
    },
    [login]
  );

  const loading = login.isPending;

  return (
    <FullScreen>
      <Box sx={{ width: '500px' }}>
        <FullscreenAppLogo />

        <Paper sx={{ p: 2 }}>
          <Typography variant="h6">Prisijungimas</Typography>

          <form onSubmit={handleSubmit}>
            <Stack spacing={4}>
              <TextField
                name="email"
                variant="standard"
                label="El. paštas"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <PersonIcon />
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                name="password"
                type="password"
                variant="standard"
                label="Slaptažodis"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <LockIcon />
                    </InputAdornment>
                  ),
                }}
              />

              <LoadingButton
                loading={loading}
                variant="contained"
                type="submit">
                Prisijungti
              </LoadingButton>
            </Stack>
          </form>
        </Paper>
      </Box>
    </FullScreen>
  );
}

export default Login;
