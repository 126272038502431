import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from 'components/DialogTitle';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import Dialog from 'components/Dialog';

import { useDialog } from 'components/Dialogs';
import InfoBoxWithLabel from 'components/InfoBoxWithLabel';
import { formatDateTime } from 'lib';
import { HttpMessageView } from 'schema';
import JsonViewer from 'components/JsonViewer';
import DialogContent from 'components/DialogContent';

type Props = {
  message: HttpMessageView;
};

export type HttpRequestDialogProps = Props;

const HttpRequestDialog = ({ message }: Props) => {
  const [, { close }] = useDialog('httpMessage');

  const {
    uri,
    method,
    sentAt,
    requestData,
    responseStatus,
    responseData,
    errorMessage,
  } = message;

  return (
    <Dialog dialog="httpMessage" maxWidth="md">
      <DialogTitle onClose={close}>HTTP Užklausos detalės</DialogTitle>

      <DialogContent>
        <Stack spacing={2}>
          <Grid container>
            <Grid xs={6}>
              <InfoBoxWithLabel label="URL">{uri}</InfoBoxWithLabel>
            </Grid>

            <Grid xs={6}>
              <InfoBoxWithLabel label="Metodas">{method}</InfoBoxWithLabel>
            </Grid>
          </Grid>

          <Grid container>
            <Grid xs={6}>
              <InfoBoxWithLabel label="Laikas">
                {formatDateTime(sentAt, true)}
              </InfoBoxWithLabel>
            </Grid>

            <Grid xs={6}>
              {responseStatus && (
                <InfoBoxWithLabel label="HTTP statuso kodas">
                  {responseStatus}
                </InfoBoxWithLabel>
              )}
            </Grid>
          </Grid>

          {errorMessage && (
            <InfoBoxWithLabel label="Klaidos pranešimas">
              {errorMessage}
            </InfoBoxWithLabel>
          )}

          <InfoBoxWithLabel label="Užklusa">
            <JsonViewer data={requestData} />
          </InfoBoxWithLabel>

          {responseData && (
            <InfoBoxWithLabel label="Atsakymas">
              <JsonViewer data={responseData} />
            </InfoBoxWithLabel>
          )}
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={close} variant="contained">
          Uždaryti
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default HttpRequestDialog;
