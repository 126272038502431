import CircularProgress from '@mui/material/CircularProgress';
import FullScreen from 'components/FullScreen';

const AppSplash = () => (
  <FullScreen>
    <CircularProgress size={72} />
  </FullScreen>
);

export default AppSplash;
