import FilePreview, { FilePreviewProps } from 'components/FilePreview';
import { UploadField } from 'lib';

type Props = Omit<FilePreviewProps, 'children'> & {
  file: UploadField;
  alt: string;
};

export type FilePreviewCurrentProps = Props;

const FilePreviewCurrent = ({ file, alt, ...rest }: Props) => {
  const src =
    file.currentUrl ??
    (file.uploaded ? URL.createObjectURL(file.uploaded) : null);

  if (!src) return null;

  return (
    <FilePreview {...rest}>
      <img src={src} alt={alt} />
    </FilePreview>
  );
};

export default FilePreviewCurrent;
