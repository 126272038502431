import AddIcon from '@mui/icons-material/Add';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

type Props = IconButtonProps & {
  tooltip: string;
  loading?: boolean;
};

const ToolbarButton = (props: Props) => {
  const { tooltip, children = <AddIcon />, loading, ...rest } = props;

  return (
    <Tooltip title={tooltip}>
      <IconButton {...rest} color="inherit">
        {loading ? <CircularProgress size={24} /> : children}
      </IconButton>
    </Tooltip>
  );
};

export default ToolbarButton;
