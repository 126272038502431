import { useCallback } from 'react';
import { Form, Formik } from 'formik';

import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from 'components/DialogTitle';
import Dialog, { DialogProps } from 'components/Dialog';
import DialogContent from 'components/DialogContent';
import QuantityDiscountFormFields, {
  Fields,
  schema,
} from 'components/QuantityDiscountFormFields';

import { useDialog } from 'components/Dialogs';
import { useUpdateQuantityDiscount } from 'features/QuantityDiscount';
import { ActivityDetailsView, QuantityDiscountView } from 'schema';

type Props = DialogProps & {
  activity: ActivityDetailsView;
  discount: QuantityDiscountView;
};

export type EditQuantityDiscountDialogProps = Props;

const EditQuantityDiscountDialog = (props: Props) => {
  const { activity, discount, ...rest } = props;
  const { quantity, amount } = discount;
  const [, { close }] = useDialog('quantityDiscountEdit');
  const update = useUpdateQuantityDiscount(activity.id, discount.id);

  const initial = {
    quantity: quantity.toString(),
    amount: amount.toString(),
  };

  const handleSubmit = useCallback(
    ({ quantity, amount }: Fields) => {
      const form = {
        quantity: Number(quantity),
        amount: Number(amount),
      };

      update.mutate(form, {
        onSuccess: close,
      });
    },
    [close, update]
  );

  return (
    <Dialog {...rest} dialog="quantityDiscountEdit">
      <Formik<Fields>
        initialValues={initial}
        onSubmit={handleSubmit}
        validationSchema={schema}>
        <Form>
          <DialogTitle onClose={close}>Kiekio nuolaida</DialogTitle>

          <DialogContent>
            <QuantityDiscountFormFields />
          </DialogContent>

          <DialogActions>
            <Button onClick={close} variant="text">
              Atšaukti
            </Button>

            <LoadingButton
              loading={update.isPending}
              variant="contained"
              type="submit"
              startIcon={<SaveIcon />}>
              Saugoti
            </LoadingButton>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

export default EditQuantityDiscountDialog;
