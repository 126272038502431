import ClubMemberSelect, {
  ClubMemberSelectProps,
  Option as SelectOption,
} from 'components/ClubMemberSelect';
import { useField } from 'formik';
import { SyntheticEvent, useCallback } from 'react';
import { MemberDropdownOption } from 'schema';

export type Option = SelectOption;

type Props = Omit<ClubMemberSelectProps, 'value' | 'onChange'> & {
  name: string;
};

const FormClubMemberSelect = ({ name, ...props }: Props) => {
  const [{ value }, { error, touched }, { setValue }] =
    useField<MemberDropdownOption[]>(name);

  const hasError = Boolean(error && touched);

  const handleChange = useCallback(
    (_: SyntheticEvent, value: MemberDropdownOption[]) => {
      setValue(value);
    },
    [setValue]
  );

  return (
    <ClubMemberSelect
      value={value}
      onChange={handleChange}
      error={hasError}
      helperText={hasError && error}
      {...props}
    />
  );
};

export default FormClubMemberSelect;
