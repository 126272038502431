import { useCallback } from 'react';
import { Formik } from 'formik';

import AttendantPersonalInfoForm, {
  Fields,
  schema,
} from 'components/AttendantPersonalInfoForm';

import { useUpdateAttendant } from 'features/Attendant';
import { ClubAttendantDetails } from 'schema';

type Props = {
  attendant: ClubAttendantDetails;
};

const AttendantPersonalInfo = ({ attendant }: Props) => {
  const { mutate } = useUpdateAttendant(attendant.id);
  const { user, ...rest } = attendant;

  const handleSubmit = useCallback(
    (form: Fields) => {
      mutate(form);
    },
    [mutate]
  );

  const initial: Fields = { ...rest };

  if (user) {
    initial.user = {
      email: user.email,
      password: '',
      phone: user.phone ?? '',
    };
  }

  return (
    <Formik<Fields>
      initialValues={initial}
      onSubmit={handleSubmit}
      validationSchema={schema}>
      <AttendantPersonalInfoForm attendant={attendant} />
    </Formik>
  );
};

export default AttendantPersonalInfo;
