import { InputAdornment } from '@mui/material';
import FormTextField from 'components/FormTextField';
import Stack from 'components/Stack';
import { number, object } from 'yup';

export type Fields = {
  quantity: string;
  amount: string;
};

export const schema = object({
  quantity: number()
    .typeError('Prašome įvesti skaičių')
    .required('Prašome įvesti narių skaičių'),

  amount: number()
    .typeError('Prašome įvesti skaičių')
    .required('Prašome įvesti nuolaidos dydį'),
});

const QuantityDiscountFormFields = () => (
  <Stack>
    <FormTextField name="quantity" label="Dalyvių kiekis" />

    <FormTextField
      name="amount"
      label="Nuolaida"
      InputProps={{
        endAdornment: <InputAdornment position="end">%</InputAdornment>,
      }}
    />
  </Stack>
);

export default QuantityDiscountFormFields;
