import { useCallback } from 'react';
import { Form, Formik } from 'formik';

import SaveIcon from '@mui/icons-material/Save';
import DialogTitle from 'components/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import PageFormFields, { Fields, schema } from 'components/PageFormFields';

import { useDialog } from 'components/Dialogs';
import { useUpdatePage } from 'features/Page';
import { PageDetails } from 'schema';
import DialogContent from 'components/DialogContent';

type Props = {
  page: PageDetails;
  onUpdate: (page: PageDetails) => void;
};

const PageEditDialogForm = ({ page, onUpdate }: Props) => {
  const [, { close }] = useDialog('pageEdit');
  const update = useUpdatePage(page.id);

  const handleSubmit = useCallback(
    ({ content, ...rest }: Fields) => {
      const vars = {
        content: content!,
        ...rest,
      };

      update.mutate(vars, {
        onSuccess: onUpdate,
      });
    },
    [onUpdate, update]
  );

  return (
    <Formik<Fields>
      initialValues={page}
      onSubmit={handleSubmit}
      validationSchema={schema}>
      <Form>
        <DialogTitle onClose={close}>{page.name}</DialogTitle>

        <DialogContent>
          <PageFormFields page={page} />
        </DialogContent>

        <DialogActions>
          <Button onClick={close} variant="text">
            Atšaukti
          </Button>

          <LoadingButton
            loading={update.isPending}
            variant="contained"
            type="submit"
            startIcon={<SaveIcon />}>
            Saugoti
          </LoadingButton>
        </DialogActions>
      </Form>
    </Formik>
  );
};

export default PageEditDialogForm;
