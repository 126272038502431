import { useMutation } from '@tanstack/react-query';

import {
  activitySessionSendMessage,
  ActivitySessionSendMessageInput,
} from 'schema';

import { useErrorHandler } from 'lib';

export const sessionKeys = {
  all: [{ scope: 'sessions' }] as const,
};

type UseSendSessionMessage = Omit<
  ActivitySessionSendMessageInput,
  'activity' | 'session'
>;

export const useSendSessionMessage = (activity: number, session: number) =>
  useMutation({
    mutationFn: ({ form, files }: UseSendSessionMessage) =>
      activitySessionSendMessage({ activity, session, form, files }),

    onError: useErrorHandler(),
  });
