import Grid from '@mui/material/Unstable_Grid2';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

const ActivityCardSkeleton = () => {
  return (
    <Grid xs={12}>
      <Card sx={{ display: 'flex' }}>
        <Skeleton variant="rectangular" width={300} height={210} />

        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
          <CardContent sx={{ flexGrow: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                sx={{ flexGrow: 1 }}>
                <Skeleton width="40%" />
              </Typography>

              <Typography color="text.secondary" sx={{ fontWeight: 'bold' }}>
                <Skeleton width={100} />
              </Typography>
            </Box>

            <Divider sx={{ mb: 1 }} />

            <Typography color="text.secondary">
              <Skeleton width="16%" />
            </Typography>

            <Typography color="text.secondary">
              <Skeleton width="34%" />
            </Typography>

            <Typography color="text.secondary">
              <Skeleton width="26%" />
            </Typography>
          </CardContent>

          <CardActions sx={{ display: 'flex' }}>
            <Skeleton width={125} height={35} />
          </CardActions>
        </Box>
      </Card>
    </Grid>
  );
};

export default ActivityCardSkeleton;
