import { useCallback, MouseEvent } from 'react';

import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';

import { useDialog } from 'components/Dialogs';
import { CoachSessionDetailsView } from 'schema';

type Props = MenuItemProps & {
  session: CoachSessionDetailsView;
};

const SessionInviteAttendantsMenuItem = (props: Props) => {
  const { onClick, session, ...rest } = props;
  const [, { open }] = useDialog('sessionInvite');

  const handleClick = useCallback(
    (event: MouseEvent<HTMLLIElement>) => {
      open({ activity: session.activity, session });
      onClick && onClick(event);
    },
    [onClick, open, session]
  );

  return (
    <MenuItem onClick={handleClick} {...rest}>
      Pakviesti narius
    </MenuItem>
  );
};

export default SessionInviteAttendantsMenuItem;
