import { useCallback } from 'react';

import ReplayIcon from '@mui/icons-material/Replay';
import Tooltip from '@mui/material/Tooltip';
import ConfirmableIconButton from 'components/ConfirmableIconButton';
import CircularProgress from '@mui/material/CircularProgress';

import {
  CoachSessionDetailsView,
  CoachSessionInvitationListView,
} from 'schema';
import { useResendInvitation } from 'features/AttendantInvitation';

type Props = {
  session: CoachSessionDetailsView;
  invitation: CoachSessionInvitationListView;
};

const SessionInvitationResendButton = ({ session, invitation }: Props) => {
  const mutation = useResendInvitation(
    session.activity.id,
    session.id,
    invitation.id
  );

  const handleResendInvitation = useCallback(() => {
    mutation.mutate();
  }, [mutation]);

  return (
    <ConfirmableIconButton
      size="small"
      sx={{ ml: 1 }}
      onConfirm={handleResendInvitation}
      text="Ar tikrai norite kartoti kvietimą?">
      {mutation.isPending ? (
        <CircularProgress size={20} />
      ) : (
        <Tooltip title="Kartoti kvietimą">
          <ReplayIcon fontSize="small" color="primary" />
        </Tooltip>
      )}
    </ConfirmableIconButton>
  );
};

export default SessionInvitationResendButton;
