import Box, { BoxProps } from '@mui/material/Box';

const FullScreen = ({ sx = [], ...props }: BoxProps) => {
  return (
    <Box
      sx={[
        {
          backgroundColor: ({ palette }) =>
            palette.mode === 'light' ? palette.grey[100] : palette.grey[900],
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...props}
    />
  );
};

export default FullScreen;
