import TableCell from '@mui/material/TableCell';
import SessionsReservationsTableRow from 'components/SessionsReservationsTableRow';
import Table from 'components/Table';
import TableRow from 'components/TableRow';

import { useActivitySessionReservations } from 'features/SessionReservation';
import { CoachSessionDetailsView } from 'schema';

type Props = {
  session: CoachSessionDetailsView;
};

const SessionReservations = ({ session }: Props) => {
  const reservations = useActivitySessionReservations(
    session.activity.id,
    session.id
  );

  return (
    <Table
      loading={reservations.isPending}
      head={
        <TableRow>
          <TableCell component="th">El. paštas</TableCell>

          <TableCell component="th" align="center">
            Telefono nr.
          </TableCell>

          <TableCell component="th" align="center">
            Rezervacijos data
          </TableCell>

          <TableCell component="th">Pastabos</TableCell>

          <TableCell component="th" />
        </TableRow>
      }>
      {reservations.data?.map(reservation => (
        <SessionsReservationsTableRow
          key={reservation.id}
          session={session}
          reservation={reservation}
        />
      ))}
    </Table>
  );
};

export default SessionReservations;
