import { ChangeEvent, useCallback } from 'react';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Page from 'components/Page';

import { ColorMode, useColorMode } from 'components/Theme';

const SettingsPage = () => {
  const { mode, changeColorMode } = useColorMode();

  const handleModeChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, value: string) => {
      changeColorMode(value as ColorMode);
    },
    [changeColorMode]
  );

  return (
    <Page title="Nustatymai">
      <Paper sx={{ mb: 2, p: 2 }}>
        <FormControl>
          <FormLabel id="color-scheme-label">Spalvų schema</FormLabel>

          <RadioGroup
            aria-labelledby="color-scheme-label"
            name="color-scheme"
            value={mode}
            onChange={handleModeChange}>
            <FormControlLabel
              value="system"
              control={<Radio />}
              label="Naudoti sisteminę"
            />
            <FormControlLabel value="dark" control={<Radio />} label="Tamsi" />
            <FormControlLabel
              value="light"
              control={<Radio />}
              label="Šviesi"
            />
          </RadioGroup>
        </FormControl>
      </Paper>
    </Page>
  );
};

export default SettingsPage;
