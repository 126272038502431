import { SyntheticEvent, useCallback, useState } from 'react';

import AttendantSurveyTable from 'components/AttendantSurveyTable';
import Page from 'components/Page';
import Section from 'components/Section';
import ClubAttendantSelect from 'components/ClubAttendantSelect';

import { useClub } from 'components/ClubProvider';
import { useClubSurveys } from 'features/Club';
import { AttendantDropdownOption } from 'schema';

const SurveyList = () => {
  const [attendant, setAttendant] = useState<AttendantDropdownOption | null>(
    null
  );

  const surveys = useClubSurveys(useClub().id, {
    attendant: attendant?.id,
  });

  const handleChange = useCallback(
    (_: SyntheticEvent, value: AttendantDropdownOption | null) => {
      setAttendant(value);
    },
    []
  );

  return (
    <Page title="Tyrimai">
      <Section sx={{ mb: 2 }} title="Tyrimų paieška">
        <ClubAttendantSelect
          label="Pagal narį"
          value={attendant}
          onChange={handleChange}
          sx={{ mt: 2 }}
        />
      </Section>

      <AttendantSurveyTable
        surveys={surveys.data ?? []}
        loading={surveys.isPending}
      />
    </Page>
  );
};

export default SurveyList;
