import { ReactElement } from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import Tooltip from '@mui/material/Tooltip';

import { CoachSessionListView, SessionStatus } from 'schema';

type Props = {
  session: CoachSessionListView;
};

type IconConfig = {
  title: string;
  component: ReactElement;
};

type SessionStatusLookup = Record<SessionStatus, IconConfig>;

const lookup: SessionStatusLookup = {
  draft: {
    title: 'Nepublikuota',
    component: <UnpublishedIcon fontSize="small" color="disabled" />,
  },

  published: {
    title: 'Publikuota',
    component: <CheckCircleIcon fontSize="small" color="success" />,
  },

  expired: {
    title: 'Pasibaigęs galiojimas',
    component: <UnpublishedIcon fontSize="small" color="warning" />,
  },

  full: {
    title: 'Užpildytos visos vietos',
    component: <UnpublishedIcon fontSize="small" color="warning" />,
  },
};

const SessionStatusIcon = ({ session }: Props) => {
  const config = lookup[session.status];

  return <Tooltip title={config.title}>{config.component}</Tooltip>;
};

export default SessionStatusIcon;
