import { useQuery } from '@tanstack/react-query';
import { cityIndex } from 'schema';

export const cityKeys = {
  all: [{ scope: 'city' }] as const,

  lists: () => [{ ...cityKeys.all[0], entity: 'list' }] as const,
  list: () => [{ ...cityKeys.lists()[0] }] as const,
};

export const useCities = () =>
  useQuery({ queryKey: cityKeys.list(), queryFn: cityIndex });
