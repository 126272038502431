import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import HttpRequestTable from 'components/HttpRequestTable';
import InfoBoxWithLabelSkeleton from 'components/InfoBoxWithLabelSkeleton';

const InvoiceSyncDetailsSkeleton = () => (
  <Box>
    <Grid container>
      <Grid xs={6}>
        <InfoBoxWithLabelSkeleton />
      </Grid>

      <Grid xs={6}>
        <InfoBoxWithLabelSkeleton />
      </Grid>
    </Grid>

    <HttpRequestTable loading messages={[]} sx={{ mt: 2 }} />
  </Box>
);

export default InvoiceSyncDetailsSkeleton;
