import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from 'components/DialogTitle';
import Skeleton from '@mui/material/Skeleton';
import ButtonSkeleton from 'components/ButtonSkeleton';
import TextFieldSkeleton from 'components/TextFieldSkeleton';
import DialogContent from 'components/DialogContent';
import Stack from 'components/Stack';

const SessionEditDialogSkeleton = () => (
  <Box>
    <DialogTitle>
      <Skeleton />
    </DialogTitle>

    <DialogContent>
      <Stack>
        <TextFieldSkeleton />
        <TextFieldSkeleton />
        <TextFieldSkeleton />
      </Stack>
    </DialogContent>

    <DialogActions>
      <ButtonSkeleton />
      <ButtonSkeleton />
    </DialogActions>
  </Box>
);

export default SessionEditDialogSkeleton;
