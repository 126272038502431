import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Checkbox from 'components/FormCheckbox';
import FormTimePicker from 'components/FormTimePicker';

import { romanWeek, Weekday } from 'lib';

export type WeekDayScheduleFieldType = {
  enabled: boolean;
  weekday: Weekday;
  startTime: Date | null;
  endTime: Date | null;
};

type Props = {
  name: string;
};

const WeekDayScheduleField = ({ name }: Props) => {
  const fields = [];

  for (let i = 0; i <= 6; i++) {
    fields.push(
      <TableRow key={i} sx={{ '& td': { border: 0 } }}>
        <TableCell sx={{ pl: 0 }}>
          <Checkbox name={`${name}[${i}].enabled`} />
        </TableCell>

        <TableCell>{romanWeek(i + 1)}</TableCell>

        <TableCell align="center">
          <FormTimePicker name={`${name}[${i}].startTime`} />
        </TableCell>

        <TableCell align="right" sx={{ pr: 0 }}>
          <FormTimePicker name={`${name}[${i}].endTime`} />
        </TableCell>
      </TableRow>
    );
  }

  return (
    <Table size="small">
      <TableBody>{fields}</TableBody>
    </Table>
  );
};

export default WeekDayScheduleField;
