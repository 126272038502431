import FormActions from 'components/FormActions';
import FormButton from 'components/FormButton';
import Page from 'components/Page';
import PageFormFields, { Fields, schema } from 'components/PageFormFields';
import PageSkeleton from 'components/PageSkeleton';
import Section from 'components/Section';
import { usePage, useUpdatePage } from 'features/Page';
import { Form, Formik } from 'formik';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

const PageEdit = () => {
  const { id } = useParams();
  const page = usePage(Number(id));
  const update = useUpdatePage(Number(id));

  const handleSubmit = useCallback(
    ({ content, ...rest }: Fields) => {
      update.mutate({
        content: content!,
        ...rest,
      });
    },
    [update]
  );

  if (!page.data) return <PageSkeleton>Kraunasi...</PageSkeleton>;

  return (
    <Page title={page.data.title}>
      <Formik<Fields>
        initialValues={page.data}
        onSubmit={handleSubmit}
        validationSchema={schema}>
        <Form>
          <Section>
            <PageFormFields page={page.data} />

            <FormActions sx={{ mt: 2 }}>
              <FormButton loading={update.isPending} />
            </FormActions>
          </Section>
        </Form>
      </Formik>
    </Page>
  );
};

export default PageEdit;
