import DeleteIcon from '@mui/icons-material/Delete';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import ConfirmableIconButton from 'components/ConfirmableIconButton';
import { useDeletePublicHoliday } from 'features/PublicHoliday';
import { formatDate } from 'lib';
import { useCallback } from 'react';
import { PublicHolidayView } from 'schema';

type Props = {
  holiday: PublicHolidayView;
  pending?: boolean;
};

const PublicHolidayRow = ({ holiday, pending }: Props) => {
  const { mutate } = useDeletePublicHoliday(holiday.id);

  const handleDeleteClick = useCallback(() => {
    mutate();
  }, [mutate]);

  return (
    <TableRow>
      <TableCell sx={{ ...(pending && { color: 'red' }) }}>
        {formatDate(holiday.date)}
      </TableCell>

      <TableCell align="right">
        <ConfirmableIconButton onConfirm={handleDeleteClick} size="small">
          <DeleteIcon color="error" fontSize="small" />
        </ConfirmableIconButton>
      </TableCell>
    </TableRow>
  );
};

export default PublicHolidayRow;
