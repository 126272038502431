import {
  QueryFunctionContext,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { useNotification } from 'components/Notifications';
import { useErrorHandler } from 'lib';
import { attendantInvitationsKeys } from 'features/AttendantInvitation';

import {
  ActivitySessionReservationIndexInput,
  CoachSessionInvitationListView,
  CoachSessionReservationListView,
  SessionReservationUpdate,
  activitySessionReservationDelete,
  activitySessionReservationIndex,
  activitySessionReservationInvite,
  activitySessionReservationUpdate,
} from 'schema';

export const sessionReservationsKeys = {
  all: [{ scope: 'session-reservations' }] as const,

  lists: () => [{ ...sessionReservationsKeys.all[0], entity: 'list' }] as const,
  list: (params: ActivitySessionReservationIndexInput) =>
    [{ ...sessionReservationsKeys.lists()[0], params }] as const,
};

type SessionReservationsContext = QueryFunctionContext<
  ReturnType<(typeof sessionReservationsKeys)['list']>
>;

export const useActivitySessionReservations = (
  activity: number,
  session: number
) =>
  useQuery({
    queryKey: sessionReservationsKeys.list({ activity, session }),

    queryFn: async ({ queryKey: [{ params }] }: SessionReservationsContext) =>
      await activitySessionReservationIndex(params),
  });

export const useSendReservationInvitation = (
  activity: number,
  session: number,
  reservation: number
) => {
  const client = useQueryClient();
  const { pop } = useNotification();

  return useMutation({
    mutationFn: () =>
      activitySessionReservationInvite({ activity, session, reservation }),

    onSuccess: invitation => {
      client.setQueryData<CoachSessionReservationListView[]>(
        sessionReservationsKeys.list({ activity, session }),
        current => current?.filter(item => item.id !== reservation) ?? []
      );

      client.setQueryData<CoachSessionInvitationListView[]>(
        attendantInvitationsKeys.list({ activity, session }),
        current => [invitation, ...(current ?? [])]
      );

      client.invalidateQueries({
        queryKey: sessionReservationsKeys.list({ activity, session }),
      });

      client.invalidateQueries({
        queryKey: attendantInvitationsKeys.list({ activity, session }),
      });

      pop('Pakvietimas išsiųstas');
    },

    onError: useErrorHandler(),
  });
};

export const useUpdateReservation = (
  activity: number,
  session: number,
  reservation: number
) => {
  const { pop } = useNotification();
  const client = useQueryClient();

  return useMutation({
    mutationFn: (form: SessionReservationUpdate) =>
      activitySessionReservationUpdate({
        activity,
        session,
        reservation,
        form,
      }),

    onSuccess: () => {
      client.invalidateQueries({
        queryKey: sessionReservationsKeys.list({ activity, session }),
      });

      pop('Išsaugota');
    },

    onError: useErrorHandler(),
  });
};

export const useDeleteSessionReservation = (
  activity: number,
  session: number,
  reservation: number
) => {
  const queryClient = useQueryClient();
  const queryKey = sessionReservationsKeys.list({ activity, session });
  const handleError = useErrorHandler();

  return useMutation({
    mutationFn: () =>
      activitySessionReservationDelete({ activity, session, reservation }),

    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey });

      const snapshot =
        queryClient.getQueryData<CoachSessionReservationListView[]>(queryKey);

      queryClient.setQueryData<CoachSessionReservationListView[]>(
        queryKey,
        items => items?.filter(item => item.id !== session) ?? []
      );

      return { snapshot };
    },

    onError: (error: any, __, context) => {
      handleError(error);
      queryClient.setQueryData(queryKey, context?.snapshot);
    },

    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });
};
