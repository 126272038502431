import {
  MouseEvent,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';

import MenuIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Menu, { MenuProps } from '@mui/material/Menu';

type Props = Omit<MenuProps, 'open' | 'anchorEl' | 'onClose'>;

type MenuContextType = {
  close: () => void;
};

const MenuContext = createContext<MenuContextType>({
  close: () => {},
});

export const useMenu = () => useContext(MenuContext);

const MenuButton = (props: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const hondleOpen = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const close = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <MenuContext.Provider value={{ close }}>
      <IconButton size="small" onClick={hondleOpen}>
        <MenuIcon fontSize="small" />
      </IconButton>

      <Menu open={open} anchorEl={anchorEl} onClose={close} {...props} />
    </MenuContext.Provider>
  );
};

export default MenuButton;
