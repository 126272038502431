import WarningIcon from '@mui/icons-material/Warning';
import Tooltip from '@mui/material/Tooltip';
import { ActivityListView } from 'schema';

type Props = {
  activity: ActivityListView;
};

const ActivityCardWarnings = ({ activity }: Props) => {
  const {
    canPublish,
    hasPublishedSessions,
    hasAgreement,
    hasPersonalDataAgreement,
  } = activity;

  if (!canPublish && hasPublishedSessions) {
    const title = !hasAgreement
      ? 'Neįvestas sutarties tekstas'
      : !hasPersonalDataAgreement
      ? 'Neįvestas asmens duomenų saugojimo sutikimo tekstas'
      : '';

    return (
      <Tooltip title={title}>
        <WarningIcon color="error" fontSize="small" />
      </Tooltip>
    );
  }

  return null;
};

export default ActivityCardWarnings;
