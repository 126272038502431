export function stringToNumber(string: string | null): number | null {
  if (!string || string.length === 0) return null;

  return Number(string);
}

export function truncate(string: string, length = 15, trailer = '...'): string {
  let result = string.substring(0, length);

  if (string.length > length) {
    result += trailer;
  }

  return result;
}

export function randomString(length = 10): string {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;

  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }

  return result;
}
