import {
  QueryFunctionContext,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import {
  categoryCreate,
  categoryDelete,
  categoryIndex,
  CategoryListView,
  categoryShow,
  CategoryShowInput,
  categoryUpdate,
} from 'schema';

import { useErrorHandler } from 'lib';
import { useNotification } from 'components/Notifications';

export const categoryKeys = {
  all: [{ scope: 'categories' }] as const,

  lists: () => [{ ...categoryKeys.all[0], entity: 'list' }] as const,
  list: () => [{ ...categoryKeys.lists()[0] }] as const,

  details: () => [{ ...categoryKeys.all[0], entity: 'detail' }] as const,
  detail: (params: CategoryShowInput) =>
    [{ ...categoryKeys.details()[0], params }] as const,
};

type CategoryDetailsContext = QueryFunctionContext<
  ReturnType<(typeof categoryKeys)['detail']>
>;

export const useCategories = () =>
  useQuery({ queryKey: categoryKeys.list(), queryFn: categoryIndex });

export const useCategory = (category: number) =>
  useQuery({
    queryKey: categoryKeys.detail({ category }),
    queryFn: async ({ queryKey: [{ params }] }: CategoryDetailsContext) =>
      await categoryShow(params),
  });

export const useCreateCategory = () => {
  const queryClient = useQueryClient();
  const queryKey = categoryKeys.list();

  return useMutation({
    mutationFn: categoryCreate,
    onSuccess: data => {
      queryClient.setQueryData<CategoryListView[]>(queryKey, old => [
        ...(old ?? []),
        data,
      ]);
    },
    onError: useErrorHandler(),
  });
};

export const useUpdateCategory = (category: number) => {
  const queryClient = useQueryClient();
  const { pop } = useNotification();

  return useMutation({
    mutationFn: categoryUpdate,
    onSuccess: data => {
      queryClient.setQueryData(categoryKeys.detail({ category }), data);
      pop('Išsaugota');
    },
    onError: useErrorHandler(),
  });
};

export const useDeleteCategory = () => {
  const queryClient = useQueryClient();
  const queryKey = categoryKeys.list();
  const handleError = useErrorHandler();

  return useMutation({
    mutationFn: categoryDelete,
    onMutate: async ({ category }) => {
      const snapshot = queryClient.getQueryData<CategoryListView[]>(queryKey);

      queryClient.setQueryData<CategoryListView[]>(
        queryKey,
        items => items?.filter(item => item.id !== category) ?? []
      );

      return { snapshot };
    },

    onError: (error: any, __, context) => {
      handleError(error);
      queryClient.setQueryData(queryKey, context?.snapshot);
    },

    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });
};
