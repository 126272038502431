import { MouseEvent, useCallback } from 'react';

import Button from '@mui/material/Button';
import DialogTitle from 'components/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Dialog from 'components/Dialog';

import { useDialog } from 'components/Dialogs';
import DialogContent from 'components/DialogContent';

type Props = {
  title?: string;
  text?: string;
  onConfirm?: (event: MouseEvent<HTMLButtonElement>) => void;
};

export type ConfirmDialogProps = Props;

export const useConfirm = (props: Props) => {
  const [, { open }] = useDialog('confirm');

  return useCallback(
    (event: MouseEvent<any>) => {
      event.stopPropagation();

      open(props);
    },
    [open, props]
  );
};

function ConfirmDialog(props: Props) {
  const [, { close }] = useDialog('confirm');

  const {
    title = 'Patvirtinimas',
    text = 'Ar tikrai norite atlikti šį veiksmą?',
    onConfirm,
  } = props;

  const handleConfirm = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      close();
      onConfirm && onConfirm(event);
    },
    [close, onConfirm]
  );

  return (
    <Dialog dialog="confirm">
      <DialogTitle onClose={close}>{title}</DialogTitle>
      <DialogContent>{text}</DialogContent>

      <DialogActions>
        <Button autoFocus onClick={close}>
          Ne
        </Button>

        <Button onClick={handleConfirm}>Taip</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialog;
