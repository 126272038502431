import Link from '@mui/material/Link';
import { useDownloadInvoice } from 'features/Invoice';

type Invoice = {
  id: number;
  invoiceNumber: string;
};

type Props = {
  invoice: Invoice;
};

const InvoiceDownload = ({ invoice }: Props) => {
  const [download, isDownloading] = useDownloadInvoice(invoice);

  if (isDownloading) return <>Palaukite...</>;

  return <Link onClick={download}>{invoice.invoiceNumber}</Link>;
};

export default InvoiceDownload;
