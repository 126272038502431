import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import ClubMembersTableRow from 'components/ClubMembersTableRow';
import Table, { TableProps } from 'components/Table';
import { ClubMemberListView } from 'schema';

type Props = TableProps & {
  members: ClubMemberListView[];
};

const ClubMembersTable = ({ members, ...props }: Props) => (
  <Table
    head={
      <TableRow>
        <TableCell>Vardas</TableCell>
        <TableCell align="center">El. paštas</TableCell>
        <TableCell align="center">Treneris</TableCell>
        <TableCell align="center">Administratorius</TableCell>
        <TableCell />
      </TableRow>
    }
    {...props}>
    {members.map(member => (
      <ClubMembersTableRow key={member.id} member={member} />
    ))}
  </Table>
);

export default ClubMembersTable;
