import { useCallback } from 'react';
import { Form, Formik } from 'formik';

import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from 'components/DialogTitle';
import Dialog from 'components/Dialog';
import DialogContent from 'components/DialogContent';
import Stack from 'components/Stack';

import { useDialog } from 'components/Dialogs';
import {
  CoachSessionDetailsView,
  CoachSessionReservationListView,
} from 'schema';
import { useUpdateReservation } from 'features/SessionReservation';
import Typography from '@mui/material/Typography';
import FormTextField from 'components/FormTextField';

type Fields = {
  notes: string;
};

type Props = {
  session: CoachSessionDetailsView;
  reservation: CoachSessionReservationListView;
};

export type SessionReservationDialogProps = Props;

const SessionReservationDialog = ({ session, reservation }: Props) => {
  const [, { close }] = useDialog('sessionReservation');

  const update = useUpdateReservation(
    session.activity.id,
    session.id,
    reservation.id
  );

  const initial = {
    notes: reservation.notes ?? '',
  };

  const handleSubmit = useCallback(
    (values: Fields) => {
      update.mutate(values, {
        onSuccess: close,
      });
    },
    [close, update]
  );

  return (
    <Dialog dialog="sessionReservation">
      <Formik<Fields> initialValues={initial} onSubmit={handleSubmit}>
        <Form>
          <DialogTitle onClose={close}>Reservacija</DialogTitle>

          <DialogContent>
            <Stack>
              <Typography>El. paštas: {reservation.user.email}</Typography>
              <FormTextField
                multiline
                fullWidth
                name="notes"
                label="Pastabos"
                rows={2}
              />
            </Stack>
          </DialogContent>

          <DialogActions>
            <Button onClick={close} variant="text">
              Atšaukti
            </Button>

            <LoadingButton
              loading={update.isPending}
              variant="contained"
              type="submit"
              startIcon={<SaveIcon />}>
              Saugoti
            </LoadingButton>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

export default SessionReservationDialog;
