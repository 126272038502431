import Dialog from 'components/Dialog';
import PageEditDialogForm from 'components/PageEditDialogForm';
import DialogSkeleton from 'components/DialogSkeleton';
import PageFormFieldsSkeleton from 'components/PageFormFieldsSkeleton';

import { useDialog } from 'components/Dialogs';
import { usePage } from 'features/Page';
import { PageDetails, PageListView } from 'schema';
import { useCallback } from 'react';

type Props = {
  page: PageListView;
  onUpdate: (page: PageDetails) => void;
};

export type PageEditDialogProps = Props;

const PageEditDialog = ({ page, onUpdate }: Props) => {
  const [, { close }] = useDialog('pageEdit');
  const pageQuery = usePage(page.id);

  const handleUpdate = useCallback(
    (page: PageDetails) => {
      close();
      onUpdate(page);
    },
    [close, onUpdate]
  );

  return (
    <Dialog dialog="pageEdit" maxWidth="md">
      {pageQuery.data ? (
        <PageEditDialogForm page={pageQuery.data} onUpdate={handleUpdate} />
      ) : (
        <DialogSkeleton>
          <PageFormFieldsSkeleton />
        </DialogSkeleton>
      )}
    </Dialog>
  );
};

export default PageEditDialog;
