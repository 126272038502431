import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { ReactNode } from 'react';

type Props<T> = Omit<
  AutocompleteProps<T, false, false, false>,
  'renderInput'
> & {
  options: T[];
  label?: string;
  renderInput?: AutocompleteProps<T, false, false, false>['renderInput'];
  error?: boolean;
  helperText?: ReactNode;
};

export type SelectProps<T> = Props<T>;

export type SelectOption = {
  id: number;
  name: string;
};

function isOptionEqualToValue<T extends SelectOption>(option: T, value: T) {
  return option.id === value.id;
}

function Select<T extends SelectOption>(props: Props<T>) {
  const { label, error, helperText, ...rest } = props;

  return (
    <Autocomplete<T, false, false, false>
      loading
      loadingText="Kraunasi..."
      noOptionsText="Nėra pasirinkimų"
      getOptionLabel={option => option.name}
      isOptionEqualToValue={isOptionEqualToValue}
      renderInput={params => (
        <TextField
          {...params}
          variant="standard"
          label={label}
          error={error}
          helperText={helperText}
        />
      )}
      renderOption={(props, option, { selected }) => (
        <MenuItem {...props} selected={selected}>
          {option.name}
        </MenuItem>
      )}
      {...rest}
    />
  );
}

export default Select;
