import MuiDialogContent, {
  DialogContentProps as MuiDialogContentProps,
} from '@mui/material/DialogContent';

const DialogContent = ({ sx = [], ...props }: MuiDialogContentProps) => (
  <MuiDialogContent
    dividers
    sx={[{ overflowY: 'inherit' }, ...(Array.isArray(sx) ? sx : [sx])]}
    {...props}
  />
);

export default DialogContent;
