import { useCallback } from 'react';
import { useField } from 'formik';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import DropZone from 'components/DropZone';
import PreviewThumb from 'components/PreviewThumb';

import { UploadField } from 'lib';

type Props = {
  name: string;
  label?: string;
};

const FormMultiImageUpload = ({ name, label }: Props) => {
  const [{ value }, , { setValue }] = useField<UploadField[]>(name);

  const handleDrop = useCallback(
    (accepted: File[]) => {
      setValue([
        ...value,
        ...accepted.map(file => ({ currentUrl: null, uploaded: file })),
      ]);
    },
    [setValue, value]
  );

  const handleDelete = useCallback(
    (i: number) => () => {
      const newValue = [...value];
      newValue.splice(i, 1);

      setValue(newValue);
    },
    [setValue, value]
  );

  return (
    <Box>
      {label && (
        <Typography variant="caption" color="text.secondary">
          {label}
        </Typography>
      )}

      <DropZone multiple onDrop={handleDrop} />

      <Grid container spacing={1} sx={{ mt: 2 }}>
        {value.map((preview, i) => (
          <PreviewThumb
            height={100}
            key={i}
            file={preview}
            alt={preview.currentUrl ?? 'Nuotrauka'}
            onDelete={handleDelete(i)}
          />
        ))}
      </Grid>
    </Box>
  );
};

export default FormMultiImageUpload;
