import { useCallback, useState, SyntheticEvent, ChangeEvent } from 'react';

import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';
import Page from 'components/Page';
import SessionSelect from 'components/SessionSelect';
import YesNoSelect from 'components/YesNoSelect';
import DatePicker from 'components/DatePicker';
import InvoicesTable from 'components/InvoicesTable';
import UnsentInvoicesCard from 'components/UnsentInvoicesCard';
import UnpaidInvoicesCard from 'components/UnpaidInvoicesCard';
import ActivitySelect from 'components/ActivitySelect';
import Section from 'components/Section';

import { formatDate } from 'lib';
import { useInvoices } from 'features/Invoice';

import {
  ActivityListView,
  ClubInvoiceIndexParams,
  SessionDropdownOption,
} from 'schema';

type FilterState = {
  session: SessionDropdownOption | null;
  activity: ActivityListView | null;
  search: string;
  from: Date | null;
  to: Date | null;
  sent: boolean | null;
  paid: boolean | null;
  periodic: boolean | null;
};

const buildFilterParams = (state: FilterState): ClubInvoiceIndexParams => {
  const { session, activity, search, from, to, sent, paid, periodic } = state;
  const params: ClubInvoiceIndexParams = {};

  if (session) {
    params.session = session.id;
  }

  if (activity) {
    params.activity = activity.id;
  }

  if (search) {
    params.search = search;
  }

  if (from) {
    params.from = formatDate(from);
  }

  if (to) {
    params.to = formatDate(to);
  }

  if (sent !== null) {
    params.sent = sent;
  }

  if (paid !== null) {
    params.paid = paid;
  }

  if (periodic !== null) {
    params.periodic = periodic;
  }

  return params;
};

const InvoicesList = () => {
  const [session, setSession] = useState<SessionDropdownOption | null>(null);
  const [activity, setActivity] = useState<ActivityListView | null>(null);
  const [search, setSearch] = useState<string>('');
  const [from, setFrom] = useState<Date | null>(null);
  const [to, setTo] = useState<Date | null>(null);
  const [sent, setSent] = useState<boolean | null>(null);
  const [periodic, setPeriodic] = useState<boolean | null>(null);
  const [paid, setPaid] = useState<boolean | null>(null);

  const invoices = useInvoices(
    buildFilterParams({
      session,
      activity,
      search,
      from,
      to,
      sent,
      paid,
      periodic,
    })
  );

  const handleSessionChange = useCallback(
    (_: SyntheticEvent, value: SessionDropdownOption | null) => {
      setSession(value);
    },
    []
  );

  const handleActivityChange = useCallback(
    (_: SyntheticEvent, value: ActivityListView | null) => {
      setActivity(value);
    },
    []
  );

  const handleChangeSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearch(event.target.value);
    },
    []
  );

  return (
    <Page title="Atsiskaitymai">
      <Grid container spacing={2} sx={{ mb: 1 }}>
        <Grid xs={4}>
          <UnsentInvoicesCard />
        </Grid>

        <Grid xs={4}>
          <UnpaidInvoicesCard />
        </Grid>
      </Grid>

      <Section sx={{ mb: 2 }} title="Sąskaitos">
        <Grid container spacing={2} sx={{ pt: 2, pb: 2 }}>
          <Grid xs={3} sx={{ pb: 2 }}>
            <TextField
              variant="standard"
              fullWidth
              label="Paieška"
              value={search}
              onChange={handleChangeSearch}
            />
          </Grid>

          <Grid xs={3} sx={{ pb: 2 }}>
            <SessionSelect
              label="Grupė"
              value={session}
              onChange={handleSessionChange}
            />
          </Grid>

          <Grid xs={3} sx={{ pb: 2 }}>
            <ActivitySelect
              label="Veikla"
              value={activity}
              onChange={handleActivityChange}
            />
          </Grid>

          <Grid xs={3} sx={{ pb: 2 }}>
            <YesNoSelect
              fullWidth
              label="Sąskaita išsiųsta"
              value={sent}
              onChange={setSent}
            />
          </Grid>

          <Grid xs={3}>
            <DatePicker
              fullWidth
              label="Sukurta nuo"
              value={from}
              onChange={setFrom}
            />
          </Grid>

          <Grid xs={3}>
            <DatePicker
              fullWidth
              label="Sukurta iki"
              value={to}
              onChange={setTo}
            />
          </Grid>

          <Grid xs={3} sx={{ pb: 2 }}>
            <YesNoSelect
              fullWidth
              label="Sąskaitos tipas"
              value={periodic}
              onChange={setPeriodic}
              yesText="Periodinis"
              noText="Registracija"
            />
          </Grid>

          <Grid xs={3}>
            <YesNoSelect
              fullWidth
              label="Apmokėta"
              value={paid}
              onChange={setPaid}
            />
          </Grid>
        </Grid>
      </Section>

      <InvoicesTable
        loading={invoices.isPending}
        invoices={invoices.data ?? []}
      />
    </Page>
  );
};

export default InvoicesList;
