import { useCallback } from 'react';
import { Formik } from 'formik';

import ActivityConfirmationEmailSettingsForm from 'components/ActivityConfirmationEmailSettingsForm';

import { useUpdateActivityConfirmationEmail } from 'features/Activity';
import {
  ActivityConfirmationEmailSettings as ActivityConfirmationEmailSettingsType,
  ActivityDetailsView,
  EditorJSData,
} from 'schema';

type Fields = {
  useDefault: boolean;
  template: EditorJSData | null;
};

type Props = {
  activity: ActivityDetailsView;
  settings: ActivityConfirmationEmailSettingsType;
};

const ActivityConfirmationEmailSettings = ({ activity, settings }: Props) => {
  const update = useUpdateActivityConfirmationEmail(activity.id);

  const handleSave = useCallback(
    (values: Fields) => {
      update.mutate(values);
    },
    [update]
  );

  return (
    <Formik initialValues={settings} onSubmit={handleSave}>
      <ActivityConfirmationEmailSettingsForm
        settings={settings}
        loading={update.isPending}
      />
    </Formik>
  );
};

export default ActivityConfirmationEmailSettings;
