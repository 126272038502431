import { StrictMode } from 'react';
import { AxiosResponse } from 'axios';
import ReactDOM from 'react-dom/client';

import Debugger from './components/Debugger';

import { ApiResponse } from 'schema';

let requests: AxiosResponse<ApiResponse<any>>[] = [];

const debuggerElement = document.createElement('div');
debuggerElement.setAttribute('id', 'debugger-root');
document.body.appendChild(debuggerElement);

const debuggerRoot = ReactDOM.createRoot(debuggerElement);

export function intercept(request: AxiosResponse<ApiResponse<any>>) {
  if (process.env.REACT_APP_ENV === 'production') return;

  requests = [request, ...requests];

  debuggerRoot.render(
    <StrictMode>
      <Debugger data={requests} />
    </StrictMode>
  );
}
