import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ToolbarButton from 'components/ToolbarButton';

import { useDownload } from 'lib';
import { CoachSessionDetailsView } from 'schema';

type Props = {
  session: CoachSessionDetailsView;
};

const SessionAttendantsExportButton = ({ session }: Props) => {
  const [download, isDownloading] = useDownload(
    `/api/v1/activities/${session.activity.id}/sessions/${session.id}/attendants/export` // TODO: @Hardcode
  );

  return (
    <ToolbarButton
      loading={isDownloading}
      tooltip="Narių exportas"
      onClick={download}>
      <FileDownloadIcon />
    </ToolbarButton>
  );
};

export default SessionAttendantsExportButton;
