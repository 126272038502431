import { format } from 'date-fns/format';
import { formatDistance } from 'date-fns/formatDistance';

import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  DATE_TIME_SECONDS_FORMAT,
  locale,
} from 'const';

export enum Weekday {
  Monday = 1,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
  Sunday,
}

const romanNumbers = {
  [Weekday.Monday]: 'I',
  [Weekday.Tuesday]: 'II',
  [Weekday.Wednesday]: 'III',
  [Weekday.Thursday]: 'IV',
  [Weekday.Friday]: 'V',
  [Weekday.Saturday]: 'VI',
  [Weekday.Sunday]: 'VII',
};

export function romanWeek(day: Weekday) {
  return romanNumbers[day];
}

const weekDays = {
  [Weekday.Monday]: 'Pirmadienis',
  [Weekday.Tuesday]: 'Antradienis',
  [Weekday.Wednesday]: 'Trečiadienis',
  [Weekday.Thursday]: 'Ketvirtadienis',
  [Weekday.Friday]: 'Penktadienis',
  [Weekday.Saturday]: 'Šeštadienis',
  [Weekday.Sunday]: 'Sekmadienis',
};

export function weekDayName(day: Weekday) {
  return weekDays[day];
}

export function makeTime(time: string) {
  const [hours, minutes] = time.split(':');

  const date = new Date();

  date.setHours(Number(hours));
  date.setMinutes(Number(minutes));

  return date;
}

export function formatAgo(date: Date) {
  return formatDistance(date, new Date(), {
    locale,
    addSuffix: true,
  });
}

export function formatDate(date: Date) {
  return format(date, DATE_FORMAT);
}

export function formatDateTime(date: Date, time = false) {
  return format(date, time ? DATE_TIME_SECONDS_FORMAT : DATE_TIME_FORMAT);
}
