import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import DatePicker from 'components/DatePicker';
import Page from 'components/Page';
import PublicHolidayRow from 'components/PublicHolidayRow';
import Table from 'components/Table';
import { isSameDay } from 'date-fns/isSameDay';
import {
  useCreatePublicHoliday,
  usePublicHolidays,
} from 'features/PublicHoliday';
import { useCallback, useState } from 'react';

const PublicHolidays = () => {
  // TODO: should maybe use formik form here, perhaps maybe not
  const [valueToAdd, setValueToAdd] = useState<Date | null>(null);
  const holidays = usePublicHolidays();
  const add = useCreatePublicHoliday();

  const invalid = !!(holidays.data ?? []).find(
    ({ date }) => valueToAdd && isSameDay(date, valueToAdd)
  );

  const handleAddClick = useCallback(() => {
    if (!valueToAdd) return;

    add.mutate({ date: valueToAdd });
    setValueToAdd(null);
  }, [add, valueToAdd]);

  return (
    <Page title="Valstybinės šventės">
      <Paper sx={{ mb: 2, p: 2, display: 'flex', flexDirection: 'row' }}>
        <Box sx={{ flexGrow: 1 }}>
          <DatePicker
            fullWidth
            value={valueToAdd}
            onChange={setValueToAdd}
            error={invalid}
            helperText={
              invalid ? 'Tokia valstybinė šventė jau egzistuoja' : undefined
            }
          />
        </Box>

        <Box>
          <Button
            variant="contained"
            sx={{ ml: 2 }}
            startIcon={<AddIcon />}
            disabled={invalid}
            onClick={handleAddClick}>
            Pridėti
          </Button>
        </Box>
      </Paper>

      <Table
        loading={holidays.isPending}
        head={
          <TableRow>
            <TableCell component="th">Data</TableCell>
            <TableCell component="th" />
          </TableRow>
        }>
        {holidays.data?.map(holiday => (
          <PublicHolidayRow key={holiday.id} holiday={holiday} />
        )) ?? []}
      </Table>
    </Page>
  );
};

export default PublicHolidays;
