import { useParams } from 'react-router-dom';

import DownloadIcon from '@mui/icons-material/Download';
import LoadingButton from '@mui/lab/LoadingButton';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import { useClub } from 'components/ClubProvider';
import NoPhoto from 'components/NoPhoto';
import UserAvatar from 'components/UserAvatar';

import { formatDate, useDownload } from 'lib';
import { ClubOrderAttendant } from 'schema';

type Props = {
  attendant: ClubOrderAttendant;
};

const OrderAttendantCard = ({ attendant }: Props) => {
  const { session } = attendant;
  const { order } = useParams();
  const club = useClub();

  const [download, isDownloading] = useDownload(
    `/api/v1/clubs/${club.id}/orders/${Number(order)}/attendants/${
      attendant.id
    }/agreement`
  );

  return (
    <Grid xs={12} sm={12} md={9} lg={6} xl={4}>
      <Card>
        <CardHeader
          avatar={<UserAvatar user={attendant} />}
          title={attendant.fullName}
          subheader={formatDate(attendant.dateOfBirth)}
        />

        {session?.activity.photo ? (
          <CardMedia
            component="img"
            height="140"
            image={session.activity.photo.publicUrl}
            alt={session.name}
          />
        ) : (
          <NoPhoto />
        )}

        <CardContent>
          <Typography gutterBottom variant="h5">
            {session?.activity.name}
          </Typography>

          <Typography variant="body2" color="text.secondary">
            {session?.name}
          </Typography>
        </CardContent>

        <CardActions>
          <LoadingButton
            disabled={!attendant.agreement}
            startIcon={<DownloadIcon />}
            onClick={download}
            loading={isDownloading}>
            {attendant.agreement ? 'Sutartis' : 'Užsakymas nebaigtas'}
          </LoadingButton>
        </CardActions>
      </Card>
    </Grid>
  );
};

export default OrderAttendantCard;
