import FormSimpleSelect, {
  FormSimpleSelectProps,
} from 'components/FormSimpleSelect';
import { ActivityType } from 'schema';

type Option = {
  value: ActivityType;
  label: string;
};

type Props = Omit<FormSimpleSelectProps<ActivityType>, 'options'>;

const options: Option[] = [
  {
    value: 'camp',
    label: 'Stovykla',
  },
  {
    value: 'regular',
    label: 'Būrelis',
  },
];

const ActivityTypeSelect = (props: Props) => {
  return <FormSimpleSelect {...props} options={options} />;
};

export default ActivityTypeSelect;
