import { object, string } from 'yup';

import Stack from '@mui/material/Stack';
import FormImageUpload from 'components/FormImageUpload';
import FormTextField from 'components/FormTextField';

import { UploadField } from 'lib';

export type FormFields = {
  name: string;
  photo: UploadField | null;
};

export const schema = object({
  name: string().required('Prašome įvesti kategorijos pavadinimą'),
});

const CategoryFormFields = () => (
  <Stack spacing={4} sx={{ mb: 2 }}>
    <FormTextField name="name" label="Pavadinimas" />
    <FormImageUpload name="photo" label="Nuotrauka" />
  </Stack>
);

export default CategoryFormFields;
