import { useCallback } from 'react';
import { Form, Formik } from 'formik';
import { object } from 'yup';

import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from 'components/DialogTitle';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Dialog from 'components/Dialog';
import DialogContent from 'components/DialogContent';
import FormSessionSelect from 'components/FormSessionSelect';

import { useDialog } from 'components/Dialogs';
import { useChangeSession } from 'features/AttendantRegistration';
import { SelectOption } from 'components/Select';
import {
  CoachAttendantRegistrationListView,
  CoachSessionDetailsView,
} from 'schema';

type Fields = {
  session: SelectOption | null;
};

type Props = {
  session: CoachSessionDetailsView;
  registration: CoachAttendantRegistrationListView;
};

export type ChangeSessionDialogProps = Props;

const schema = object({
  session: object().required('Prašome pasirinkti naują grupę'),
});

const ChangeSessionDialog = ({ session, registration }: Props) => {
  const [, { close }] = useDialog('changeSession');
  const change = useChangeSession(
    session.activity.id,
    session.id,
    registration.id
  );

  const handleSubmit = useCallback(
    ({ session }: Fields) => {
      const form = {
        id: session!.id,
      };

      change.mutate(form, {
        onSuccess: close,
      });
    },
    [change, close]
  );

  const initial = {
    session,
  };

  return (
    <Dialog dialog="changeSession">
      <Formik<Fields>
        initialValues={initial}
        onSubmit={handleSubmit}
        validationSchema={schema}>
        <Form>
          <DialogTitle onClose={close}>Keisti grupę</DialogTitle>

          <DialogContent>
            <Grid container sx={{ mb: 2 }}>
              <Grid xs={6}>
                <Typography color="text.secondary">Dalyvis:</Typography>
              </Grid>
              <Grid xs={6}>{registration.attendant.fullName}</Grid>
            </Grid>

            <FormSessionSelect name="session" label="Nauja grupė" />
          </DialogContent>

          <DialogActions>
            <Button onClick={close} variant="text">
              Atšaukti
            </Button>

            <LoadingButton
              loading={change.isPending}
              variant="contained"
              type="submit">
              Keisti
            </LoadingButton>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

export default ChangeSessionDialog;
