import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Table, { TableProps } from 'components/Table';
import InvoicesTableRow from 'components/InvoicesTableRow';

import { ClubInvoiceListView } from 'schema';

type Props = TableProps & {
  invoices: ClubInvoiceListView[];
};

const InvoicesTable = ({ invoices, ...props }: Props) => (
  <Table
    head={
      <TableRow>
        <TableCell />
        <TableCell component="th">Klientas</TableCell>

        <TableCell component="th" align="center">
          Kontaktai
        </TableCell>

        <TableCell component="th" align="center">
          Sąskaitos tipas
        </TableCell>

        <TableCell component="th" align="center">
          Ataskaitinis laikotarpis
        </TableCell>

        <TableCell component="th" align="center">
          Suma
        </TableCell>

        <TableCell component="th" align="center">
          Sąskaita
        </TableCell>

        <TableCell component="th" align="center">
          Sąskaitos data
        </TableCell>

        <TableCell component="th" align="center">
          Išsiųsta
        </TableCell>

        <TableCell component="th" align="center">
          Sumokėta suma
        </TableCell>

        <TableCell component="th" align="center">
          Apmokėjimo data
        </TableCell>

        <TableCell component="th" align="right"></TableCell>
      </TableRow>
    }
    {...props}>
    {invoices.map(invoice => (
      <InvoicesTableRow key={invoice.id} invoice={invoice} />
    ))}
  </Table>
);

export default InvoicesTable;
