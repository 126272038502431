import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import LinkBehavior from 'components/LinkBehavior';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { PaletteMode } from '@mui/material';

export type ColorMode = PaletteMode | 'system';

type ColorModeContextType = {
  mode: ColorMode;
  changeColorMode: (mode: ColorMode) => void;
};

type Props = {
  children?: ReactNode;
};

const ColorModeContext = createContext<ColorModeContextType>({
  mode: 'system',
  changeColorMode: () => {},
});

export const useColorMode = () => useContext(ColorModeContext);

const selector = '(prefers-color-scheme: dark)';
const key = 'igd_color_mode';

const systemMode = (): PaletteMode =>
  window.matchMedia && window.matchMedia(selector).matches ? 'dark' : 'light';

const Theme = ({ children }: Props) => {
  const [mode, setMode] = useState<ColorMode>(
    (localStorage.getItem(key) as ColorMode) ?? 'system'
  );

  const [palleteMode, setPalleteMode] = useState(
    mode === 'system' ? systemMode() : mode
  );

  const colorMode = useMemo(
    () => ({
      mode,
      changeColorMode: (newMode: ColorMode) => {
        localStorage.setItem(key, newMode);
        setMode(newMode);

        if (newMode === 'system') {
          setPalleteMode(systemMode());
        } else {
          setPalleteMode(newMode);
        }
      },
    }),
    [mode]
  );

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: palleteMode,
        },
        shape: {
          borderRadius: 2,
        },
        components: {
          MuiLink: {
            defaultProps: {
              // @ts-ignore
              component: LinkBehavior,
            },
          },
          MuiButtonBase: {
            defaultProps: {
              LinkComponent: LinkBehavior,
            },
          },
        },
      }),
    [palleteMode]
  );

  useEffect(() => {
    if (mode !== 'system') return;

    const handler = (event: MediaQueryListEvent) => {
      setPalleteMode(event.matches ? 'dark' : 'light');
    };

    window.matchMedia(selector).addEventListener('change', handler);

    return () => {
      window.matchMedia(selector).removeEventListener('change', handler);
    };
  }, [mode]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default Theme;
