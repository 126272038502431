import DeleteIcon from '@mui/icons-material/Delete';
import Link from '@mui/material/Link';
import TableCell from '@mui/material/TableCell';
import ConfirmableIconButton from 'components/ConfirmableIconButton';
import { useDialog } from 'components/Dialogs';
import TableRow, { TableRowProps } from 'components/TableRow';
import { useDeleteQuantityDiscount } from 'features/QuantityDiscount';
import { useCallback } from 'react';
import { ActivityDetailsView, QuantityDiscountView } from 'schema';

type Props = TableRowProps & {
  activity: ActivityDetailsView;
  discount: QuantityDiscountView;
};

const QuantityDiscountTableRow = ({ activity, discount, ...props }: Props) => {
  const [, { open }] = useDialog('quantityDiscountEdit');
  const remove = useDeleteQuantityDiscount(activity.id, discount.id);

  const handleDelete = useCallback(() => {
    remove.mutate();
  }, [remove]);

  const handleEditClick = useCallback(() => {
    open({ activity, discount });
  }, [activity, discount, open]);

  return (
    <TableRow {...props}>
      <TableCell>
        <Link onClick={handleEditClick}>{discount.quantity}</Link>
      </TableCell>

      <TableCell align="center">
        <Link onClick={handleEditClick}>{discount.amount}%</Link>
      </TableCell>

      <TableCell align="right">
        <ConfirmableIconButton
          onConfirm={handleDelete}
          size="small"
          text="Ar tikrai norite ištrinti šią nuolaidą">
          <DeleteIcon fontSize="small" color="error" />
        </ConfirmableIconButton>
      </TableCell>
    </TableRow>
  );
};

export default QuantityDiscountTableRow;
