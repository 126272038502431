import { useParams } from 'react-router-dom';

import Page from 'components/Page';
import PageSkeleton from 'components/PageSkeleton';

import { useDiscount } from 'features/Discount';
import { useClub } from 'components/ClubProvider';
import Tabber from 'components/Tabber';
import DiscountDetailsEdit from 'components/DiscountDetailsEdit';
import Tabs from 'components/Tabs';
import DiscountHistory from 'components/DiscountHistory';

const DiscountEdit = () => {
  const { discount } = useParams();
  const club = useClub();
  const discountQuery = useDiscount(club.id, Number(discount));

  if (!discountQuery.data) return <PageSkeleton>Kraunasi...</PageSkeleton>;

  return (
    <Tabs
      tabs={[
        {
          title: 'Nuolaida',
          component: <DiscountDetailsEdit discount={discountQuery.data} />,
          paper: false,
          hash: 'discount',
        },
        {
          title: 'Panaudojimų istorija',
          component: <DiscountHistory discount={discountQuery.data} />,
          paper: false,
          hash: 'history',
        },
      ]}>
      <Page title={`Nuolaidos kodas: ${discountQuery.data.code}`}>
        <Tabber />
      </Page>
    </Tabs>
  );
};

export default DiscountEdit;
