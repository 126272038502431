import { useCallback } from 'react';
import { Form, Formik } from 'formik';

import AddIcon from '@mui/icons-material/Add';
import DialogTitle from 'components/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from 'components/Dialog';
import CategoryFormFields, {
  FormFields,
  schema,
} from 'components/CategoryFormFields';

import { useCreateCategory } from 'features/Category';
import { useDialog } from 'components/Dialogs';
import DialogContent from 'components/DialogContent';

const initial: FormFields = {
  name: '',
  photo: {
    currentUrl: null,
    uploaded: null,
  },
};

export type AddCategoryDialogProps = {};

const AddCategoryDialog = () => {
  const [, { close }] = useDialog('categoryAdd');
  const create = useCreateCategory();

  const handleSubmit = useCallback(
    ({ photo, ...form }: FormFields) => {
      const vars = {
        form,
        files: photo?.uploaded ? { photo: photo.uploaded } : null,
      };

      create.mutate(vars, {
        onSuccess: () => {
          close();
        },
      });
    },
    [close, create]
  );

  return (
    <Dialog dialog="categoryAdd">
      <Formik<FormFields>
        initialValues={initial}
        onSubmit={handleSubmit}
        validationSchema={schema}>
        <Form>
          <DialogTitle onClose={close}>Nauja kategorija</DialogTitle>

          <DialogContent>
            <CategoryFormFields />
          </DialogContent>

          <DialogActions>
            <Button onClick={close} variant="text">
              Atšaukti
            </Button>

            <LoadingButton
              loading={create.isPending}
              variant="contained"
              type="submit"
              startIcon={<AddIcon />}>
              Pridėti
            </LoadingButton>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

export default AddCategoryDialog;
