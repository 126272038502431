import ShiftSelect, { ShiftSelectProps } from 'components/ShiftSelect';
import { useField } from 'formik';
import { SyntheticEvent, useCallback } from 'react';
import { ShiftView } from 'schema';

type Props = Omit<ShiftSelectProps, 'value' | 'onChange'> & {
  name: string;
};

const FormShiftSelect = ({ name, ...props }: Props) => {
  const [{ value }, { error, touched }, { setValue }] =
    useField<ShiftView | null>(name);

  const hasError = Boolean(error && touched);

  const handleChange = useCallback(
    (_: SyntheticEvent, value: ShiftView | null) => {
      setValue(value);
    },
    [setValue]
  );

  return (
    <ShiftSelect
      value={value}
      onChange={handleChange}
      error={hasError}
      helperText={hasError && error}
      {...props}
    />
  );
};

export default FormShiftSelect;
