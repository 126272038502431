import TableCell from '@mui/material/TableCell';
import AttendantRegistrationsTableRow from 'components/AttendantRegistrationsTableRow';
import Table, { TableProps } from 'components/Table';
import TableRow from 'components/TableRow';
import { ClubAttendantRegistrationListView } from 'schema';

type Props = TableProps & {
  registrations: ClubAttendantRegistrationListView[];
};

const AttendantRegistrationsTable = ({ registrations, ...props }: Props) => {
  return (
    <Table
      head={
        <TableRow>
          <TableCell component="th">Pavadinimas</TableCell>

          <TableCell component="th" align="right">
            Lankymo pradžia
          </TableCell>
        </TableRow>
      }
      {...props}>
      {registrations.map(registration => (
        <AttendantRegistrationsTableRow
          key={registration.id}
          registration={registration}
        />
      ))}
    </Table>
  );
};

export default AttendantRegistrationsTable;
