import { useParams } from 'react-router-dom';

import Stack from '@mui/material/Stack';
import ActivityEditAddButton from 'components/ActivityEditAddButton';
import Tabber from 'components/Tabber';
import ActivitySessions from 'components/ActivitySessions';
import ActivityShifts from 'components/ActivityShifts';
import ActivityDetails from 'components/ActivityDetails';
import ActivityAgreement from 'components/ActivityAgreement';
import ActivityPersonalDataAgreement from 'components/ActivityPersonalDataAgreement';
import ActivityConfirmationEmail from 'components/ActivityConfirmationEmail';
import AttendantsExportButton from 'components/AttendantsExportButton';
import PageSkeleton from 'components/PageSkeleton';
import Page from 'components/Page';
import TabberSkeleton from 'components/TabberSkeleton';
import ActivityDiscounts from 'components/ActivityDiscounts';
import Tabs from 'components/Tabs';

import { useActivity } from 'features/Activity';

const ActivityEdit = () => {
  const { activity } = useParams();
  const activityId = Number(activity);
  const query = useActivity(activityId);

  if (!query.data)
    return (
      <PageSkeleton>
        <TabberSkeleton tabs={5} />
      </PageSkeleton>
    );

  return (
    <Tabs
      tabs={[
        {
          title: 'Grupės',
          component: <ActivitySessions activity={query.data} />,
          paper: false,
          hash: 'groups',
        },
        {
          title: 'Pamainos',
          component: <ActivityShifts activity={query.data} />,
          paper: false,
          hash: 'shifts',
        },
        {
          title: 'Veikla',
          component: <ActivityDetails activity={query.data} />,
          hash: 'activity',
          paper: false,
        },
        {
          title: 'Sutartis',
          component: <ActivityAgreement activity={query.data} />,
          hash: 'agreement',
          paper: false,
        },
        {
          title: 'Registracijos laiškas',
          component: <ActivityConfirmationEmail activity={query.data} />,
          hash: 'confirmation-email',
          paper: false,
        },
        {
          title: 'Sutikimas dėl duomenų saugojimo',
          component: <ActivityPersonalDataAgreement activity={query.data} />,
          hash: 'personal-data-agreement',
          paper: false,
        },
        {
          title: 'Nuolaidos',
          component: <ActivityDiscounts activity={query.data} />,
          hash: 'discounts',
          paper: false,
        },
      ]}>
      <Page
        title={query.data.name}
        actions={
          <Stack direction="row" spacing={1}>
            <AttendantsExportButton activity={query.data} />
            <ActivityEditAddButton activity={query.data} />
          </Stack>
        }>
        <Tabber />
      </Page>
    </Tabs>
  );
};

export default ActivityEdit;
