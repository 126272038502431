import {
  QueryFunctionContext,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { useNotification } from 'components/Notifications';
import { useErrorHandler } from 'lib';

import {
  ActivitySessionInvitationIndexInput,
  CoachSessionInvitationListView,
  SendInvitationForm,
  activitySessionInvitationDelete,
  activitySessionInvitationIndex,
  activitySessionInvitationResend,
  activitySessionInvitationSend,
} from 'schema';

export const attendantInvitationsKeys = {
  all: [{ scope: 'attendant-invitations' }] as const,

  lists: () =>
    [{ ...attendantInvitationsKeys.all[0], entity: 'list' }] as const,
  list: (params: ActivitySessionInvitationIndexInput) =>
    [{ ...attendantInvitationsKeys.lists()[0], params }] as const,
};

type SessionInvitationsContext = QueryFunctionContext<
  ReturnType<(typeof attendantInvitationsKeys)['list']>
>;

export const useInvitations = (activity: number, session: number) =>
  useQuery({
    queryKey: attendantInvitationsKeys.list({ activity, session }),

    queryFn: async ({ queryKey: [{ params }] }: SessionInvitationsContext) =>
      await activitySessionInvitationIndex(params),
  });

export const useSendSessionInvitations = (activity: number, session: number) =>
  useMutation({
    mutationFn: (form: SendInvitationForm) =>
      activitySessionInvitationSend({ activity, session, form }),

    onError: useErrorHandler(),
  });

export const useResendInvitation = (
  activity: number,
  session: number,
  invitation: number
) => {
  const { pop } = useNotification();

  return useMutation({
    mutationFn: () =>
      activitySessionInvitationResend({ activity, session, invitation }),

    onSuccess: () => {
      pop('Pakvietimas išsiųstas pakartotinai');
    },
    onError: useErrorHandler(),
  });
};

export const useDeleteInvitation = (
  activity: number,
  session: number,
  invitation: number
) => {
  const queryClient = useQueryClient();
  const queryKey = attendantInvitationsKeys.list({ activity, session });
  const handleError = useErrorHandler();

  return useMutation({
    mutationFn: () =>
      activitySessionInvitationDelete({ activity, session, invitation }),

    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey });

      const snapshot =
        queryClient.getQueryData<CoachSessionInvitationListView[]>(queryKey);

      queryClient.setQueryData<CoachSessionInvitationListView[]>(
        queryKey,
        items => items?.filter(item => item.id !== session) ?? []
      );

      return { snapshot };
    },

    onError: (error: any, __, context) => {
      handleError(error);
      queryClient.setQueryData(queryKey, context?.snapshot);
    },

    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });
};
