import { useCallback } from 'react';
import { Form, Formik } from 'formik';
import { bool, object, string } from 'yup';

import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Unstable_Grid2';
import Section from 'components/Section';
import FormCheckbox from 'components/FormCheckbox';
import FormTextField from 'components/FormTextField';
import FormImageUpload from 'components/FormImageUpload';
import FormActions from 'components/FormActions';
import FormButton from 'components/FormButton';

import { useUpdateClub } from 'features/Club';
import { ClubDetailsView } from 'schema';
import { UploadField } from 'lib';

type FormFields = {
  name: string;
  webUrl: string;
  description: string;
  companyName: string;
  address: string;
  companyCode: string;
  vatPayer: boolean;
  vatCode: string;
  invoiceIssuer: string;
  invoiceSeries: string;
  lastInvoiceNumber: string;
  accountNr: string;
  bank: string;
  logo: UploadField;
};

type Props = {
  club: ClubDetailsView;
};

const schema = object({
  vatPayer: bool(),
  vatCode: string().when('vatPayer', {
    is: true,
    then: schema => schema.required('Prašome įvesti įmonės PVM kodą'),
  }),
});

const ClubDetails = ({ club }: Props) => {
  const update = useUpdateClub(club.id);

  const initial: FormFields = {
    name: club.name,
    webUrl: club.webUrl ?? '',
    description: club.description ?? '',
    companyName: club.companyName ?? '',
    address: club.address ?? '',
    companyCode: club.companyCode?.toString() ?? '',
    vatPayer: club.vatPayer,
    vatCode: club.vatCode ?? '',
    invoiceIssuer: club.invoiceIssuer ?? '',
    invoiceSeries: club.invoiceSeries ?? '',
    lastInvoiceNumber: club.lastInvoiceNumber?.toString() ?? '',
    accountNr: club.accountNr ?? '',
    bank: club.bank ?? '',
    logo: {
      currentUrl: club.logo?.publicUrl ?? null,
      uploaded: null,
    },
  };

  const handleSubmit = useCallback(
    ({ companyCode, lastInvoiceNumber, logo, ...form }: FormFields) => {
      update.mutate({
        form: {
          ...form,
          companyCode: companyCode ? Number(companyCode) : null,
          lastInvoiceNumber: lastInvoiceNumber
            ? Number(lastInvoiceNumber)
            : null,
        },
        files: logo.uploaded && { logo: logo.uploaded },
      });
    },
    [update]
  );

  return (
    <Formik<FormFields>
      initialValues={initial}
      onSubmit={handleSubmit}
      validationSchema={schema}>
      <Form>
        <Section sx={{ mb: 2 }} title="Klubo informacija">
          <Stack spacing={4}>
            <FormTextField name="name" label="Pavadinimas" />
            <FormTextField name="webUrl" label="Svetainės adresas" />
            <FormTextField multiline name="description" label="Aprašymas" />
          </Stack>
        </Section>

        <Section sx={{ mb: 2 }} title="Rekvizitai sąskaitoms">
          <Stack spacing={4} sx={{ mb: 2 }}>
            <FormTextField name="companyName" label="Įmonės pavadinimas" />
            <FormTextField name="address" label="Adresas" />
            <FormTextField name="companyCode" label="Įmonės kodas" />

            <FormControlLabel
              label="PVM Mokėtojas"
              control={<FormCheckbox name="vatPayer" />}
            />

            <FormTextField name="vatCode" label="PVM kodas" />
            <FormTextField name="accountNr" label="Sąskaitos numeris" />
            <FormTextField name="bank" label="Bankas" />

            <FormTextField
              name="invoiceIssuer"
              label="Sąskaitą išrašančio asmens vardas, pavardė"
            />

            <Grid container>
              <Grid xs={6} sx={{ pr: 1 }}>
                <FormTextField
                  fullWidth
                  name="invoiceSeries"
                  label="Sąskaitų serijos pavadinimas"
                />
              </Grid>

              <Grid xs={6} sx={{ pl: 1 }}>
                <FormTextField
                  fullWidth
                  name="lastInvoiceNumber"
                  label="Paskutinis sąskaitos numeris"
                />
              </Grid>
            </Grid>

            <FormImageUpload cover={false} name="logo" label="Logotipas" />
          </Stack>
        </Section>

        <Section>
          <FormActions>
            <FormButton loading={update.isPending} />
          </FormActions>
        </Section>
      </Form>
    </Formik>
  );
};

export default ClubDetails;
