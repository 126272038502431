import TableCell from '@mui/material/TableCell';
import DiscountTableRow from 'components/DiscountTableRow';
import Table, { TableProps } from 'components/Table';
import TableRow from 'components/TableRow';

import { ClubDiscountIndexParams, DiscountListView } from 'schema';

type Props = TableProps & {
  discounts: DiscountListView[];
  searchParams: ClubDiscountIndexParams;
};

const DiscountTable = ({ discounts, searchParams, ...props }: Props) => (
  <Table
    head={
      <TableRow>
        <TableCell component="th" />
        <TableCell component="th">Kodas</TableCell>

        <TableCell component="th" align="center">
          Nuolaida
        </TableCell>

        <TableCell component="th" align="center">
          Sukurta
        </TableCell>

        <TableCell component="th" align="center">
          Galioja iki
        </TableCell>

        <TableCell component="th" align="center">
          Kiekis
        </TableCell>

        <TableCell component="th" align="center">
          Panaudota
        </TableCell>

        <TableCell component="th" align="right" />
      </TableRow>
    }
    {...props}>
    {discounts.map(discount => (
      <DiscountTableRow
        key={discount.id}
        discount={discount}
        searchParams={searchParams}
      />
    ))}
  </Table>
);

export default DiscountTable;
