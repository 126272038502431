import { useCallback } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Link from '@mui/material/Link';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import TableRow from 'components/TableRow';
import SessionCapacityIndicator from 'components/SessionCapacityIndicator';
import SessionStatusIcon from 'components/SessionStatusIcon';
import TableRowActions from 'components/TableRowActions';
import ConfirmableIconButton from 'components/ConfirmableIconButton';

import { useDeleteActivitySession } from 'features/Activity';
import { useDialog } from 'components/Dialogs';
import { ActivityDetailsView, CoachSessionListView } from 'schema';

type Props = {
  activity: ActivityDetailsView;
  session: CoachSessionListView;
};

const SessionTableRow = ({ activity, session }: Props) => {
  const { id, name, price, coach, city } = session;
  const deleteSession = useDeleteActivitySession(activity.id, id);
  const [, { open }] = useDialog('sessionEdit');

  const handleDelete = useCallback(() => {
    deleteSession.mutate();
  }, [deleteSession]);

  const handleEditClick = useCallback(() => {
    open({ activity, session });
  }, [activity, open, session]);

  return (
    <TableRow>
      <TableCell>
        <Link href={`sessions/${id.toString()}`}>{name}</Link>
      </TableCell>

      <TableCell align="center">
        <SessionCapacityIndicator session={session} />
      </TableCell>

      <TableCell align="center">{city.name}</TableCell>

      <TableCell align="center">
        {coach.user.firstName} {coach.user.lastName}
      </TableCell>

      <TableCell align="center">
        {price} €{activity.type === 'regular' && '/mėn'}
      </TableCell>

      <TableCell align="center">
        <SessionStatusIcon session={session} />
      </TableCell>

      <TableCell align="right">
        <TableRowActions>
          <IconButton size="small" onClick={handleEditClick} sx={{ mr: 1 }}>
            <EditIcon fontSize="small" />
          </IconButton>

          <ConfirmableIconButton
            onConfirm={handleDelete}
            size="small"
            text={`Ar tikrai norite ištrinti "${name}"`}>
            <DeleteIcon fontSize="small" color="error" />
          </ConfirmableIconButton>
        </TableRowActions>
      </TableCell>
    </TableRow>
  );
};

export default SessionTableRow;
