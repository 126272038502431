import {
  QueryFunctionContext,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useNotification } from 'components/Notifications';
import { useErrorHandler } from 'lib';
import {
  ActivitySessionRegistrationIndexInput,
  ActivitySessionRegistrationShowInput,
  AddSessionResultForm,
  AttendantRegistrationUpdate,
  CoachAttendantRegistrationDetails,
  CoachAttendantRegistrationListView,
  LinkEntity,
  activitySessionRegistrationAddResult,
  activitySessionRegistrationChangeSession,
  activitySessionRegistrationDelete,
  activitySessionRegistrationIndex,
  activitySessionRegistrationShow,
  activitySessionRegistrationUpdate,
} from 'schema';

export const attendantRegistrationsKeys = {
  all: [{ scope: 'attendant-registrations' }] as const,

  lists: () =>
    [{ ...attendantRegistrationsKeys.all[0], entity: 'list' }] as const,
  list: (params: ActivitySessionRegistrationIndexInput) =>
    [{ ...attendantRegistrationsKeys.lists()[0], params }] as const,

  details: () =>
    [{ ...attendantRegistrationsKeys.all[0], entity: 'details' }] as const,
  detail: (params: ActivitySessionRegistrationShowInput) =>
    [{ ...attendantRegistrationsKeys.details()[0], params }] as const,
};

type SessionRegistrationsContext = QueryFunctionContext<
  ReturnType<(typeof attendantRegistrationsKeys)['list']>
>;

type SessionRegistrationDetailsContext = QueryFunctionContext<
  ReturnType<(typeof attendantRegistrationsKeys)['detail']>
>;

export const useActivitySessionRegistrations = (
  activity: number,
  session: number
) =>
  useQuery({
    queryKey: attendantRegistrationsKeys.list({ activity, session }),

    queryFn: async ({ queryKey: [{ params }] }: SessionRegistrationsContext) =>
      await activitySessionRegistrationIndex(params),
  });

export const useActivitySessionRegistration = (
  activity: number,
  session: number,
  registration: number
) =>
  useQuery({
    queryKey: attendantRegistrationsKeys.detail({
      activity,
      session,
      registration,
    }),

    queryFn: async ({
      queryKey: [{ params }],
    }: SessionRegistrationDetailsContext) =>
      await activitySessionRegistrationShow(params),
  });

export const useAddSessionResult = (
  activity: number,
  session: number,
  registration: number
) => {
  const queryClient = useQueryClient();

  const queryKey = attendantRegistrationsKeys.detail({
    activity,
    session,
    registration,
  });

  return useMutation({
    mutationFn: (form: AddSessionResultForm) =>
      activitySessionRegistrationAddResult({
        activity,
        session,
        registration,
        form,
      }),

    onSuccess: data => {
      queryClient.setQueryData<CoachAttendantRegistrationDetails>(
        queryKey,
        old => {
          if (!old) return;

          return {
            ...old,
            results: [data, ...old.results],
          };
        }
      );
    },
    onError: useErrorHandler(),
  });
};

export const useChangeSession = (
  activity: number,
  session: number,
  registration: number
) => {
  const client = useQueryClient();
  const { pop } = useNotification();

  return useMutation({
    mutationFn: (form: LinkEntity) =>
      activitySessionRegistrationChangeSession({
        activity,
        session,
        registration,
        form,
      }),

    onSuccess: () => {
      client.invalidateQueries({
        queryKey: attendantRegistrationsKeys.lists(),
      });

      pop('Grupė pakeista');
    },

    onError: useErrorHandler(),
  });
};

export const useUpdateRegistration = (
  activity: number,
  session: number,
  registration: number
) => {
  const { pop } = useNotification();
  const client = useQueryClient();

  const queryKey = attendantRegistrationsKeys.detail({
    activity,
    session,
    registration,
  });

  return useMutation({
    mutationFn: (form: AttendantRegistrationUpdate) =>
      activitySessionRegistrationUpdate({
        activity,
        session,
        registration,
        form,
      }),

    onSuccess: () => {
      client.invalidateQueries({ queryKey });

      client.invalidateQueries({
        queryKey: attendantRegistrationsKeys.list({ activity, session }),
      });

      pop('Išsaugota');
    },

    onError: useErrorHandler(),
  });
};

export const useRemoveRegistration = (
  activity: number,
  session: number,
  registration: number
) => {
  const client = useQueryClient();
  const queryKey = attendantRegistrationsKeys.list({ activity, session });
  const handleError = useErrorHandler();

  return useMutation({
    mutationFn: () =>
      activitySessionRegistrationDelete({ activity, session, registration }),

    onMutate: async () => {
      await client.cancelQueries({ queryKey });

      const snapshot =
        client.getQueryData<CoachAttendantRegistrationListView[]>(queryKey);

      client.setQueryData<CoachAttendantRegistrationListView[]>(
        queryKey,
        items => items?.filter(item => item.id !== session) ?? []
      );

      return { snapshot };
    },

    onError: (error: any, __, context) => {
      handleError(error);
      client.setQueryData(queryKey, context?.snapshot);
    },

    onSettled: () => {
      client.invalidateQueries({ queryKey });
    },
  });
};
