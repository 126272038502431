import {
  QueryFunctionContext,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useClub } from 'components/ClubProvider';
import { useNotification } from 'components/Notifications';
import { useErrorHandler } from 'lib';
import {
  AdminAttendantForm,
  ClubAttendantDetails,
  ClubAttendantDropdownInput,
  ClubAttendantIndexInput,
  ClubAttendantIndexParams,
  ClubAttendantSessionsInput,
  ClubAttendantShowInput,
  clubAttendantDropdown,
  clubAttendantIndex,
  clubAttendantSessions,
  clubAttendantShow,
  clubAttendantUpdate,
} from 'schema';

export const attendantKeys = {
  all: [{ scope: 'attendants' }] as const,

  lists: () => [{ ...attendantKeys.all[0], entity: 'list' }] as const,
  list: (params: ClubAttendantIndexInput) =>
    [{ ...attendantKeys.lists()[0], params }] as const,

  dropdowns: () => [{ ...attendantKeys.all[0], entity: 'dropdown' }] as const,
  dropdown: (params: ClubAttendantDropdownInput) =>
    [{ ...attendantKeys.dropdowns()[0], params }] as const,

  details: () => [{ ...attendantKeys.all[0], entity: 'details' }] as const,
  detail: (params: ClubAttendantShowInput) =>
    [{ ...attendantKeys.details()[0], params }] as const,

  registrationsLists: () =>
    [{ ...attendantKeys.all[0], entity: 'registrations' }] as const,
  registrations: (params: ClubAttendantSessionsInput) =>
    [{ ...attendantKeys.registrationsLists()[0], params }] as const,
};

type AttendantListContext = QueryFunctionContext<
  ReturnType<(typeof attendantKeys)['list']>
>;

type AttendantDropdownContenxt = QueryFunctionContext<
  ReturnType<(typeof attendantKeys)['dropdown']>
>;

type AttendantDetailsContext = QueryFunctionContext<
  ReturnType<(typeof attendantKeys)['detail']>
>;

type AttendantRegistrationsContext = QueryFunctionContext<
  ReturnType<(typeof attendantKeys)['registrations']>
>;

export const useAttendants = (params: ClubAttendantIndexParams = {}) =>
  useQuery({
    queryKey: attendantKeys.list({ club: useClub().id, params }),
    queryFn: async ({ queryKey: [{ params }] }: AttendantListContext) =>
      clubAttendantIndex(params),
  });

export const useAttendantsDropdownOptions = () =>
  useQuery({
    queryKey: attendantKeys.dropdown({ club: useClub().id }),
    queryFn: async ({ queryKey: [{ params }] }: AttendantDropdownContenxt) =>
      await clubAttendantDropdown(params),
  });

export const useAttendant = (attendant: number) =>
  useQuery({
    queryKey: attendantKeys.detail({ club: useClub().id, attendant }),
    queryFn: async ({ queryKey: [{ params }] }: AttendantDetailsContext) =>
      await clubAttendantShow(params),
  });

export const useAttendantRegistrations = (attendant: number) =>
  useQuery({
    queryKey: attendantKeys.registrations({ club: useClub().id, attendant }),
    queryFn: async ({
      queryKey: [{ params }],
    }: AttendantRegistrationsContext) => await clubAttendantSessions(params),
  });

export const useUpdateAttendant = (attendant: number) => {
  const client = useQueryClient();
  const { pop } = useNotification();
  const club = useClub().id;

  return useMutation({
    mutationFn: (form: AdminAttendantForm) =>
      clubAttendantUpdate({ club, attendant, form }),

    onSuccess: data => {
      client.invalidateQueries({
        queryKey: attendantKeys.lists(),
      });

      client.setQueryData<ClubAttendantDetails>(
        attendantKeys.detail({ club, attendant }),
        data
      );

      pop('Išsaugota');
    },

    onError: useErrorHandler(),
  });
};
