import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UnpublishedIcon from '@mui/icons-material/Unpublished';

type Props = {
  discount: {
    isValid: boolean;
  };
};

const DiscountStatus = ({ discount }: Props) => {
  if (discount.isValid) {
    return <CheckCircleIcon color="success" fontSize="small" />;
  }

  return <UnpublishedIcon color="disabled" fontSize="small" />;
};

export default DiscountStatus;
