import Grid from '@mui/material/Grid';

import FilePreviewCurrent, {
  FilePreviewCurrentProps,
} from 'components/FilePreviewCurrent';

type Props = FilePreviewCurrentProps;

const PreviewThumb = (props: Props) => (
  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
    <FilePreviewCurrent {...props} />
  </Grid>
);

export default PreviewThumb;
