import TableCell from '@mui/material/TableCell';
import Table from 'components/Table';
import TableRow from 'components/TableRow';
import { formatDateTime } from 'lib';

import { DiscountDetails } from 'schema';

type Props = {
  discount: DiscountDetails;
};

const DiscountHistory = ({ discount }: Props) => (
  <Table
    head={
      <TableRow>
        <TableCell component="th">Data</TableCell>
        <TableCell component="th" align="center">
          Klientas
        </TableCell>
      </TableRow>
    }>
    {discount.history.map(({ usedAt, user }, i) => (
      <TableRow key={i}>
        <TableCell>{formatDateTime(usedAt)}</TableCell>
        <TableCell align="center">{user.fullName}</TableCell>
      </TableRow>
    ))}
  </Table>
);

export default DiscountHistory;
