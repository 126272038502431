import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import MuiDialogTitle, {
  DialogTitleProps as MuiDialogTitleProps,
} from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';

type Props = MuiDialogTitleProps & {
  onClose?: () => void;
};

export type DialogTitleProps = Props;

const DialogTitle = ({ children, sx = [], onClose }: Props) => {
  return (
    <MuiDialogTitle
      sx={[
        { display: 'flex', alignItems: 'center' },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}>
      <Box sx={{ flexGrow: 1 }}>{children}</Box>

      {onClose && (
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      )}
    </MuiDialogTitle>
  );
};

export default DialogTitle;
