import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { object, ref, string } from 'yup';

import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import FullscreenAppLogo from 'components/FullscreenAppLogo';
import FormTextField from 'components/FormTextField';
import FullScreen from 'components/FullScreen';

import { useAcceptMemberInvitation } from 'features/MemberInvitation';
import { useClearAuth } from 'features/Auth';
import { MemberInvitationDetails } from 'schema';

type Fields = {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  passwordRepeat: string;
};

const schema = object({
  email: string().required('Prašome įvesti el. pašto adresą'),
  firstName: string().required('Prašome įvesti vardą'),
  lastName: string().required('Prašome įvesti pavardę'),

  password: string()
    .required('Prašome įvesti slaptažodį')
    .min(8, 'Prašome įvesti bent 8 simbolius'),

  passwordRepeat: string()
    .required('Pakartokite slaptažodį')
    .oneOf([ref('password')], 'Nesutampa slaptažodžiai'),
});

type Props = {
  invitation: MemberInvitationDetails;
};

const MemberInvitationSignup = ({ invitation }: Props) => {
  const { email, user, token } = invitation;
  const accept = useAcceptMemberInvitation(token);
  const clear = useClearAuth();
  const navigate = useNavigate();

  const handleSubmit = useCallback(
    (user: Fields) => {
      const form = { user };

      accept.mutate(form, {
        onSuccess: () => {
          clear();
          navigate('/');
        },
      });
    },
    [accept, clear, navigate]
  );

  const initial = {
    email,
    firstName: user?.firstName ?? '',
    lastName: user?.lastName ?? '',
    password: '',
    passwordRepeat: '',
  };

  return (
    <FullScreen>
      <Box sx={{ p: 2, width: '500px' }}>
        <FullscreenAppLogo />

        <Paper sx={{ p: 2 }}>
          <Formik<Fields>
            onSubmit={handleSubmit}
            initialValues={initial}
            validationSchema={schema}>
            <Form>
              <Typography variant="h6" color="text.secondary">
                Registracija
              </Typography>

              <Stack spacing={4}>
                <Grid container spacing={2}>
                  <Grid xs={6} sx={{ pl: 0 }}>
                    <FormTextField fullWidth name="firstName" label="Vardas" />
                  </Grid>

                  <Grid xs={6} sx={{ pr: 0 }}>
                    <FormTextField fullWidth name="lastName" label="Pavardė" />
                  </Grid>
                </Grid>

                <FormTextField name="email" label="El. paštas" disabled />

                <FormTextField
                  name="password"
                  type="password"
                  label="Slaptažodis"
                />

                <FormTextField
                  name="passwordRepeat"
                  type="password"
                  label="Pakartokite slaptažodį"
                />

                <LoadingButton
                  loading={accept.isPending}
                  variant="contained"
                  type="submit">
                  Registruotis
                </LoadingButton>
              </Stack>
            </Form>
          </Formik>
        </Paper>
      </Box>
    </FullScreen>
  );
};

export default MemberInvitationSignup;
