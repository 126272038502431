import SessionsTable from 'components/SessionsTable';

import { useActivitySessions } from 'features/Activity';

import { ActivityDetailsView } from 'schema';

type Props = {
  activity: ActivityDetailsView;
};

const ActivitySessions = ({ activity }: Props) => {
  const sessions = useActivitySessions(activity.id);

  return (
    <SessionsTable
      loading={sessions.isPending}
      shifts={sessions.data ?? []}
      activity={activity}
    />
  );
};

export default ActivitySessions;
