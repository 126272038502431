import Chip from '@mui/material/Chip';
import MultiChipInput, { MultiChipInputProps } from 'components/MultiChipInput';
import { useField } from 'formik';
import { uniq } from 'lib';
import { SyntheticEvent, useCallback } from 'react';

type Props = MultiChipInputProps & {
  name: string;
};

const FormMultiChipInput = ({ name, ...props }: Props) => {
  const [{ value }, { error, touched }, { setValue }] =
    useField<string[]>(name);

  const hasError = Boolean(error && touched);

  const handleChange = useCallback(
    (_: SyntheticEvent, newValue: string[]) => {
      setValue(newValue);
    },
    [setValue]
  );

  const message = Array.isArray(error) ? uniq(error.filter(e => e)) : error;

  return (
    <MultiChipInput
      value={value}
      error={hasError}
      helperText={hasError && message}
      onChange={handleChange}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            label={option}
            {...getTagProps({ index })}
            color={hasError && (error as any)[index] ? 'error' : undefined}
          />
        ))
      }
      {...props}
    />
  );
};

export default FormMultiChipInput;
