import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import MainToolbar from 'components/MainToolbar';
import { ReactNode } from 'react';
import { useLayout } from 'routes/Root';

type Props = {
  children: ReactNode;
};

const PageSkeleton = ({ children }: Props) => {
  const { mainMenuOpen, toggleDrawer } = useLayout();

  return (
    <Box>
      <MainToolbar open={mainMenuOpen} onToggle={toggleDrawer}>
        <Skeleton width={200} />
      </MainToolbar>

      <Box sx={{ pt: 8 }}>{children}</Box>
    </Box>
  );
};

export default PageSkeleton;
