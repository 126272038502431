import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent, SelectProps } from '@mui/material/Select';

type Value = boolean | null;
type SelectValue = 1 | 0 | '';

type Props = Omit<SelectProps<SelectValue>, 'onChange' | 'value'> & {
  value: Value;
  onChange: (value: Value) => void;
  yesText?: string;
  noText?: string;
};

const YesNoSelect = (props: Props) => {
  const {
    value,
    onChange,
    label,
    fullWidth,
    yesText = 'Taip',
    noText = 'Ne',
    ...rest
  } = props;

  const handleChange = (event: SelectChangeEvent<SelectValue>) => {
    const newValue = event.target.value;

    if (newValue === '') {
      onChange(null);
    } else {
      onChange(Boolean(newValue));
    }
  };

  const selectValue = value === null ? '' : value ? 1 : 0;

  return (
    <FormControl
      sx={[
        (fullWidth ?? false) && {
          width: '100%',
        },
      ]}>
      <InputLabel id="demo-simple-select-helper-label">{label}</InputLabel>

      <Select<SelectValue>
        labelId="demo-simple-select-helper-label"
        value={selectValue}
        onChange={handleChange}
        variant="standard"
        fullWidth={fullWidth}
        {...rest}>
        <MenuItem value="">
          <em>Visi</em>
        </MenuItem>

        <MenuItem value={1}>{yesText}</MenuItem>
        <MenuItem value={0}>{noText}</MenuItem>
      </Select>
    </FormControl>
  );
};

export default YesNoSelect;
