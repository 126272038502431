import Box, { BoxProps } from '@mui/material/Box';

import logo from 'assets/img/logo.png';

const FullscreenAppLogo = ({ sx = [], ...props }: BoxProps) => (
  <Box
    sx={[{ '& img': { width: '50%' } }, ...(Array.isArray(sx) ? sx : [sx])]}
    {...props}>
    <img src={logo} alt="iGudo" />
  </Box>
);

export default FullscreenAppLogo;
