import { SyntheticEvent, useCallback } from 'react';

import TableCell from '@mui/material/TableCell';
import OrdersTableRow from 'components/OrdersTableRow';
import OrdersTableStatusTabs from 'components/OrdersTableStatusTabs';
import Table, { TableProps } from 'components/Table';
import TableRow from 'components/TableRow';

import {
  ClubOrderCountsByStatusParams,
  ClubOrderListItem,
  OrderStatus,
} from 'schema';

type Props = TableProps & {
  orders: ClubOrderListItem[];
  status: OrderStatus;
  filter: ClubOrderCountsByStatusParams;
  onStatusChange: (status: OrderStatus) => void;
};

const OrdersTable = (props: Props) => {
  const { orders, status, filter, onStatusChange, ...rest } = props;

  const handleTabChange = useCallback(
    (_: SyntheticEvent, value: OrderStatus) => {
      onStatusChange(value);
    },
    [onStatusChange]
  );

  return (
    <Table
      {...rest}
      tabs={
        <OrdersTableStatusTabs
          filter={filter}
          value={status}
          onChange={handleTabChange}
        />
      }
      head={
        <TableRow>
          <TableCell component="th">Nr</TableCell>

          <TableCell component="th" align="center">
            Data
          </TableCell>

          <TableCell component="th" align="left">
            Paslaugos
          </TableCell>

          <TableCell component="th" align="center">
            Užsakovas
          </TableCell>

          <TableCell component="th" align="center">
            Suma
          </TableCell>

          <TableCell component="th" align="center">
            Apmokėjimo data
          </TableCell>

          <TableCell component="th" align="right">
            Sąskaita
          </TableCell>
        </TableRow>
      }>
      {orders.map(order => (
        <OrdersTableRow key={order.id} order={order} />
      ))}
    </Table>
  );
};

export default OrdersTable;
