import SaveIcon from '@mui/icons-material/Save';
import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton';

type Props = LoadingButtonProps & {};

const FormButton = (props: Props) => {
  const {
    startIcon = <SaveIcon />,
    variant = 'contained',
    children = 'Saugoti',
    type = 'submit',
    ...rest
  } = props;

  return (
    <LoadingButton
      type={type}
      startIcon={startIcon}
      variant={variant}
      {...rest}>
      {children}
    </LoadingButton>
  );
};

export default FormButton;
