import { ReactNode } from 'react';

import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import AppBar from 'components/AppBar';
import AppClubSelect from 'components/AppClubSelect';

type Props = {
  open: boolean;
  onToggle: () => void;
  children?: ReactNode;
};

function MainToolbar(props: Props) {
  const { children, open, onToggle } = props;

  return (
    <AppBar position="absolute" open={open}>
      <Toolbar
        sx={{
          pr: '24px', // keep right padding when drawer closed
        }}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={onToggle}
          sx={{
            marginRight: '36px',
            ...(open && { display: 'none' }),
          }}>
          <MenuIcon />
        </IconButton>

        <Box sx={{ flexGrow: 1, display: 'flex' }}>{children}</Box>

        <AppClubSelect />
      </Toolbar>
    </AppBar>
  );
}

export default MainToolbar;
