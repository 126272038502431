import { ReactNode } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MainToolbar from 'components/MainToolbar';

import { useLayout } from 'routes/Root';

type Props = {
  title: string;
  children?: ReactNode;
  actions?: ReactNode;
};

const Page = ({ title, children, actions }: Props) => {
  const { mainMenuOpen, toggleDrawer } = useLayout();

  return (
    <Box>
      <MainToolbar open={mainMenuOpen} onToggle={toggleDrawer}>
        <Typography component="h1" variant="h6" sx={{ flexGrow: 1 }}>
          {title}
        </Typography>

        {actions}
      </MainToolbar>

      <Box sx={{ pt: 8 }}>{children}</Box>
    </Box>
  );
};

export default Page;
