import { useCallback } from 'react';
import { useField } from 'formik';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DropZone from 'components/DropZone';
import FilePreviewCurrent, {
  FilePreviewCurrentProps,
} from 'components/FilePreviewCurrent';

import { UploadField } from 'lib';

type Props = Omit<FilePreviewCurrentProps, 'onDelete' | 'file' | 'alt'> & {
  name: string;
  label?: string;
};

function FormImageUpload({ name, label, ...rest }: Props) {
  const [{ value }, , { setValue }] = useField<UploadField>(name);

  const handleDrop = useCallback(
    (files: File[]) => {
      setValue({
        ...value,
        uploaded: files[0],
      });
    },
    [setValue, value]
  );

  const handleDelete = useCallback(() => {
    setValue({
      currentUrl: null,
      uploaded: null,
    });
  }, [setValue]);

  return (
    <Box>
      {label && (
        <Typography variant="caption" color="text.secondary">
          {label}
        </Typography>
      )}

      {value.currentUrl || value.uploaded ? (
        <FilePreviewCurrent
          file={value}
          onDelete={handleDelete}
          alt={label ?? 'Nuotrauka'}
          {...rest}
        />
      ) : (
        <DropZone onDrop={handleDrop} />
      )}
    </Box>
  );
}

export default FormImageUpload;
