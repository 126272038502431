import {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useState,
} from 'react';

import AppSplash from 'components/AppSplash';
import Login from 'components/Login';

import { hasToken, useMeQuery } from 'features/Auth';
import { UserDetailsView } from 'schema';

type AuthContextType = {
  me: UserDetailsView | null;
  login: () => void;
  recover: () => void;
};

type AuthMode = 'recover' | 'login';

type Props = {
  children: ReactElement;
};

const modes = {
  login: <Login />,
  recover: <div>Recover</div>,
};

const AuthContext = createContext<AuthContextType>({
  me: null,
  login: () => {},
  recover: () => {},
});

export const useAuth = () => useContext(AuthContext);

export const useMe = () => useAuth().me!;

const AuthGuard = ({ children }: Props) => {
  const authQuery = useMeQuery();
  const [mode, setMode] = useState<AuthMode>('login');

  const login = useCallback(() => {
    setMode('login');
  }, []);

  const recover = useCallback(() => {
    setMode('recover');
  }, []);

  if (!hasToken()) return modes[mode];

  if (authQuery.isLoading && !authQuery.isFetched) return <AppSplash />;

  if (!authQuery.data) return modes[mode];

  return (
    <AuthContext.Provider value={{ me: authQuery.data, login, recover }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthGuard;
