import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { useUpdateClubMemberRoles } from 'features/Club';
import { useClub } from 'components/ClubProvider';
import { ClubMemberDetails } from 'schema';
import { ChangeEvent, useCallback } from 'react';

type Props = {
  member: ClubMemberDetails;
};

const UserDetailsRoles = ({ member }: Props) => {
  const { isCoach, isAdmin } = member;
  const update = useUpdateClubMemberRoles(useClub().id, member.id);

  const handleCoachChange = useCallback(
    (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      update.mutate({ isCoach: checked, isAdmin });
    },
    [isAdmin, update]
  );

  const handleAdminChange = useCallback(
    (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      update.mutate({ isCoach, isAdmin: checked });
    },
    [isCoach, update]
  );

  return (
    <Box>
      <Typography variant="h6">Vartotojo rolės klube</Typography>

      <Box>
        <FormControlLabel
          label="Treneris"
          control={
            <Checkbox
              checked={isCoach}
              onChange={handleCoachChange}
              disabled={update.isPending}
            />
          }
        />
      </Box>

      <Box>
        <FormControlLabel
          label="Administratorius"
          control={
            <Checkbox
              checked={isAdmin}
              onChange={handleAdminChange}
              disabled={update.isPending}
            />
          }
        />
      </Box>
    </Box>
  );
};

export default UserDetailsRoles;
