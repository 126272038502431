import { useField } from 'formik';
import { date, number, object, string } from 'yup';

import Alert from '@mui/material/Alert';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from 'components/Stack';
import FormTextField from 'components/FormTextField';
import FormDatePicker from 'components/FormDatePicker';
import TwoColumn from 'components/TwoColumn';
import DiscountTypeSelect from 'components/DiscountTypeSelect';

import { DiscountDetails, DiscountType } from 'schema';

export type Fields = {
  code: string;
  validFrom: Date | null;
  expiresAt: Date | null;
  quantity: string;
  type: DiscountType;
  amount: string;
};

export const schema = object({
  code: string().required('Prašome įvesti nuolaidos kodą'),
  validFrom: date().nullable(),
  expiresAt: date().nullable(),
  quantity: number().typeError('Paršome vesti skaičių'),
  type: string()
    .oneOf(['percent', 'absolute'])
    .required('Prašome pasirinkti nuolaidos tipą'),
  amount: number()
    .typeError('Paršome vesti skaičių')
    .required('Prašome įvesti nuolaidos dydį'),
});

type Props = {
  discount?: DiscountDetails;
};

const DiscountFormFields = ({ discount }: Props) => {
  const [type] = useField<DiscountType | ''>('type');
  const limitedEdit = Boolean(discount && discount.history.length);

  return (
    <Stack>
      {limitedEdit && (
        <Alert severity="warning">
          Šis kuponas jau panaudotas todėl jo redagavimas yra ribotas
        </Alert>
      )}

      <FormTextField name="code" label="Kodas" disabled={limitedEdit} />

      <TwoColumn
        leftSlot={<FormDatePicker name="validFrom" label="Galioja nuo" />}
        rightSlot={<FormDatePicker name="expiresAt" label="Galioja iki" />}
      />

      <FormTextField
        name="quantity"
        label="Kuponų kiekis"
        helperText="Jei tuščias kiekis neribotas"
      />

      <DiscountTypeSelect
        name="type"
        label="Nuolaidos tipas"
        disabled={limitedEdit}
      />

      <FormTextField
        disabled={limitedEdit}
        name="amount"
        label="Nuolaida"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {type.value === 'absolute' ? '€' : '%'}
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
};

export default DiscountFormFields;
