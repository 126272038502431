import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { useClub } from 'components/ClubProvider';
import {
  ClubOrderCountsByStatusInput,
  ClubOrderCountsByStatusParams,
  ClubOrderIndexInput,
  ClubOrderIndexParams,
  ClubOrderShowInput,
  clubOrderCountsByStatus,
  clubOrderIndex,
  clubOrderShow,
} from 'schema';

export const ordersKeys = {
  all: [{ scope: 'orders' }] as const,

  lists: () => [{ ...ordersKeys.all[0], entity: 'list' }] as const,
  list: (params: ClubOrderIndexInput) =>
    [{ ...ordersKeys.lists()[0], params }] as const,

  details: () => [{ ...ordersKeys.all[0], entity: 'detail' }] as const,
  detail: (params: ClubOrderShowInput) =>
    [{ ...ordersKeys.details()[0], params }] as const,

  countsByStatusLists: () =>
    [{ ...ordersKeys.all[0], entity: 'counts-by-status' }] as const,
  countsByStatus: (params: ClubOrderCountsByStatusInput) =>
    [{ ...ordersKeys.countsByStatusLists()[0], params }] as const,
};

type OrderListContext = QueryFunctionContext<
  ReturnType<(typeof ordersKeys)['list']>
>;

type OrderDetailsContext = QueryFunctionContext<
  ReturnType<(typeof ordersKeys)['detail']>
>;

type OrderCountsByStatusContext = QueryFunctionContext<
  ReturnType<(typeof ordersKeys)['countsByStatus']>
>;

export const useOrders = (params: ClubOrderIndexParams | null = null) =>
  useQuery({
    queryKey: ordersKeys.list({ club: useClub().id, params }),
    queryFn: async ({ queryKey: [{ params }] }: OrderListContext) =>
      clubOrderIndex(params),
  });

export const useOrder = (order: number) =>
  useQuery({
    queryKey: ordersKeys.detail({ club: useClub().id, order }),
    queryFn: async ({ queryKey: [{ params }] }: OrderDetailsContext) =>
      await clubOrderShow(params),
  });

export const useCountsByStatus = (
  params: ClubOrderCountsByStatusParams | null = null
) =>
  useQuery({
    queryKey: ordersKeys.countsByStatus({ club: useClub().id, params }),
    queryFn: async ({ queryKey: [{ params }] }: OrderCountsByStatusContext) =>
      clubOrderCountsByStatus(params),
  });
