import Page from 'components/Page';
import Pagination from 'components/Pagination';
import PaymentsTable from 'components/PaymentsTable';

const Payments = () => (
  <Page title="Mokėjimai">
    <Pagination>
      <PaymentsTable />
    </Pagination>
  </Page>
);

export default Payments;
