import TableCell from '@mui/material/TableCell';
import ShiftsTableRow from 'components/ShiftsTableRow';
import Table, { TableProps } from 'components/Table';
import TableRow from 'components/TableRow';

import { ActivityDetailsView, ShiftView } from 'schema';

type Props = TableProps & {
  activity: ActivityDetailsView;
  shifts: ShiftView[];
};

const ShiftsTable = ({ activity, shifts, ...props }: Props) => (
  <Table
    head={
      <TableRow>
        <TableCell component="th">Pradžia</TableCell>

        <TableCell component="th" align="center">
          Pabaiga
        </TableCell>

        <TableCell component="th" align="center">
          Grupių
        </TableCell>

        <TableCell component="th" />
      </TableRow>
    }
    {...props}>
    {shifts.map(shift => (
      <ShiftsTableRow key={shift.id} activity={activity} shift={shift} />
    ))}
  </Table>
);

export default ShiftsTable;
