import { useParams } from 'react-router-dom';

import AppSplash from 'components/AppSplash';
import MemberInvitationAccept from 'components/MemberInvitationAccept';
import MemberInvitationInvalid from 'components/MemberInvitationInvalid';
import MemberInvitationSignup from 'components/MemberInvitationSignup';

import { useMemberInvitation } from 'features/MemberInvitation';
import { useMeQuery } from 'features/Auth';

const JoinPage = () => {
  const { token } = useParams();
  const invitation = useMemberInvitation(token!);
  const me = useMeQuery();

  if (invitation.isPending || me.isPending) {
    return <AppSplash />;
  }

  if (!invitation.data) {
    return <MemberInvitationInvalid />;
  }

  if (invitation.data.user?.hasPassword) {
    return <MemberInvitationAccept invitation={invitation.data} me={me.data} />;
  }

  return <MemberInvitationSignup invitation={invitation.data} />;
};

export default JoinPage;
