import { ReactNode } from 'react';

import DialogActions from '@mui/material/DialogActions';
import DialogTitle from 'components/DialogTitle';
import Skeleton from '@mui/material/Skeleton';

import DialogContent from 'components/DialogContent';

type Props = {
  children: ReactNode;
};

const DialogSkeleton = ({ children }: Props) => (
  <>
    <DialogTitle>
      <Skeleton width={350} />
    </DialogTitle>

    <DialogContent>{children}</DialogContent>

    <DialogActions>
      <Skeleton variant="rectangular" width={100} height={36} />
      <Skeleton variant="rectangular" width={100} height={36} />
    </DialogActions>
  </>
);

export default DialogSkeleton;
