import Grid, { Grid2Props } from '@mui/material/Unstable_Grid2';
import { ReactNode } from 'react';

type Props = Grid2Props & {
  leftSlot?: ReactNode;
  rightSlot?: ReactNode;
  gutter?: boolean;
};

const TwoColumn = (props: Props) => {
  const { leftSlot, rightSlot, gutter = false, ...rest } = props;

  return (
    <Grid container spacing={2} {...rest}>
      <Grid xs={6} sx={[!gutter && { pl: 0 }]}>
        {leftSlot}
      </Grid>

      <Grid xs={6} sx={[!gutter && { pr: 0 }]}>
        {rightSlot}
      </Grid>
    </Grid>
  );
};

export default TwoColumn;
