import { useParams } from 'react-router-dom';

import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import OrderDetails from 'components/OrderDetails';
import OrderPayments from 'components/OrderPayments';
import OrderAttendantCard from 'components/OrderAttendantCard';
import Page from 'components/Page';
import PageSkeleton from 'components/PageSkeleton';

import { useOrder } from 'features/Order';

const OrderEdit = () => {
  const { order } = useParams();
  const orderQuery = useOrder(Number(order));

  if (!orderQuery.data) return <PageSkeleton>Kraunasi...</PageSkeleton>;

  return (
    <Page title={`Užsakymas: ${orderQuery.data.number}`}>
      <OrderDetails sx={{ mb: 2 }} order={orderQuery.data} />
      <OrderPayments sx={{ mb: 2 }} order={orderQuery.data} />
      <Typography variant="h6">Paslaugos</Typography>

      <Grid container spacing={2}>
        {orderQuery.data.attendants.map(attendant => (
          <OrderAttendantCard attendant={attendant} />
        ))}
      </Grid>
    </Page>
  );
};

export default OrderEdit;
