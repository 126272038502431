import {
  QueryFunctionContext,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { useNotification } from 'components/Notifications';
import { useErrorHandler } from 'lib';
import {
  AcceptMemberInvitation,
  MemberInvitationShowInput,
  memberInvitationAccept,
  memberInvitationShow,
} from 'schema';

export const memberInvitationsKeys = {
  all: [{ scope: 'member-invitations' }] as const,

  details: () =>
    [{ ...memberInvitationsKeys.all[0], entity: 'detail' }] as const,
  detail: (params: MemberInvitationShowInput) =>
    [{ ...memberInvitationsKeys.details()[0], params }] as const,
};

type MemberInvitationDetailsContext = QueryFunctionContext<
  ReturnType<(typeof memberInvitationsKeys)['detail']>
>;

export const useMemberInvitation = (token: string) =>
  useQuery({
    queryKey: memberInvitationsKeys.detail({ token }),
    queryFn: async ({
      queryKey: [{ params }],
    }: MemberInvitationDetailsContext) => await memberInvitationShow(params),
  });

export const useAcceptMemberInvitation = (token: string) => {
  const { pop } = useNotification();

  return useMutation({
    mutationFn: (form: AcceptMemberInvitation) =>
      memberInvitationAccept({ token, form }),

    onSuccess: () => {
      pop('Kvietimas priimtas sėkmingai');
    },

    onError: useErrorHandler(),
  });
};
