import { useTheme } from '@mui/material/styles';
import { lightTheme } from '@uiw/react-json-view/light';
import { darkTheme } from '@uiw/react-json-view/dark';

import JsonView from '@uiw/react-json-view';

type Props = {
  data: object;
};

const JsonViewer = ({ data }: Props) => {
  const { palette } = useTheme();

  return (
    <JsonView
      value={data}
      displayObjectSize={false}
      displayDataTypes={false}
      style={palette.mode === 'light' ? lightTheme : darkTheme}
    />
  );
};

export default JsonViewer;
