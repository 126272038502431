import {
  QueryFunctionContext,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import {
  AddMenuItem,
  EntitySortForm,
  menuCreateItem,
  menuDeleteItem,
  MenuDetails,
  MenuItemUpdate,
  MenuKey,
  menuShow,
  MenuShowInput,
  menuShowItem,
  MenuShowItemInput,
  menuUpdateItem,
  menuUpdateSorting,
} from 'schema';
import { useErrorHandler } from 'lib';
import { useNotification } from 'components/Notifications';
import { arrayMove } from '@dnd-kit/sortable';

export const menuKeys = {
  all: [{ scope: 'activities' }] as const,

  details: () => [{ ...menuKeys.all[0], entity: 'menu' }] as const,
  detail: (params: MenuShowInput) =>
    [{ ...menuKeys.details()[0], params }] as const,

  itemDetails: () => [{ ...menuKeys.all[0], entity: 'menu-item' }] as const,
  itemDetail: (params: MenuShowItemInput) =>
    [{ ...menuKeys.itemDetails()[0], params }] as const,
};

type MenuDetailsContext = QueryFunctionContext<
  ReturnType<(typeof menuKeys)['detail']>
>;

type MenuItemDetailsContext = QueryFunctionContext<
  ReturnType<(typeof menuKeys)['itemDetail']>
>;

export const useMenu = (menuKey: MenuKey) =>
  useQuery({
    queryKey: menuKeys.detail({ menuKey }),
    queryFn: async ({ queryKey: [{ params }] }: MenuDetailsContext) =>
      await menuShow(params),
  });

export const useMenuItem = (menuKey: MenuKey, item: number) =>
  useQuery({
    queryKey: menuKeys.itemDetail({ menuKey, item }),
    queryFn: async ({ queryKey: [{ params }] }: MenuItemDetailsContext) =>
      await menuShowItem(params),
  });

export const useCreateMenuItem = (menuKey: MenuKey) => {
  const client = useQueryClient();
  const handleError = useErrorHandler();
  const queryKey = menuKeys.detail({ menuKey });

  return useMutation({
    mutationFn: (form: AddMenuItem) => menuCreateItem({ menuKey, form }),

    onMutate: async ({ name }) => {
      await client.cancelQueries({ queryKey });

      const snapshot = client.getQueryData<MenuDetails>(queryKey);

      const max =
        snapshot?.items.reduce(
          (current, item) => (item.id > current ? item.id : current),
          0
        ) ?? 0;

      client.setQueryData<MenuDetails>(queryKey, menu => {
        if (!menu) return;
        const { items, ...rest } = menu;

        return {
          items: [{ id: max + 1, name }, ...(items ?? [])],
          ...rest,
        };
      });

      return { snapshot };
    },

    onError: (error: any, __, context) => {
      handleError(error);
      client.setQueryData(queryKey, context?.snapshot);
    },

    onSettled: () => {
      client.invalidateQueries({ queryKey });
    },
  });
};

export const useDeleteMenuItem = (menuKey: MenuKey) => {
  const client = useQueryClient();
  const queryKey = menuKeys.detail({ menuKey });
  const handleError = useErrorHandler();

  return useMutation({
    mutationFn: (item: number) => menuDeleteItem({ menuKey, item }),

    onMutate: async (deleted: number) => {
      await client.cancelQueries({ queryKey });

      const snapshot = client.getQueryData<MenuDetails>(queryKey);

      client.setQueryData<MenuDetails>(queryKey, menu => {
        if (!menu) return;
        const { items, ...rest } = menu;

        return {
          items: items.filter(item => item.id !== deleted) ?? [],
          ...rest,
        };
      });

      return { snapshot };
    },

    onError: (error: any, __, context) => {
      handleError(error);
      client.setQueryData(queryKey, context?.snapshot);
    },

    onSettled: () => {
      client.invalidateQueries({ queryKey });
    },
  });
};

export const useUpdateMenuItem = (menuKey: MenuKey, item: number) => {
  const client = useQueryClient();
  const queryKey = menuKeys.itemDetail({ menuKey, item });
  const { pop } = useNotification();

  return useMutation({
    mutationFn: (form: MenuItemUpdate) =>
      menuUpdateItem({ menuKey, item, form }),

    onSuccess: data => {
      client.setQueryData(queryKey, data);
      client.invalidateQueries({ queryKey: menuKeys.detail({ menuKey }) });

      pop('Išsaugota');
    },

    onError: useErrorHandler(),
  });
};

type SortData = {
  form: EntitySortForm;
  oldIndex: number;
  newIndex: number;
};

export const useUpdateMenuItemSorting = (menuKey: MenuKey) => {
  const client = useQueryClient();
  const queryKey = menuKeys.detail({ menuKey });
  const handleError = useErrorHandler();

  return useMutation({
    mutationFn: async ({ form }: SortData) =>
      menuUpdateSorting({ menuKey, form }),

    onMutate: async ({ oldIndex, newIndex }) => {
      await client.cancelQueries({ queryKey });

      const snapshot = client.getQueryData<MenuDetails>(queryKey);

      client.setQueryData<MenuDetails>(queryKey, menu => {
        if (!menu) return;
        const { items, ...rest } = menu;

        return {
          items: arrayMove(items, oldIndex, newIndex),
          ...rest,
        };
      });

      return { snapshot };
    },

    onSuccess: data => {
      client.setQueriesData({ queryKey }, data);
    },

    onError: (error: any, __, context) => {
      handleError(error);
      client.setQueryData(queryKey, context?.snapshot);
    },

    onSettled: () => {
      client.invalidateQueries({ queryKey });
    },
  });
};
