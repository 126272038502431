import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ButtonSkeleton from 'components/ButtonSkeleton';
import EditorSkeleton from 'components/EditorSkeleton';
import FormActions from 'components/FormActions';
import TemplateVariablesSkeleton from 'components/TemplateVariablesSkeleton';

const ActivityConfirmationEmailSettingsSkeleton = () => (
  <Stack>
    <Paper sx={{ p: 2 }}>
      <Skeleton width={400} />
    </Paper>

    <Paper sx={{ p: 2 }}>
      <Typography variant="h6">
        <Skeleton width={200} />
      </Typography>

      <TemplateVariablesSkeleton />
    </Paper>

    <Paper sx={{ p: 2 }}>
      <Typography variant="h6">
        <Skeleton width={300} />
      </Typography>

      <EditorSkeleton />
    </Paper>

    <Paper sx={{ p: 2 }}>
      <FormActions>
        <ButtonSkeleton />
      </FormActions>
    </Paper>
  </Stack>
);

export default ActivityConfirmationEmailSettingsSkeleton;
