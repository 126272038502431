import { HTMLProps } from 'react';

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

type Props = HTMLProps<HTMLDivElement> & {
  value: number;
  index: number;
  dismount?: boolean;
  paper?: boolean;
};

const TabberPanel = (props: Props) => {
  const {
    children,
    value,
    index,
    dismount = true,
    paper = false,
    ...other
  } = props;

  const Component: any = paper ? Paper : Box;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {(value === index || !dismount) && (
        <Component sx={[{ mt: 2 }]}>{children}</Component>
      )}
    </div>
  );
};

export default TabberPanel;
