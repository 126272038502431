import { useCallback } from 'react';

import TableCell from '@mui/material/TableCell';
import TableRow from 'components/TableRow';

import { useDialog } from 'components/Dialogs';
import { formatDateTime } from 'lib';
import { HttpMessageView } from 'schema';

type Props = {
  message: HttpMessageView;
};

const HttpRequestRow = ({ message }: Props) => {
  const { uri, responseStatus, method, sentAt } = message;
  const [, { open }] = useDialog('httpMessage');

  const handleClick = useCallback(() => {
    open({ message });
  }, [message, open]);

  return (
    <TableRow hover onClick={handleClick} sx={{ cursor: 'pointer' }}>
      <TableCell>{uri}</TableCell>
      <TableCell align="center">{method}</TableCell>
      <TableCell align="center">{responseStatus}</TableCell>
      <TableCell align="right">{formatDateTime(sentAt)}</TableCell>
    </TableRow>
  );
};

export default HttpRequestRow;
