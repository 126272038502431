import FormSelect, { FormSelectProps } from 'components/FormSelect';

import { usePages } from 'features/Page';
import { PageListView } from 'schema';

type Props = Omit<FormSelectProps<PageListView>, 'options'>;

const PageSelect = (props: Props) => {
  const query = usePages();

  return (
    <FormSelect
      {...props}
      options={query.data ?? []}
      loading={query.isPending}
      noOptionsText="Dar nėra puslapių"
    />
  );
};

export default PageSelect;
