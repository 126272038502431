import { useCallback } from 'react';

import Page from 'components/Page';
import ToolbarButton from 'components/ToolbarButton';
import PagesTable from 'components/PagesTable';

import { useDialog } from 'components/Dialogs';

const PageList = () => {
  const [, { open }] = useDialog('pageAdd');

  const handleAddClick = useCallback(() => {
    open({});
  }, [open]);

  return (
    <Page
      title="Puslapiai"
      actions={
        <ToolbarButton tooltip="Nauja kategorija" onClick={handleAddClick} />
      }>
      <PagesTable />
    </Page>
  );
};

export default PageList;
