import MenuItem, { MenuItemProps } from 'components/MenuItem';

import { ConfirmDialogProps, useConfirm } from 'components/ConfirmDialog';

type Props = ConfirmDialogProps & MenuItemProps;

const ConfirmableMenuItem = (props: Props) => {
  const { onConfirm, title, text, ...rest } = props;
  const confirm = useConfirm({ onConfirm, title, text });

  return <MenuItem onClick={confirm} {...rest} />;
};

export default ConfirmableMenuItem;
