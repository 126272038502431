import { useLocation } from 'react-router-dom';

import InsightsIcon from '@mui/icons-material/Insights';
import EmailIcon from '@mui/icons-material/Email';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import StoreIcon from '@mui/icons-material/Store';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DiscountIcon from '@mui/icons-material/Discount';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import PersonIcon from '@mui/icons-material/Person';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Link from '@mui/material/Link';
import Drawer from 'components/Drawer';
import UserMenuItem from 'components/UserMenuItem';
import LogoutMenuItem from 'components/LogoutMenuItem';
import MainMenuSettings from 'components/MainMenuSettings';

import logo from 'assets/img/logo.png';
import { useClub } from 'components/ClubProvider';

type Props = {
  open: boolean;
  onToggle: () => void;
};

export const selected = (path: string, pathname: string) =>
  pathname.startsWith(path);

function MainMenu({ open, onToggle }: Props) {
  const { pathname } = useLocation();
  const { member } = useClub();

  return (
    <Drawer variant="permanent" open={open}>
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          px: [1],
        }}>
        <Box
          sx={{
            flexGrow: 1,
            pt: 1,
            pl: 1,
            '& img': { maxHeight: '100%', maxWidth: '100%' },
          }}>
          <img src={logo} alt="iGudo" />
        </Box>

        <IconButton onClick={onToggle}>
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>

      <Divider />

      <Box
        sx={{
          overflowY: 'overlay',
          overflowX: 'clip',
          scrollbarGutter: 'auto',
        }}>
        <List>
          {(member.isCoach || member.isAdmin) && (
            <ListItemButton
              href="activities"
              component={Link}
              selected={selected('/activities', pathname)}>
              <ListItemIcon>
                <BeachAccessIcon />
              </ListItemIcon>

              <ListItemText primary="Veiklos" />
            </ListItemButton>
          )}

          {member.isAdmin && (
            <ListItemButton
              href="discounts"
              component={Link}
              selected={selected('/discounts', pathname)}>
              <ListItemIcon>
                <DiscountIcon />
              </ListItemIcon>

              <ListItemText primary="Nuolaidų kuponai" />
            </ListItemButton>
          )}

          <ListItemButton
            href="/messages"
            component={Link}
            selected={selected('/messages', pathname)}>
            <ListItemIcon>
              <EmailIcon />
            </ListItemIcon>

            <ListItemText primary="Komunikacija" />
          </ListItemButton>

          <ListItemButton
            href="timetable"
            component={Link}
            selected={selected('/timetable', pathname)}>
            <ListItemIcon>
              <CalendarMonthIcon />
            </ListItemIcon>

            <ListItemText primary="Tvarkaraštis" />
          </ListItemButton>

          <ListItemButton
            href="surveys"
            component={Link}
            selected={selected('/surveys', pathname)}>
            <ListItemIcon>
              <InsightsIcon />
            </ListItemIcon>

            <ListItemText primary="Tyrimai" />
          </ListItemButton>

          {member.isAdmin && (
            <ListItemButton
              href="orders"
              component={Link}
              selected={selected('/orders', pathname)}>
              <ListItemIcon>
                <ShoppingCartIcon />
              </ListItemIcon>

              <ListItemText primary="Užsakymai" />
            </ListItemButton>
          )}

          {member.isAdmin && (
            <ListItemButton
              href="invoices"
              component={Link}
              selected={selected('/invoices', pathname)}>
              <ListItemIcon>
                <CreditCardIcon />
              </ListItemIcon>

              <ListItemText primary="Atsiskaitymai" />
            </ListItemButton>
          )}

          {(member.isAdmin || member.isCoach) && (
            <ListItemButton
              href="attendants"
              component={Link}
              selected={selected('/attendants', pathname)}>
              <ListItemIcon>
                <SupervisorAccountIcon />
              </ListItemIcon>

              <ListItemText primary="Dalyviai" />
            </ListItemButton>
          )}

          {member.isAdmin && (
            <ListItemButton
              href="users"
              component={Link}
              selected={selected('/users', pathname)}>
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>

              <ListItemText primary="Vartotojai" />
            </ListItemButton>
          )}

          {member.isAdmin && (
            <ListItemButton
              href="club"
              component={Link}
              selected={selected('/club', pathname)}>
              <ListItemIcon>
                <StoreIcon />
              </ListItemIcon>

              <ListItemText primary="Klubas" />
            </ListItemButton>
          )}

          <Divider />
          <MainMenuSettings />
          <Divider />

          <UserMenuItem
            href="account"
            selected={selected('/account', pathname)}
          />

          <LogoutMenuItem />
        </List>
      </Box>
    </Drawer>
  );
}

export default MainMenu;
