import { useCallback } from 'react';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Table from 'components/Table';
import CategoryTableRow from 'components/CategoryTableRow';
import Page from 'components/Page';
import PageSkeleton from 'components/PageSkeleton';
import ToolbarButton from 'components/ToolbarButton';

import { useCategories } from 'features/Category/queries';
import { useDialog } from 'components/Dialogs';

const CategoriesList = () => {
  const [, { open }] = useDialog('categoryAdd');
  const query = useCategories();

  const handleAddClick = useCallback(() => {
    open({});
  }, [open]);

  if (!query.data) return <PageSkeleton>Kraunasi...</PageSkeleton>;

  return (
    <Page
      title="Kategorijos"
      actions={
        <ToolbarButton tooltip="Nauja kategorija" onClick={handleAddClick} />
      }>
      <Table
        head={
          <TableRow>
            <TableCell component="th">Nuotrauka</TableCell>
            <TableCell component="th">Pavadinimas</TableCell>
            <TableCell component="th"></TableCell>
          </TableRow>
        }>
        {query.data.map(category => (
          <CategoryTableRow key={category.id} category={category} />
        ))}
      </Table>
    </Page>
  );
};

export default CategoriesList;
