import { useCallback } from 'react';

import SendIcon from '@mui/icons-material/Send';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import ConfirmableIconButton from 'components/ConfirmableIconButton';

import {
  CoachSessionDetailsView,
  CoachSessionReservationListView,
} from 'schema';
import { useSendReservationInvitation } from 'features/SessionReservation';

type Props = {
  session: CoachSessionDetailsView;
  reservation: CoachSessionReservationListView;
};

const SessionReservationInviteButton = ({ session, reservation }: Props) => {
  const invite = useSendReservationInvitation(
    session.activity.id,
    session.id,
    reservation.id
  );

  const handleSendInvitation = useCallback(() => {
    invite.mutate();
  }, [invite]);

  return (
    <ConfirmableIconButton
      size="small"
      sx={{ ml: 1 }}
      onConfirm={handleSendInvitation}
      text="Ar tikrai norite siųsti kvietimą?">
      {invite.isPending ? (
        <CircularProgress size={20} />
      ) : (
        <Tooltip title="Siųsti kvietimą">
          <SendIcon fontSize="small" color="primary" />
        </Tooltip>
      )}
    </ConfirmableIconButton>
  );
};

export default SessionReservationInviteButton;
