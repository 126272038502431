import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';

type Props = {
  children: ReactNode;
};

type PaginatorPageInfo = {
  page: number;
  size: number;
};

type PaginatorActions = {
  setPage: (page: number) => void;
  setSize: (size: number) => void;
};

type Context = [PaginatorPageInfo, PaginatorActions];

const PaginationContext = createContext<Context>([
  {
    page: 0,
    size: 25,
  },
  {
    setPage: () => {},
    setSize: () => {},
  },
]);

const PAGE_SIZE = 'igd_page_size';

export const usePagination = () => useContext(PaginationContext);

const Pagination = ({ children }: Props) => {
  const [info, setInfo] = useState<PaginatorPageInfo>({
    page: 0,
    size: Number(localStorage.getItem(PAGE_SIZE) ?? 25),
  });

  const setPage = useCallback((page: number) => {
    setInfo(v => ({ ...v, page }));
  }, []);

  const setSize = useCallback((size: number) => {
    setInfo(v => ({ ...v, size }));
    localStorage.setItem(PAGE_SIZE, size.toString());
  }, []);

  return (
    <PaginationContext.Provider value={[info, { setPage, setSize }]}>
      {children}
    </PaginationContext.Provider>
  );
};

export default Pagination;
