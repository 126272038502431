import { useCallback } from 'react';
import { useField } from 'formik';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PageSelect from 'components/PageSelect';
import Section from 'components/Section';

import { SelectOption } from 'components/Select';
import { PageListView } from 'schema';
import { useDialog } from 'components/Dialogs';

const MenuEditorPageForm = () => {
  const [{ value }, , { setValue }] = useField<SelectOption | null>('page');
  const [, { open: openAdd }] = useDialog('pageAdd');
  const [, { open: openEdit }] = useDialog('pageEdit');

  const handleAdd = useCallback(
    (page: PageListView) => {
      setValue(page);
    },
    [setValue]
  );

  const handleAddClick = useCallback(() => {
    openAdd({ onAdd: handleAdd });
  }, [handleAdd, openAdd]);

  const handleEditClick = useCallback(() => {
    openEdit({ page: value, onUpdate: setValue });
  }, [openEdit, setValue, value]);

  return (
    <Section>
      <Typography variant="h6">Meniu puslapis</Typography>

      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <PageSelect fullWidth name="page" label="Puslapis" />

        <Stack spacing={1} direction="row" sx={{ ml: 2 }}>
          <Tooltip title="Redaguoti puslapį">
            <span>
              <IconButton disabled={!value} onClick={handleEditClick}>
                <EditIcon />
              </IconButton>
            </span>
          </Tooltip>

          <Tooltip title="Pridėti naują puslapį">
            <IconButton onClick={handleAddClick}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      </Box>
    </Section>
  );
};

export default MenuEditorPageForm;
