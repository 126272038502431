import useTheme from '@mui/material/styles/useTheme';

import Box, { BoxProps } from '@mui/material/Box';
import ImageIcon from '@mui/icons-material/Image';

const NoPhoto = ({ sx = [], ...props }: BoxProps) => {
  const {
    palette: { mode },
  } = useTheme();

  const bgcolor = mode === 'light' ? '#dfdfdf' : '#121212';

  return (
    <Box
      sx={[
        {
          bgcolor,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'text.secondary',
          fontSize: '72px',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...props}>
      <ImageIcon fontSize="inherit" />
    </Box>
  );
};

export default NoPhoto;
