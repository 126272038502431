import { createBrowserRouter, Navigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import Root from 'routes/Root';
import ErrorPage from 'routes/ErrorPage';
import ActivitiesList from 'routes/ActivitiesList';
import ActivityEdit from 'routes/ActivityEdit';
import CategoriesList from 'routes/CategoriesList';
import CategoryEdit from 'routes/CategoryEdit';
import SessionEdit from 'routes/SessionEdit';
import InvoicesList from 'routes/InvoicesList';
import InvoiceEdit from 'routes/InvoiceEdit';
import SettingsPage from 'routes/SettingsPage';
import ClubEditPage from 'routes/ClubEditPage';
import PublicHolidays from 'routes/PublicHolidays';
import MenuEdit from 'routes/MenuEdit';
import PageList from 'routes/PageList';
import PageEdit from 'routes/PageEdit';
import SurveyList from 'routes/SurveyList';
import SurveyDetails from 'routes/SurveyDetails';
import AttendantList from 'routes/AttendantList';
import AttendantDetails from 'routes/AttendantDetails';
import UserList from 'routes/UserList';
import JoinPage from 'routes/JoinPage';
import UserDetailsPage from 'routes/UserDetailsPage';
import Payments from 'routes/Payments';
import DiscountList from 'routes/DiscountList';
import DiscountEdit from 'routes/DiscountEdit';
import OrderList from 'routes/OrderList';
import OrderEdit from './OrderEdit';

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter([
  {
    path: '/',
    errorElement: <ErrorPage />,
    element: <Root />,
    children: [
      { index: true, element: <Navigate to="/activities" replace /> },
      {
        path: 'activities',
        errorElement: <ErrorPage />,
        children: [
          {
            index: true,
            errorElement: <ErrorPage />,
            element: <ActivitiesList />,
          },
          {
            path: ':activity',
            errorElement: <ErrorPage />,
            children: [
              {
                index: true,
                errorElement: <ErrorPage />,
                element: <ActivityEdit />,
              },
              {
                path: 'sessions',
                errorElement: <ErrorPage />,
                children: [
                  {
                    path: ':session',
                    errorElement: <ErrorPage />,
                    element: <SessionEdit />,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'discounts',
        children: [
          {
            index: true,
            element: <DiscountList />,
          },
          {
            path: ':discount',
            element: <DiscountEdit />,
          },
        ],
      },
      {
        path: 'messages',
        children: [
          {
            index: true,
            element: <div>Komunikacija</div>,
          },
          {
            path: ':id',
            element: <div>Žinutė</div>,
          },
        ],
      },
      {
        path: 'timetable',
        errorElement: <ErrorPage />,
        element: <div>Tvarkaraštis</div>,
      },
      {
        path: 'surveys',
        errorElement: <ErrorPage />,
        children: [
          {
            index: true,
            errorElement: <ErrorPage />,
            element: <SurveyList />,
          },
          {
            path: ':id',
            element: <SurveyDetails />,
          },
        ],
      },
      {
        path: 'orders',
        errorElement: <ErrorPage />,
        children: [
          {
            index: true,
            errorElement: <ErrorPage />,
            element: <OrderList />,
          },
          {
            path: ':order',
            element: <OrderEdit />,
          },
        ],
      },
      {
        path: 'invoices',
        errorElement: <ErrorPage />,
        children: [
          {
            index: true,
            errorElement: <ErrorPage />,
            element: <InvoicesList />,
          },
          {
            path: ':id',
            element: <InvoiceEdit />,
          },
        ],
      },
      {
        path: 'users',
        errorElement: <ErrorPage />,
        children: [
          {
            index: true,
            element: <UserList />,
          },
          {
            path: ':user',
            element: <UserDetailsPage />,
          },
        ],
      },
      {
        path: 'club',
        errorElement: <ErrorPage />,
        element: <ClubEditPage />,
      },
      {
        path: 'settings',
        errorElement: <ErrorPage />,
        element: <SettingsPage />,
      },
      {
        path: 'attendants',
        errorElement: <ErrorPage />,
        children: [
          {
            index: true,
            element: <AttendantList />,
          },
          {
            path: ':attendant',
            element: <AttendantDetails />,
          },
        ],
      },
      {
        path: 'categories',
        errorElement: <ErrorPage />,
        children: [
          {
            index: true,
            element: <CategoriesList />,
          },
          {
            path: ':id',
            element: <CategoryEdit />,
          },
        ],
      },
      {
        path: 'menu',
        errorElement: <ErrorPage />,
        element: <MenuEdit />,
      },
      {
        path: 'pages',
        errorElement: <ErrorPage />,
        children: [
          {
            index: true,
            errorElement: <ErrorPage />,
            element: <PageList />,
          },
          {
            path: ':id',
            element: <PageEdit />,
          },
        ],
      },
      {
        path: 'payments',
        errorElement: <ErrorPage />,
        element: <Payments />,
      },
      {
        path: 'public-holidays',
        errorElement: <ErrorPage />,
        element: <PublicHolidays />,
      },
      {
        path: 'account',
        errorElement: <ErrorPage />,
        element: <div>Paskyra</div>,
      },
    ],
  },
  {
    path: '/join/:token',
    errorElement: <ErrorPage />,
    element: <JoinPage />,
  },
]);
