import { ReactNode } from 'react';

import TableViewIcon from '@mui/icons-material/TableView';
import Box from '@mui/material/Box';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';

type Props = {
  headSize?: number;
  noRecordsText?: ReactNode;
  noRecordsIcon?: ReactNode;
};

const TableEmpty = (props: Props) => {
  const {
    headSize = 1,
    noRecordsText = 'Dar nėra įrašų',
    noRecordsIcon = <TableViewIcon fontSize="large" />,
  } = props;

  return (
    <TableRow>
      <TableCell
        colSpan={headSize}
        sx={{ color: 'text.secondary', textAlign: 'center', pt: 4, pb: 4 }}>
        <Box>{noRecordsIcon}</Box>
        <Typography>{noRecordsText}</Typography>
      </TableCell>
    </TableRow>
  );
};

export default TableEmpty;
