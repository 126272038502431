import ArticleIcon from '@mui/icons-material/Article';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

const MenuEditorUnselected = () => (
  <Paper
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      minHeight: 300,
      flexGrow: 1,
    }}>
    <Box
      sx={{
        fontSize: '10rem',
        lineHeight: '10rem',
        color: 'text.secondary',
      }}>
      <ArticleIcon fontSize="inherit" />
    </Box>

    <Typography color="text.secondary">
      Pasirinkite meniu punktą redagavimui
    </Typography>
  </Paper>
);

export default MenuEditorUnselected;
