import DownloadIcon from '@mui/icons-material/Download';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';

import { useClub } from 'components/ClubProvider';
import { useDownload } from 'lib';
import { AttendantSurveyListView } from 'schema';

type Props = {
  survey: AttendantSurveyListView;
};

const AttendantSurveyTableRowDownload = ({ survey }: Props) => {
  // TODO: @Hardcode
  const [download, isDownloading] = useDownload(
    `/api/v1/clubs/${useClub().id}/surveys/${survey.id}/xlsx`
  );

  return (
    <IconButton size="small" onClick={download}>
      {isDownloading ? (
        <CircularProgress size={20} />
      ) : (
        <DownloadIcon fontSize="small" />
      )}
    </IconButton>
  );
};

export default AttendantSurveyTableRowDownload;
