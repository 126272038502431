import { MouseEvent, useCallback } from 'react';

import MuiMenuItem, {
  MenuItemProps as MuiMenuItemProps,
} from '@mui/material/MenuItem';
import { useMenu } from 'components/MenuButton';

type Props = MuiMenuItemProps;

export type MenuItemProps = Props;

const MenuItem = ({ onClick, ...props }: Props) => {
  const { close } = useMenu();

  const handleClick = useCallback(
    (event: MouseEvent<HTMLLIElement>) => {
      close();
      onClick && onClick(event);
    },
    [close, onClick]
  );

  return <MuiMenuItem onClick={handleClick} {...props} />;
};

export default MenuItem;
