import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import UserDetailsRoles from 'components/UserDetailsRoles';
import UserDetailsPassword from 'components/UserDetailsPassword';

import { ClubMemberDetails } from 'schema';

type Props = {
  member: ClubMemberDetails;
};

const UserDetails = ({ member }: Props) => {
  const { user } = member;

  return (
    <Grid container spacing={2}>
      <Grid xs={6}>
        <Paper sx={{ p: 2, textAlign: 'center', height: '100%' }}>
          <Box
            sx={{
              fontSize: 256,
              color: 'text.secondary',
              height: 300,
            }}>
            <AccountCircleIcon fontSize="inherit" />
          </Box>

          <Typography variant="h4">{user.fullName}</Typography>
          <Typography color="text.secondary">{user.email}</Typography>

          {user.phone && (
            <Typography color="text.secondary">{user.phone}</Typography>
          )}
        </Paper>
      </Grid>

      <Grid xs={6}>
        <Paper sx={{ p: 2, height: '100%' }}>
          <UserDetailsRoles member={member} />
          <Divider sx={{ mt: 2, mb: 2 }} />
          <UserDetailsPassword member={member} />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default UserDetails;
