import TableCell from '@mui/material/TableCell';
import SessionsInvitationsTableRow from 'components/SessionsInvitationsTableRow';
import Table from 'components/Table';
import TableRow from 'components/TableRow';

import { useInvitations } from 'features/AttendantInvitation';
import { CoachSessionDetailsView } from 'schema';

type Props = {
  session: CoachSessionDetailsView;
};

const SessionInvitations = ({ session }: Props) => {
  const invitations = useInvitations(session.activity.id, session.id);

  return (
    <Table
      loading={invitations.isPending}
      head={
        <TableRow>
          <TableCell component="th">Globėjas</TableCell>

          <TableCell component="th" align="center">
            El. paštas
          </TableCell>

          <TableCell component="th" align="center">
            Išsiųsta
          </TableCell>

          <TableCell component="th" />
        </TableRow>
      }>
      {invitations.data?.map(invitation => (
        <SessionsInvitationsTableRow
          key={invitation.id}
          session={session}
          invitation={invitation}
        />
      ))}
    </Table>
  );
};

export default SessionInvitations;
