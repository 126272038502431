import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

type Props = {
  rows?: number;
  cols?: number;
  minTextWidth?: number;
  maxTextWidth?: number;
};

const TableSkeleton = (props: Props) => {
  const { rows = 5, cols = 3, minTextWidth = 300, maxTextWidth = 500 } = props;

  const r = [];
  for (let y = 0; y < rows; y++) {
    const c = [];

    for (let x = 0; x < cols; x++) {
      c.push(
        <TableCell key={x}>
          <Skeleton
            width={minTextWidth + (maxTextWidth - minTextWidth) * Math.random()}
          />
        </TableCell>
      );
    }

    r.push(<TableRow key={y}>{c}</TableRow>);
  }

  return (
    <TableContainer>
      <Table>
        <TableBody>{r}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableSkeleton;
