import {
  QueryFunctionContext,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useNotification } from 'components/Notifications';
import { useErrorHandler } from 'lib';
import {
  ClubDiscountIndexInput,
  ClubDiscountIndexParams,
  ClubDiscountShowInput,
  DiscountForm,
  DiscountListView,
  clubDiscountCreate,
  clubDiscountDelete,
  clubDiscountIndex,
  clubDiscountShow,
  clubDiscountUpdate,
} from 'schema';

export const discountsKeys = {
  all: [{ scope: 'discounts' }] as const,

  lists: () => [{ ...discountsKeys.all[0], entity: 'list' }] as const,
  list: (params: ClubDiscountIndexInput) =>
    [{ ...discountsKeys.lists()[0], params }] as const,

  details: () => [{ ...discountsKeys.all[0], entity: 'details' }] as const,
  detail: (params: ClubDiscountShowInput) =>
    [{ ...discountsKeys.details()[0], params }] as const,
};

type DiscountListContext = QueryFunctionContext<
  ReturnType<(typeof discountsKeys)['list']>
>;

type DiscountDetailsContext = QueryFunctionContext<
  ReturnType<(typeof discountsKeys)['detail']>
>;

export const useDiscounts = (club: number, params: ClubDiscountIndexParams) =>
  useQuery({
    queryKey: discountsKeys.list({ club, params }),
    queryFn: async ({ queryKey: [{ params }] }: DiscountListContext) =>
      await clubDiscountIndex(params),
  });

export const useDiscount = (club: number, discount: number) =>
  useQuery({
    queryKey: discountsKeys.detail({ club, discount }),
    queryFn: async ({ queryKey: [{ params }] }: DiscountDetailsContext) =>
      await clubDiscountShow(params),
  });

export const useCreateDiscount = (club: number) => {
  const client = useQueryClient();

  return useMutation({
    mutationFn: (form: DiscountForm) => clubDiscountCreate({ club, form }),
    onError: useErrorHandler(),

    onSuccess: data => {
      client.invalidateQueries({
        queryKey: discountsKeys.lists(),
      });
    },
  });
};

export const useUpdateDiscount = (club: number, discount: number) => {
  const client = useQueryClient();
  const { pop } = useNotification();

  return useMutation({
    mutationFn: (form: DiscountForm) =>
      clubDiscountUpdate({ club, discount, form }),
    onError: useErrorHandler(),

    onSuccess: () => {
      client.invalidateQueries({
        queryKey: discountsKeys.lists(),
      });

      pop('Išsaugota');
    },
  });
};

export const useDeleteDiscount = (
  club: number,
  discount: number,
  params: ClubDiscountIndexParams
) => {
  const client = useQueryClient();
  const queryKey = discountsKeys.list({ club, params });
  const handleError = useErrorHandler();

  return useMutation({
    mutationFn: () => clubDiscountDelete({ club, discount }),
    onMutate: async () => {
      await client.cancelQueries({ queryKey });

      const snapshot = client.getQueryData<DiscountListView[]>(queryKey);

      client.setQueryData<DiscountListView[]>(
        queryKey,
        items => items?.filter(item => item.id !== discount) ?? []
      );

      return { snapshot };
    },

    onError: (error: any, __, context) => {
      handleError(error);
      client.setQueryData(queryKey, context?.snapshot);
    },

    onSettled: () => {
      client.invalidateQueries({
        queryKey: discountsKeys.lists(),
      });
    },
  });
};
