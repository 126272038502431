import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';

import Paper from '@mui/material/Paper';
import PageSkeleton from 'components/PageSkeleton';
import Page from 'components/Page';
import FormActions from 'components/FormActions';
import FormButton from 'components/FormButton';
import CategoryFormFields, {
  FormFields,
  schema,
} from 'components/CategoryFormFields';

import { useCategory, useUpdateCategory } from 'features/Category';

const CategoryEdit = () => {
  const { id } = useParams();
  const query = useCategory(Number(id));
  const update = useUpdateCategory(Number(id));

  const handleSubmit = useCallback(
    ({ photo, ...form }: FormFields) => {
      update.mutate({
        category: Number(id),
        form,
        files: photo?.uploaded ? { photo: photo.uploaded } : null,
      });
    },
    [id, update]
  );

  if (!query.data) return <PageSkeleton>Kraunasi...</PageSkeleton>;

  const initial: FormFields = {
    ...query.data,
    photo: {
      currentUrl: query.data.photo?.publicUrl ?? null,
      uploaded: null,
    },
  };

  return (
    <Page title={query.data.name}>
      <Formik<FormFields>
        initialValues={initial}
        onSubmit={handleSubmit}
        validationSchema={schema}>
        <Form>
          <Paper sx={{ p: 2 }}>
            <CategoryFormFields />

            <FormActions>
              <FormButton loading={update.isPending} />
            </FormActions>
          </Paper>
        </Form>
      </Formik>
    </Page>
  );
};

export default CategoryEdit;
