import { useRouteError } from 'react-router-dom';

function ErrorPage() {
  const error = useRouteError() as any;

  return (
    <div id="error-page">
      <h1>Atsiprašome, įvyko klaida!</h1>
      <p>
        Pabandykite perkrauti puslapį, jei tai nepadeda kreipkitės į
        administratorius
      </p>

      <p>
        <i>
          {error.response?.data.message || error.statusText || error.message}
        </i>
      </p>
    </div>
  );
}

export default ErrorPage;
