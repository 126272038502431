import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { ReactNode } from 'react';
import { CoachSessionListView } from 'schema';

type Props = {
  session: CoachSessionListView;
  children: ReactNode;
};

const SessionCapacityIndicatorBadge = ({ session, children }: Props) => {
  const { numberOfReservations, numberOfInvitations } = session;

  const title = (
    <>
      {numberOfReservations > 0 && <Box>Rezerve: {numberOfReservations}</Box>}
      {numberOfInvitations > 0 && <Box>Pakvietimų: {numberOfInvitations}</Box>}
    </>
  );

  return (
    <Tooltip title={title}>
      <Badge variant="dot" color="error">
        {children}
      </Badge>
    </Tooltip>
  );
};

export default SessionCapacityIndicatorBadge;
