import { useField } from 'formik';

import Stack, { StackProps } from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import FormTextField from 'components/FormTextField';
import MenuEditorExternalUrlForm from 'components/MenuEditorExternalUrlForm';
import MenuEditorPageForm from 'components/MenuEditorPageForm';
import MenuItemTypeSelect from 'components/MenuItemTypeSelect';
import Section from 'components/Section';

import { SelectOption } from 'components/Select';
import { MenuItemDetails, MenuItemType } from 'schema';

export type Fields = {
  name: string;
  type: MenuItemType;
  page: SelectOption | null;
  url: string;
};

type Props = StackProps & {
  item: MenuItemDetails;
};

const MenuEditorContentForm = ({ item, ...props }: Props) => {
  const [{ value }] = useField<MenuItemType>('type');

  return (
    <Stack spacing={1} {...props}>
      <Section>
        <Typography variant="h6" sx={{ mb: 2 }}>
          {item.name}
        </Typography>

        <Stack spacing={4}>
          <FormTextField name="name" label="Pavadinimas" />
          <MenuItemTypeSelect name="type" label="Tipas" />
        </Stack>
      </Section>

      {value === 'page' && <MenuEditorPageForm />}
      {value === 'external' && <MenuEditorExternalUrlForm />}
    </Stack>
  );
};

export default MenuEditorContentForm;
