import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import FormDatePicker from 'components/FormDatePicker';
import FormTextField from 'components/FormTextField';
import { useCallback, useState } from 'react';

type Props = {
  index: number;
  onDelete: (index: number) => void;
};

const EarlyBirdPricesFieldRow = ({ index, onDelete }: Props) => {
  const [hover, setHover] = useState(false);

  const handleMouseEnter = useCallback(() => {
    setHover(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setHover(false);
  }, []);

  const handleDelete = useCallback(() => {
    onDelete(index);
  }, [index, onDelete]);

  return (
    <TableRow
      sx={{ '& td': { border: 0 } }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      <TableCell sx={{ pl: 0, width: 50 }}>
        {hover ? (
          <IconButton sx={{ ml: -1 }} size="small" onClick={handleDelete}>
            <DeleteIcon fontSize="small" color="error" />
          </IconButton>
        ) : (
          'iki'
        )}
      </TableCell>

      <TableCell>
        <FormDatePicker fullWidth name={`prices.${index}.validUntil`} />
      </TableCell>

      <TableCell sx={{ pr: 0 }}>
        <FormTextField
          fullWidth
          name={`prices.${index}.price`}
          InputProps={{
            endAdornment: <InputAdornment position="end">€</InputAdornment>,
          }}
        />
      </TableCell>
    </TableRow>
  );
};

export default EarlyBirdPricesFieldRow;
