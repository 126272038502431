import Box from '@mui/material/Box';
import Stack, { StackProps } from '@mui/material/Stack';

type Props = StackProps & {};

const FormActions = ({ children, ...props }: Props) => (
  <Stack spacing={2} direction="row" {...props}>
    <Box sx={{ flexGrow: 1 }} />
    {children}
  </Stack>
);

export default FormActions;
