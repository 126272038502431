import { useCallback } from 'react';
import { Form, Formik } from 'formik';
import { date, object, string } from 'yup';

import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from 'components/DialogTitle';
import Typography from '@mui/material/Typography';
import Dialog from 'components/Dialog';
import DialogContent from 'components/DialogContent';
import FormDatePicker from 'components/FormDatePicker';
import TwoColumn from 'components/TwoColumn';

import { useDialog } from 'components/Dialogs';
import { useUpdateShift } from 'features/Shift';
import { useErrorHandler } from 'lib';

import { ActivityDetailsView, ActivityType, ShiftView } from 'schema';

type Fields = {
  activity: {
    type: ActivityType;
  };

  start: Date | null;
  end: Date | null;
};

type Props = {
  activity: ActivityDetailsView;
  shift: ShiftView;
};

export type UpdateShiftDialogProps = Props;

const schema = object({
  activity: object({
    type: string(),
  }),

  start: date().nullable().required('Prašome įvesti pradžios datą'),

  end: date()
    .nullable()
    .when('activity', {
      is: ({ type }: ActivityDetailsView) => type === 'camp',
      then: schema => schema.required('Prašome įvesti pabaigos datą.'),
      otherwise: schema => schema,
    }),
});

const UpdateShiftDialog = ({ activity, shift }: Props) => {
  const [, { close }] = useDialog('shiftUpdate');
  const { start, end } = shift;
  const create = useUpdateShift(activity.id, shift.id);
  const onError = useErrorHandler();

  const initial = {
    activity,
    start,
    end,
  };

  const handleSubmit = useCallback(
    ({ start, ...rest }: Fields) => {
      const form = {
        start: start!,
        ...rest,
      };

      create.mutate(form, {
        onSuccess: () => {
          close();
        },
        onError,
      });
    },
    [close, create, onError]
  );

  return (
    <Dialog dialog="shiftUpdate">
      <Formik<Fields>
        initialValues={initial}
        onSubmit={handleSubmit}
        validationSchema={schema}>
        <Form>
          <DialogTitle onClose={close}>Pamaina</DialogTitle>

          <DialogContent>
            <Typography sx={{ mb: 2 }}>Veikla: {activity.name}</Typography>

            <TwoColumn
              gutter
              leftSlot={<FormDatePicker name="start" />}
              rightSlot={<FormDatePicker name="end" />}
            />
          </DialogContent>

          <DialogActions>
            <Button onClick={close} variant="text">
              Atšaukti
            </Button>

            <LoadingButton
              loading={create.isPending}
              variant="contained"
              type="submit"
              startIcon={<SaveIcon />}>
              Saugoti
            </LoadingButton>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

export default UpdateShiftDialog;
