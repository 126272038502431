import ReplayIcon from '@mui/icons-material/Replay';
import SendIcon from '@mui/icons-material/Send';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { useSendInvoice } from 'features/Invoice';
import { useCallback } from 'react';
import { ClubInvoiceListView } from 'schema';

type Props = {
  invoice: ClubInvoiceListView;
};

const InvoiceSendButton = ({ invoice }: Props) => {
  const { sentAt } = invoice;
  const send = useSendInvoice(invoice.id);

  const handleSend = useCallback(() => {
    send.mutate();
  }, [send]);

  return (
    <IconButton
      size="small"
      color="primary"
      onClick={handleSend}
      disabled={send.isPending}>
      {send.isPending ? (
        <CircularProgress size={18} />
      ) : sentAt ? (
        <Tooltip title="Kartoti siuntimą">
          <ReplayIcon fontSize="small" />
        </Tooltip>
      ) : (
        <Tooltip title="Siųsti sąskaitą">
          <SendIcon fontSize="small" />
        </Tooltip>
      )}
    </IconButton>
  );
};

export default InvoiceSendButton;
