import { useCallback } from 'react';
import { Form, Formik } from 'formik';

import AddIcon from '@mui/icons-material/Add';
import DialogTitle from 'components/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from 'components/Dialog';
import PageFormFields, { Fields, schema } from 'components/PageFormFields';

import { useDialog } from 'components/Dialogs';
import { useCreatePage } from 'features/Page';
import { PageListView } from 'schema';
import DialogContent from 'components/DialogContent';

type Props = {
  onAdd: (data: PageListView) => void;
};

export type AddPageDialogProps = Props;

const initial = {
  title: '',
  content: null,
  slug: '',
};

const AddPageDialog = ({ onAdd }: Props) => {
  const [, { close }] = useDialog('pageAdd');
  const create = useCreatePage();

  const handleSubmit = useCallback(
    ({ content, ...rest }: Fields) => {
      const vars = {
        content: content!,
        ...rest,
      };

      create.mutate(vars, {
        onSuccess: page => {
          onAdd(page);
          close();
        },
      });
    },
    [close, create, onAdd]
  );

  return (
    <Dialog dialog="pageAdd" maxWidth="md">
      <Formik<Fields>
        initialValues={initial}
        onSubmit={handleSubmit}
        validationSchema={schema}>
        <Form>
          <DialogTitle onClose={close}>Naujas puslapis</DialogTitle>

          <DialogContent>
            <PageFormFields />
          </DialogContent>

          <DialogActions>
            <Button onClick={close} variant="text">
              Atšaukti
            </Button>

            <LoadingButton
              loading={create.isPending}
              variant="contained"
              type="submit"
              startIcon={<AddIcon />}>
              Pridėti
            </LoadingButton>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

export default AddPageDialog;
