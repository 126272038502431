import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from 'react';

import SessionEditDialog, {
  SessionEditDialogProps,
} from 'components/SessionEditDialog';
import SessionSendMessageDialog, {
  SessionSendMessageDialogProps,
} from 'components/SessionSendMessageDialog';
import SessionInviteAttendantsDialog, {
  SessionInviteAttendantsDialogProps,
} from 'components/SessionInviteAttendantsDialog';
import SessionRegistrationDialog, {
  SessionRegistrationDialogProps,
} from 'components/SessionAttendantDialog';
import AddSessionDialog, {
  AddSessionDialogProps,
} from 'components/AddSessionDialog';
import AddCategoryDialog, {
  AddCategoryDialogProps,
} from 'components/AddCategoryDialog';
import AddActivityDialog, {
  AddActivityDialogProps,
} from 'components/AddActivityDialog';
import InvoiceSyncDialog, {
  InvoiceSyncDialogProps,
} from 'components/InvoiceSyncDialog';
import HttpRequestDialog, {
  HttpRequestDialogProps,
} from 'components/HttpRequestDialog';
import ConfirmDialog, { ConfirmDialogProps } from 'components/ConfirmDialog';
import AddPageDialog, { AddPageDialogProps } from 'components/AddPageDialog';
import PageEditDialog, { PageEditDialogProps } from 'components/PageEditDialog';
import ChangeSessionDialog, {
  ChangeSessionDialogProps,
} from 'components/ChangeSessionDialog';
import InviteMemberDialog, {
  InviteMembersDialogProps,
} from 'components/InviteMemberDialog';
import AddShiftDialog, { AddShiftDialogProps } from 'components/AddShiftDialog';
import UpdateShiftDialog, {
  UpdateShiftDialogProps,
} from 'components/UpdateShiftDialog';
import AddDiscountDialog, {
  AddDiscountDialogProps,
} from 'components/AddDiscountDialog';
import AddQuantityDiscountDialog, {
  AddQuantityDiscountDialogProps,
} from 'components/AddQuantityDiscountDialog';
import EditQuantityDiscountDialog, {
  EditQuantityDiscountDialogProps,
} from 'components/EditQuantityDiscountDialog';
import SessionReservationDialog, {
  SessionReservationDialogProps,
} from 'components/SessionReservationDialog';

type ContextProps = {
  dialogs: State;
  setDialogs: Dispatch<SetStateAction<State>>;
};

type Props = {
  children: ReactNode;
};

type DialogState<ComponentProps> = {
  open: boolean;
  props?: ComponentProps;
};

// TODO this should be infered from some sort of config
type State = {
  confirm: DialogState<ConfirmDialogProps>;
  activityAdd: DialogState<AddActivityDialogProps>;
  categoryAdd: DialogState<AddCategoryDialogProps>;
  sessionAdd: DialogState<AddSessionDialogProps>;
  shiftAdd: DialogState<AddShiftDialogProps>;
  shiftUpdate: DialogState<UpdateShiftDialogProps>;
  sessionInvite: DialogState<SessionInviteAttendantsDialogProps>;
  sessionSendMessage: DialogState<SessionSendMessageDialogProps>;
  sessionEdit: DialogState<SessionEditDialogProps>;
  sessionRegistration: DialogState<SessionRegistrationDialogProps>;
  sessionReservation: DialogState<SessionReservationDialogProps>;
  pageAdd: DialogState<AddPageDialogProps>;
  pageEdit: DialogState<PageEditDialogProps>;
  invoiceSync: DialogState<InvoiceSyncDialogProps>;
  httpMessage: DialogState<HttpRequestDialogProps>;
  changeSession: DialogState<ChangeSessionDialogProps>;
  memberInvite: DialogState<InviteMembersDialogProps>;
  discountAdd: DialogState<AddDiscountDialogProps>;
  quantityDiscountAdd: DialogState<AddQuantityDiscountDialogProps>;
  quantityDiscountEdit: DialogState<EditQuantityDiscountDialogProps>;
};

type UseDialog<ComponentProps> = [
  boolean,
  {
    open: (props: ComponentProps) => void;
    close: () => void;
  }
];

export type DialogType = keyof State;

type PropType<TProp extends DialogType> = State[TProp];

const defaultDialogState = { open: false };

// TODO: automate this
const defaultState = {
  confirm: defaultDialogState,
  categoryAdd: defaultDialogState,
  activityAdd: defaultDialogState,
  sessionAdd: defaultDialogState,
  shiftAdd: defaultDialogState,
  shiftUpdate: defaultDialogState,
  sessionInvite: defaultDialogState,
  sessionSendMessage: defaultDialogState,
  sessionEdit: defaultDialogState,
  sessionRegistration: defaultDialogState,
  sessionReservation: defaultDialogState,
  pageAdd: defaultDialogState,
  pageEdit: defaultDialogState,
  invoiceSync: defaultDialogState,
  httpMessage: defaultDialogState,
  changeSession: defaultDialogState,
  memberInvite: defaultDialogState,
  discountAdd: defaultDialogState,
  quantityDiscountAdd: defaultDialogState,
  quantityDiscountEdit: defaultDialogState,
};

const DialogsContext = createContext<ContextProps>({
  dialogs: defaultState,
  setDialogs: () => {},
});

export function useDialog(
  name: DialogType
): UseDialog<PropType<typeof name>['props']> {
  const { dialogs, setDialogs } = useContext(DialogsContext);

  const open = useCallback(
    // FIXME: @robustness this type here is incorrect
    (props: PropType<typeof name>['props']) => {
      setDialogs({
        ...dialogs,
        [name]: {
          open: true,
          props,
        },
      });
    },
    [dialogs, name, setDialogs]
  );

  const close = useCallback(() => {
    setDialogs({
      ...dialogs,
      [name]: {
        open: false,
      },
    });
  }, [dialogs, name, setDialogs]);

  return [dialogs[name].open, { open, close }];
}

const Dialogs = ({ children }: Props) => {
  const [dialogs, setDialogs] = useState<State>(defaultState);

  const {
    confirm,
    sessionAdd,
    shiftAdd,
    shiftUpdate,
    sessionInvite,
    sessionSendMessage,
    sessionEdit,
    sessionRegistration,
    sessionReservation,
    pageAdd,
    pageEdit,
    invoiceSync,
    httpMessage,
    changeSession,
    memberInvite,
    discountAdd,
    quantityDiscountAdd,
    quantityDiscountEdit,
  } = dialogs;

  // TODO: automate rendering of the dialog components
  return (
    <DialogsContext.Provider value={{ dialogs, setDialogs }}>
      {children}

      {confirm.props && <ConfirmDialog {...confirm.props} />}

      {sessionInvite.props && (
        <SessionInviteAttendantsDialog {...sessionInvite.props} />
      )}

      {sessionSendMessage.props && (
        <SessionSendMessageDialog {...sessionSendMessage.props} />
      )}

      {sessionEdit.props && <SessionEditDialog {...sessionEdit.props} />}

      {sessionRegistration.props && (
        <SessionRegistrationDialog {...sessionRegistration.props} />
      )}

      {sessionReservation.props && (
        <SessionReservationDialog {...sessionReservation.props} />
      )}

      {sessionAdd.props && <AddSessionDialog {...sessionAdd.props} />}
      {shiftAdd.props && <AddShiftDialog {...shiftAdd.props} />}
      {shiftUpdate.props && <UpdateShiftDialog {...shiftUpdate.props} />}

      {pageAdd.props && <AddPageDialog {...pageAdd.props} />}
      {pageEdit.props && <PageEditDialog {...pageEdit.props} />}
      {invoiceSync.props && <InvoiceSyncDialog {...invoiceSync.props} />}
      {httpMessage.props && <HttpRequestDialog {...httpMessage.props} />}
      {changeSession.props && <ChangeSessionDialog {...changeSession.props} />}
      {memberInvite.props && <InviteMemberDialog {...memberInvite.props} />}
      {discountAdd.props && <AddDiscountDialog {...discountAdd.props} />}

      {quantityDiscountAdd.props && (
        <AddQuantityDiscountDialog {...quantityDiscountAdd.props} />
      )}

      {quantityDiscountEdit.props && (
        <EditQuantityDiscountDialog {...quantityDiscountEdit.props} />
      )}

      <AddCategoryDialog />
      <AddActivityDialog />
    </DialogsContext.Provider>
  );
};

export default Dialogs;
