import { useCallback } from 'react';
import { Form, Formik } from 'formik';
import { object, string } from 'yup';

import SendIcon from '@mui/icons-material/Send';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from 'components/DialogTitle';
import Stack from '@mui/material/Stack';
import FormTextField from 'components/FormTextField';
import FormMultiImageUpload from 'components/FormMultiImageUpload';
import Dialog from 'components/Dialog';
import DialogContent from 'components/DialogContent';

import { useSendSessionMessage } from 'features/Session';
import { useNotification } from 'components/Notifications';
import { useDialog } from 'components/Dialogs';
import { UploadField } from 'lib';
import { ActivityDetailsView, CoachSessionDetailsView } from 'schema';

type FormFields = {
  title: string;
  content: string;
  images: UploadField[];
};

type Props = {
  activity: ActivityDetailsView;
  session: CoachSessionDetailsView;
};

export type SessionSendMessageDialogProps = Props;

const initial = {
  title: '',
  content: '',
  images: [],
};

const schema = object({
  title: string().required('Prašome įvesti antraštę'),
  content: string()
    .required('Prašome įvesti textą')
    .min(3, 'Tekstas negali būti trumpesnis nei 3 simboliai'),
});

const SessionSendMessageDialog = ({ activity, session }: Props) => {
  const [, { close }] = useDialog('sessionSendMessage');
  const sendMessage = useSendSessionMessage(activity.id, session.id);
  const { pop } = useNotification();

  const handleSubmit = useCallback(
    ({ images, ...form }: FormFields) => {
      const vars = {
        form,
        files: {
          images: images
            .filter(({ uploaded }) => uploaded)
            .map(item => item.uploaded!),
        },
      };

      sendMessage.mutate(vars, {
        onSuccess: result => {
          pop(`Žinutė išsiųsta ${result.recipients} gavėjams`);
          close();
        },
      });
    },
    [close, pop, sendMessage]
  );

  return (
    <Dialog dialog="sessionSendMessage">
      <Formik<FormFields>
        initialValues={initial}
        onSubmit={handleSubmit}
        validationSchema={schema}>
        <Form>
          <DialogTitle onClose={close}>Naujas pranešimas</DialogTitle>

          <DialogContent>
            <Stack spacing={4}>
              <FormTextField name="title" label="Antraštė" />
              <FormTextField multiline name="content" label="Tekstas" />
              <FormMultiImageUpload name="images" label="Nuotraukos" />
            </Stack>
          </DialogContent>

          <DialogActions>
            <Button onClick={close} variant="text">
              Atšaukti
            </Button>

            <LoadingButton
              loading={sendMessage.isPending}
              variant="contained"
              type="submit"
              startIcon={<SendIcon />}>
              Siųsti
            </LoadingButton>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

export default SessionSendMessageDialog;
