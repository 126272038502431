import TableCell from '@mui/material/TableCell';
import SessionsRegistrationsTableRow from 'components/SessionsRegistrationsTableRow';
import Table from 'components/Table';
import TableRow from 'components/TableRow';

import { useActivitySessionRegistrations } from 'features/AttendantRegistration';
import { CoachSessionDetailsView } from 'schema';

type Props = {
  session: CoachSessionDetailsView;
};

const SessionRegistrations = ({ session }: Props) => {
  const query = useActivitySessionRegistrations(
    session.activity.id,
    session.id
  );

  return (
    <Table
      loading={query.isPending}
      head={
        <TableRow>
          <TableCell component="th">Vardas pavardė</TableCell>

          <TableCell component="th" align="center">
            Globėjas
          </TableCell>

          <TableCell component="th" align="center">
            Užsiėmimų pradžia
          </TableCell>

          <TableCell component="th" align="center">
            Registracijos data
          </TableCell>

          <TableCell component="th" align="center">
            Foto
          </TableCell>

          <TableCell component="th" align="center">
            Rinkodara
          </TableCell>

          <TableCell component="th">Pastabos</TableCell>

          <TableCell />
        </TableRow>
      }>
      {query.data?.map((registration, i) => (
        <SessionsRegistrationsTableRow
          key={i}
          session={session}
          registration={registration}
        />
      ))}
    </Table>
  );
};

export default SessionRegistrations;
