import { useCallback } from 'react';

import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent, SelectProps } from '@mui/material/Select';

import { useClub, useSetClub } from 'components/ClubProvider';
import { useMyClubs } from 'features/Club';

type Props = SelectProps<number>;

const AppClubSelect = (props: Props) => {
  const clubs = useMyClubs();
  const club = useClub();
  const setClub = useSetClub();

  const handleChange = useCallback(
    (event: SelectChangeEvent<number>) => {
      const newClub = clubs.data?.find(({ id }) => id === event.target.value);

      if (newClub) {
        setClub(newClub);
      }
    },
    [clubs.data, setClub]
  );

  if (!clubs.data || clubs.data.length < 2) return null;

  return (
    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
      <Select<number>
        fullWidth
        id="app-club-selector"
        value={club.id}
        onChange={handleChange}
        label="Klubas"
        {...props}>
        {clubs.data.map(({ id, name }) => (
          <MenuItem key={id} value={id}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default AppClubSelect;
