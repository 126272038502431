import Box, { BoxProps } from '@mui/material/Box';

type Props = BoxProps & {
  children: number;
};

const EuroPrice = ({ children, sx = [], ...props }: Props) => (
  <Box
    component="span"
    sx={[{ whiteSpace: 'nowrap' }, ...(Array.isArray(sx) ? sx : [sx])]}
    {...props}>
    {children} €
  </Box>
);

export default EuroPrice;
