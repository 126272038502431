import FormSelect, { FormSelectProps } from 'components/FormSelect';

import { useCities } from 'features/City';
import { CityListView } from 'schema';

type Props = Omit<FormSelectProps<CityListView>, 'options'>;

const CitySelect = (props: Props) => {
  const query = useCities();

  return <FormSelect {...props} options={query.data ?? []} />;
};

export default CitySelect;
