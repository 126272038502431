import Dialog from 'components/Dialog';
import SessionEditDialogContent from 'components/SessionEditDialogContent';
import SessionEditDialogSkeleton from 'components/SessionEditDialogSkeleton';

import { ActivityDetailsView, CoachSessionListView } from 'schema';
import { useActivitySession } from 'features/Activity';

type Props = {
  activity: ActivityDetailsView;
  session: CoachSessionListView;
};

export type SessionEditDialogProps = Props;

const SessionEditDialog = ({ activity, session }: Props) => {
  const query = useActivitySession(activity.id, session.id);

  return (
    <Dialog dialog="sessionEdit">
      {query.data ? (
        <SessionEditDialogContent activity={activity} session={query.data} />
      ) : (
        <SessionEditDialogSkeleton />
      )}
    </Dialog>
  );
};

export default SessionEditDialog;
