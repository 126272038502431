import { SyntheticEvent, useCallback } from 'react';
import { useField } from 'formik';

import SessionSelect, { SessionSelectProps } from 'components/SessionSelect';

type Props = Omit<SessionSelectProps, 'value' | 'onChange'> & {
  name: string;
};

const FormSessionSelect = ({ name, ...props }: Props) => {
  const [{ value }, { error, touched }, { setValue }] = useField(name);

  const hasError = Boolean(error && touched);

  const handleChange = useCallback(
    (_: SyntheticEvent, value: any) => {
      setValue(value);
    },
    [setValue]
  );

  return (
    <SessionSelect
      value={value}
      onChange={handleChange}
      error={hasError}
      helperText={hasError && error}
      {...props}
    />
  );
};

export default FormSessionSelect;
