import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import TemplateVariables from 'components/TemplateVariables';
import Section from 'components/Section';
import FormEditor from 'components/FormEditor';

import { EditorTemplateSettings } from 'schema';

type Props = {
  templateTitle: string;
  settings: EditorTemplateSettings;
};

const TemplateEditor = ({ templateTitle, settings }: Props) => (
  <Stack spacing={1}>
    <Section>
      <Typography variant="h6">Galimi kintamieji</Typography>
      <TemplateVariables variables={settings.variables} />
    </Section>

    <Section>
      <Typography variant="h6">{templateTitle}</Typography>
      <FormEditor name="template" />
    </Section>
  </Stack>
);

export default TemplateEditor;
