import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  CreatePublicHoliday,
  publicHolidayCreate,
  publicHolidayDelete,
  publicHolidayIndex,
  PublicHolidayView,
} from 'schema';
import { useErrorHandler } from 'lib';

export const publicHolidayKeys = {
  all: [{ scope: 'public-holidays' }] as const,

  lists: () => [{ ...publicHolidayKeys.all[0], entity: 'list' }] as const,
  list: () => [{ ...publicHolidayKeys.lists()[0] }] as const,
};

export const usePublicHolidays = () =>
  useQuery({ queryKey: publicHolidayKeys.list(), queryFn: publicHolidayIndex });

export const useCreatePublicHoliday = () => {
  const queryClient = useQueryClient();
  const handleError = useErrorHandler();
  const queryKey = publicHolidayKeys.list();

  return useMutation({
    mutationFn: (form: CreatePublicHoliday) => publicHolidayCreate({ form }),

    onMutate: async ({ date }) => {
      await queryClient.cancelQueries({ queryKey });

      const snapshot = queryClient.getQueryData<PublicHolidayView[]>(queryKey);

      const max =
        snapshot?.reduce(
          (current, item) => (item.id > current ? item.id : current),
          0
        ) ?? 0;

      queryClient.setQueryData<PublicHolidayView[]>(queryKey, items =>
        [{ id: max + 1, date }, ...(items ?? [])].sort(
          (a, b) => b.date.getTime() - a.date.getTime()
        )
      );

      return { snapshot };
    },

    onError: (error: any, __, context) => {
      handleError(error);
      queryClient.setQueryData(queryKey, context?.snapshot);
    },

    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });
};

export const useDeletePublicHoliday = (holiday: number) => {
  const queryClient = useQueryClient();
  const queryKey = publicHolidayKeys.list();
  const handleError = useErrorHandler();

  return useMutation({
    mutationFn: () => publicHolidayDelete({ holiday }),

    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey });

      const snapshot = queryClient.getQueryData<PublicHolidayView[]>(queryKey);

      queryClient.setQueryData<PublicHolidayView[]>(
        queryKey,
        items => items?.filter(item => item.id !== holiday) ?? []
      );

      return { snapshot };
    },

    onError: (error: any, __, context) => {
      handleError(error);
      queryClient.setQueryData(queryKey, context?.snapshot);
    },

    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });
};
