import Link from '@mui/material/Link';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton, {
  ListItemButtonBaseProps,
} from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useMe } from 'components/AuthGuard';
import UserAvatar from 'components/UserAvatar';

type Props = ListItemButtonBaseProps & {
  href: string;
};

const UserMenuItem = (props: Props) => {
  const me = useMe();

  return (
    <ListItemButton component={Link} {...props}>
      <ListItemAvatar>
        <UserAvatar single user={me} sx={{ width: 24, height: 24 }} />
      </ListItemAvatar>

      <ListItemText primary={me.fullName} />
    </ListItemButton>
  );
};

export default UserMenuItem;
