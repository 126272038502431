import AppNotCoach from 'components/AppNotCoach';
import AppSplash from 'components/AppSplash';
import { useMyClubs } from 'features/Club';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { MyClubView } from 'schema';

type CurrentClubContextType = {
  club?: MyClubView;
  setClub: (club: MyClubView) => void;
};

const CurrentClubContext = createContext<CurrentClubContextType>({
  setClub: () => {},
});

type Props = {
  children: ReactNode;
};

export const useClub = () => useContext(CurrentClubContext).club!;
export const useSetClub = () => useContext(CurrentClubContext).setClub;

const ClubProvider = ({ children }: Props) => {
  const [club, setClub] = useState<MyClubView>();
  const clubs = useMyClubs();

  useEffect(() => {
    if (!club && clubs.data) {
      setClub(clubs.data[0]);
    }
  }, [club, clubs.data]);

  if (!clubs.data || (!club && clubs.data.length > 0)) return <AppSplash />;

  if (clubs.data.length === 0) return <AppNotCoach />;

  return (
    <CurrentClubContext.Provider value={{ club, setClub }}>
      {children}
    </CurrentClubContext.Provider>
  );
};

export default ClubProvider;
