import Stack from '@mui/material/Stack';
import FormActions from 'components/FormActions';
import FormButton from 'components/FormButton';
import Section from 'components/Section';
import TemplateEditor from 'components/TemplateEditor';
import { useUpdatePersonalDataActivityAgreement } from 'features/Activity';
import { Form, Formik } from 'formik';
import { useCallback } from 'react';
import {
  ActivityDetailsView,
  EditorJSData,
  EditorTemplateSettings,
} from 'schema';

type Fields = {
  template: EditorJSData | null;
};

type Props = {
  activity: ActivityDetailsView;
  settings: EditorTemplateSettings;
};

const ActivityPersonalDataAgreementSettings = (props: Props) => {
  const { activity, settings } = props;
  const update = useUpdatePersonalDataActivityAgreement(activity.id);

  const handleSave = useCallback(
    ({ template }: Fields) => {
      if (!template) return;

      update.mutate({ template });
    },
    [update]
  );

  return (
    <Formik<Fields> initialValues={settings} onSubmit={handleSave}>
      <Form>
        <Stack spacing={2}>
          <TemplateEditor
            templateTitle="Sutikimo tekstas"
            settings={settings}
          />

          <Section>
            <FormActions>
              <FormButton loading={update.isPending} />
            </FormActions>
          </Section>
        </Stack>
      </Form>
    </Formik>
  );
};

export default ActivityPersonalDataAgreementSettings;
