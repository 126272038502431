import { ReactNode, useCallback, useMemo } from 'react';

import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';

import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import { DragEndEvent } from '@dnd-kit/core/dist/types';
import { EntityPosition } from 'schema';

type SortableItem = {
  id: number;
};

type Props = {
  items: SortableItem[];
  onSort: (items: EntityPosition[], oldIndex: number, newIndex: number) => void;
  children: ReactNode;
};

const Sortable = ({ items, onSort, children }: Props) => {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 1,
      },
    }),

    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const ids = useMemo(() => items.map(({ id }) => id), [items]);

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (over && active.id !== over.id) {
        const oldIndex = ids.indexOf(Number(active.id));
        const newIndex = ids.indexOf(Number(over.id));

        onSort(
          arrayMove(ids, oldIndex, newIndex)
            .reverse()
            .map((id, sort) => ({
              id,
              sort,
            })),
          oldIndex,
          newIndex
        );
      }
    },
    [ids, onSort]
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}>
      <SortableContext items={ids} strategy={verticalListSortingStrategy}>
        {children}
      </SortableContext>
    </DndContext>
  );
};

export default Sortable;
