import { ReactNode } from 'react';

import MuiTableRow, {
  TableRowProps as MuiTableRowProps,
} from '@mui/material/TableRow';

type Props = MuiTableRowProps & {
  children?: ReactNode;
};

export type TableRowProps = Props;

const TableRow = ({ children, sx = [], ...rest }: Props) => (
  <MuiTableRow
    sx={[
      {
        '&:last-child td': {
          borderBottom: 0,
        },
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    {...rest}>
    {children}
  </MuiTableRow>
);

export default TableRow;
