import { useCallback } from 'react';
import { Form, Formik } from 'formik';
import { number, object, string } from 'yup';

import AddIcon from '@mui/icons-material/Add';
import DialogTitle from 'components/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Dialog from 'components/Dialog';
import FormCheckbox from 'components/FormCheckbox';
import FormTextField from 'components/FormTextField';
import CategorySelect from 'components/CategorySelect';
import FormImageUpload from 'components/FormImageUpload';
import ActivityTypeSelect from 'components/ActivityTypeSelect';
import Stack from 'components/Stack';
import DialogContent from 'components/DialogContent';

import { useCreateActivity } from 'features/Activity';
import { useDialog } from 'components/Dialogs';
import { useClub } from 'components/ClubProvider';

import { ActivityType, CategoryListView } from 'schema';
import { UploadField } from 'lib';

export type FormFields = {
  type: ActivityType | '';
  payForLastMonth: boolean;
  name: string;
  shortName: string;
  description: string;
  category: CategoryListView | null;
  photo: UploadField;
  productCode: string;
};

export const schema = object({
  type: string().required('Prašome pasirinkti tipą'),
  name: string().required('Prašome įvesti būrelio pavadinimą'),
  shortName: string().required('Prašome įvesti būrelio trumpą pavadinimą'),
  category: object({
    id: number().required('Prašome pasirinkti kategoriją'),
  })
    .nullable()
    .required('Prašome pasirinkti kategoriją'),
  description: string().required('Prašome įvesti būrelio aprašymą'),
});

const initial: FormFields = {
  type: '',
  productCode: '',
  payForLastMonth: false,
  name: '',
  shortName: '',
  category: null,
  description: '',
  photo: {
    currentUrl: null,
    uploaded: null,
  },
};

export type AddActivityDialogProps = {};

function AddActivityDialog() {
  const [, { close }] = useDialog('activityAdd');
  const club = useClub();
  const create = useCreateActivity(club.id);

  const handleSubmit = useCallback(
    ({ photo, type, ...form }: FormFields) => {
      if (!type) return;

      const vars = {
        form: {
          club,
          type: type,
          ...form,
        },
        files: photo.uploaded && { photo: photo.uploaded },
      };

      create.mutate(vars, {
        onSuccess: () => {
          close();
        },
      });
    },
    [close, club, create]
  );

  return (
    <Dialog dialog="activityAdd">
      <Formik<FormFields>
        initialValues={initial}
        onSubmit={handleSubmit}
        validationSchema={schema}>
        <Form>
          <DialogTitle onClose={close}>Nauja veikla</DialogTitle>

          <DialogContent>
            <Box sx={{ mb: 2 }}>
              <Box sx={{ mb: 4 }}>
                <Typography sx={{ mb: 2 }} variant="overline">
                  Nustatymai
                </Typography>

                <Stack sx={{ mt: 2 }}>
                  <ActivityTypeSelect name="type" label="Tipas" />

                  <FormControlLabel
                    control={<FormCheckbox name="payForLastMonth" />}
                    label="Registruojantis mokama už paskutinį mėnesį"
                  />
                </Stack>
              </Box>

              <Box>
                <Typography sx={{ mb: 2 }} variant="overline">
                  Pristatymas
                </Typography>

                <Stack>
                  <FormTextField name="name" label="Pavadinimas" />

                  <FormTextField
                    name="shortName"
                    label="Trumpas pavadinimas"
                    helperText="Naudojamas atvaizdavimui kalendoriuje"
                  />

                  <CategorySelect name="category" label="Kategorija" />

                  <FormTextField
                    fullWidth
                    multiline
                    name="description"
                    label="Aprašymas"
                  />

                  <FormTextField
                    name="productCode"
                    label="Prekės kodas buhalterinėje sistemoje"
                  />

                  <FormImageUpload name="photo" label="Nuotrauka" />
                </Stack>
              </Box>
            </Box>
          </DialogContent>

          <DialogActions>
            <Button onClick={close} variant="text">
              Atšaukti
            </Button>

            <LoadingButton
              loading={create.isPending}
              variant="contained"
              type="submit"
              startIcon={<AddIcon />}>
              Pridėti
            </LoadingButton>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
}

export default AddActivityDialog;
