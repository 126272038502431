import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';

import TextField from '@mui/material/TextField';
import ClubMembersTable from 'components/ClubMembersTable';
import Page from 'components/Page';
import Section from 'components/Section';
import ToolbarButton from 'components/ToolbarButton';

import { useClub } from 'components/ClubProvider';
import { useClubMembers } from 'features/Club';
import { debounce } from 'lib/debounce';
import { useDialog } from 'components/Dialogs';

const UserList = () => {
  const [search, setSearch] = useState('');
  const [, { open }] = useDialog('memberInvite');
  const { id } = useClub();

  const members = useClubMembers(id, {
    s: search.length > 1 ? search : undefined,
  });

  const handleAddClick = useCallback(() => {
    open({});
  }, [open]);

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  }, []);

  const debouncedResults = useMemo(
    () => debounce(handleChange, 300),
    [handleChange]
  );

  useEffect(() => () => {
    debouncedResults.cancel();
  });

  return (
    <Page
      title="Vartotojai"
      actions={<ToolbarButton tooltip="Pridėti" onClick={handleAddClick} />}>
      <Section sx={{ mb: 2 }} title="Vartotojų paieška">
        <TextField
          fullWidth
          label="Paieška"
          variant="standard"
          onChange={debouncedResults}
          sx={{ mt: 2 }}
        />
      </Section>

      <ClubMembersTable
        members={members.data ?? []}
        loading={members.isPending}
      />
    </Page>
  );
};

export default UserList;
