import { useCallback } from 'react';

import ListItemText from '@mui/material/ListItemText';
import TableCell from '@mui/material/TableCell';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import ConfirmableMenuItem from 'components/ConfirmableMenuItem';
import MenuButton from 'components/MenuButton';
import MenuItem from 'components/MenuItem';
import SessionAttendantLink from 'components/SessionAttendantLink';
import TableRow from 'components/TableRow';

import { useRemoveRegistration } from 'features/AttendantRegistration';
import { useDialog } from 'components/Dialogs';
import { formatDate, truncate } from 'lib';

import {
  CoachAttendantRegistrationListView,
  CoachSessionDetailsView,
} from 'schema';

type Props = {
  session: CoachSessionDetailsView;
  registration: CoachAttendantRegistrationListView;
};

const SessionsRegistrationsTableRow = ({ session, registration }: Props) => {
  const [, { open }] = useDialog('changeSession');

  const remove = useRemoveRegistration(
    session.activity.id,
    session.id,
    registration.id
  );

  const handleChangeSessionClick = useCallback(() => {
    open({ session, registration });
  }, [open, registration, session]);

  const handleDelete = useCallback(() => {
    remove.mutate();
  }, [remove]);

  const {
    attendant,
    firstSessionDate,
    registrationDate,
    acceptsMarketing,
    acceptsPhoto,
    notes,
    coachNotes,
  } = registration;

  const { guardian } = attendant;

  return (
    <TableRow>
      <TableCell>
        <SessionAttendantLink session={session} registration={registration} />
      </TableCell>

      <TableCell align="center">{guardian.fullName}</TableCell>

      <TableCell align="center">
        {firstSessionDate && formatDate(firstSessionDate)}
      </TableCell>

      <TableCell align="center">{formatDate(registrationDate)}</TableCell>
      <TableCell align="center">{acceptsPhoto ? 'Taip' : '--'}</TableCell>
      <TableCell align="center">{acceptsMarketing ? 'Taip' : '--'}</TableCell>

      <TableCell>
        {notes ? (
          <Box>
            <Tooltip title={notes}>
              <span>G: {truncate(notes)}</span>
            </Tooltip>
          </Box>
        ) : (
          '--'
        )}

        {coachNotes ? (
          <Box>
            <Tooltip title={coachNotes}>
              <span>A: {truncate(coachNotes)}</span>
            </Tooltip>
          </Box>
        ) : (
          '--'
        )}
      </TableCell>

      <TableCell align="right">
        <MenuButton>
          <MenuItem onClick={handleChangeSessionClick}>
            <ListItemText>Keisti grupę...</ListItemText>
          </MenuItem>

          <ConfirmableMenuItem
            onConfirm={handleDelete}
            text={`Ar tikrai norite pašalinti ${attendant.fullName}`}>
            <ListItemText>Trinti</ListItemText>
          </ConfirmableMenuItem>
        </MenuButton>
      </TableCell>
    </TableRow>
  );
};

export default SessionsRegistrationsTableRow;
