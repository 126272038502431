import { useField } from 'formik';
import { OutputData } from '@editorjs/editorjs';

import Editor from 'components/Editor';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

type Props = {
  name: string;
  label?: string;
};

const FormEditor = ({ name, label }: Props) => {
  const [{ value }, { error, touched }, { setValue }] =
    useField<OutputData>(name);

  const hasError = Boolean(error && touched);

  return (
    <FormControl fullWidth>
      {label && (
        <InputLabel error={hasError} shrink sx={{ left: '-14px' }}>
          {label}
        </InputLabel>
      )}

      <Box
        sx={{
          borderBottom: ({ palette }) =>
            palette.mode === 'light'
              ? '1px solid rgba(0, 0, 0, 0.42)'
              : '1px solid rgba(255, 255, 255, 0.7)',
          mt: 1,
        }}>
        <Editor initial={value} onChange={setValue} />
      </Box>

      {hasError && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  );
};

export default FormEditor;
