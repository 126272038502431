import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import ConfirmableIconButton from 'components/ConfirmableIconButton';

import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  onDelete: () => void;
  height?: number;
  cover?: boolean;
};

export type FilePreviewProps = Props;

const FilePreview = (props: Props) => {
  const { children, onDelete, height = 240, cover = true } = props;

  return (
    <Box
      sx={[
        {
          position: 'relative',
          '& img': {
            width: '100%',
            objectFit: cover ? 'cover' : 'contain',
            height,
          },
        },
      ]}>
      <ConfirmableIconButton
        text="Ar tikrai norite pašalinti šią nuotrauką?"
        sx={{ position: 'absolute', top: 0, right: 0 }}
        onConfirm={onDelete}>
        <DeleteIcon color="error" />
      </ConfirmableIconButton>

      {children}
    </Box>
  );
};

export default FilePreview;
