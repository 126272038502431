import TableCell from '@mui/material/TableCell';
import PagesTableRow from 'components/PagesTableRow';
import Table from 'components/Table';
import TableRow from 'components/TableRow';

import { usePages } from 'features/Page';

const PagesTable = () => {
  const pages = usePages();

  return (
    <Table
      loading={pages.isPending}
      head={
        <TableRow>
          <TableCell component="th">Pavadinimas</TableCell>

          <TableCell component="th" align="center">
            Nuoroda
          </TableCell>

          <TableCell component="th" />
        </TableRow>
      }>
      {pages.data?.map(page => (
        <PagesTableRow page={page} key={page.id} />
      ))}
    </Table>
  );
};

export default PagesTable;
