import { Form } from 'formik';
import { array, object, string } from 'yup';
import { EditorJSData } from 'schema';

import SendIcon from '@mui/icons-material/Send';
import DialogTitle from 'components/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import DialogContent from 'components/DialogContent';
import Stack from 'components/Stack';
import FormMultiChipInput from 'components/FormMultiChipInput';
import FormEditor from 'components/FormEditor';

import { useDialog } from 'components/Dialogs';

export type Fields = {
  emails: string[];
  text: EditorJSData;
};

type Props = {
  loading: boolean;
};

export const schema = object({
  emails: array()
    .of(string().email('Neteisingas el. pašto formatas'))
    .min(1, 'Prašome įvesti bent vieną el. pašto adresą'),
  text: object().required('Prašome įvesti kvietimo tekstą'),
});

const SessionInviteAttendantsForm = ({ loading }: Props) => {
  const [, { close }] = useDialog('sessionInvite');

  return (
    <Form>
      <DialogTitle onClose={close}>
        Pakviesti narius prisijungti prie grupės
      </DialogTitle>

      <DialogContent>
        <Stack>
          <FormMultiChipInput name="emails" label="El. pašto adresai" />
          <FormEditor name="text" label="Kvietimo tekstas" />
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={close} variant="text">
          Atšaukti
        </Button>

        <LoadingButton
          loading={loading}
          variant="contained"
          type="submit"
          startIcon={<SendIcon />}>
          Siųsti
        </LoadingButton>
      </DialogActions>
    </Form>
  );
};

export default SessionInviteAttendantsForm;
