import { useCallback } from 'react';

import MenuIcon from '@mui/icons-material/Menu';
import List from '@mui/material/List';
import Paper, { PaperProps } from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import MenuEditorItemsItem from 'components/MenuEditorItemsItem';
import Sortable from 'components/Sortable';
import SortableItem from 'components/SortableItem';

import { useUpdateMenuItemSorting } from 'features/Menu';
import { EntityPosition, MenuDetails, MenuItemListView } from 'schema';

type Props = PaperProps & {
  menu: MenuDetails;
  selected: MenuItemListView | null;
  onSelectItem: (item: MenuItemListView) => void;
  onDelete: (item: MenuItemListView) => void;
};

const MenuEditorItems = (props: Props) => {
  const { menu, selected, onDelete, onSelectItem, ...rest } = props;
  const { menuKey, items } = menu;
  const updateSort = useUpdateMenuItemSorting(menuKey);

  const handleDrageEnd = useCallback(
    (items: EntityPosition[], oldIndex: number, newIndex: number) => {
      updateSort.mutate({
        form: { items },
        oldIndex,
        newIndex,
      });
    },
    [updateSort]
  );

  if (items.length === 0)
    return (
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          color: 'text.secondary',
        }}>
        <MenuIcon fontSize="large" />
        <Typography>Dar nėra meniu punktų</Typography>
      </Paper>
    );

  // TODO: @Cleanup fix items jumping on drop
  return (
    <Paper {...rest}>
      <Sortable items={items} onSort={handleDrageEnd}>
        <List disablePadding>
          {items.map((item, i) => (
            <SortableItem key={item.id} id={item.id}>
              <MenuEditorItemsItem
                divider={i < items.length - 1}
                selected={item.id === selected?.id}
                item={item}
                onDelete={onDelete}
                onSelectItem={onSelectItem}
              />
            </SortableItem>
          ))}
        </List>
      </Sortable>
    </Paper>
  );
};

export default MenuEditorItems;
