import {
  ChangeEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import OrdersTable from 'components/OrdersTable';
import Page from 'components/Page';
import TextField from '@mui/material/TextField';
import ActivitySelect from 'components/ActivitySelect';
import SessionSelect from 'components/SessionSelect';
import Section from 'components/Section';
import TwoColumn from 'components/TwoColumn';
import Stack from 'components/Stack';

import { useOrders } from 'features/Order';
import { debounce } from 'lib';
import { ActivityListView, OrderStatus, SessionDropdownOption } from 'schema';

type FilterParams = {
  search: string;
  activity: ActivityListView | null;
  session: SessionDropdownOption | null;
};

const CURRENT_STATUS_FILTER_KEY = 'order_list:status';

const makeFilter = ({ search, activity, session }: FilterParams) => {
  return {
    search: search.length > 0 ? search : undefined,
    activity: activity?.id,
    session: session?.id,
  };
};

const OrderList = () => {
  const [search, setSearch] = useState('');
  const [activity, setActivity] = useState<ActivityListView | null>(null);
  const [session, setSession] = useState<SessionDropdownOption | null>(null);

  const [status, setStatus] = useState<OrderStatus>(
    (localStorage.getItem(CURRENT_STATUS_FILTER_KEY) as OrderStatus) ??
      'payment'
  );

  const orders = useOrders({
    status,
    ...makeFilter({ search, activity, session }),
  });

  const handleStatusChange = useCallback((status: OrderStatus) => {
    setStatus(status);
    localStorage.setItem(CURRENT_STATUS_FILTER_KEY, status);
  }, []);

  const handleSessionChange = useCallback(
    (_: SyntheticEvent, value: SessionDropdownOption | null) => {
      setSession(value);
    },
    []
  );

  const handleActivityChange = useCallback(
    (_: SyntheticEvent, value: ActivityListView | null) => {
      setActivity(value);
    },
    []
  );

  const handleChangeSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearch(event.target.value);
    },
    []
  );

  const debouncedSearch = useMemo(
    () => debounce(handleChangeSearch, 300),
    [handleChangeSearch]
  );

  useEffect(() => () => {
    debouncedSearch.cancel();
  });

  return (
    <Page title="Užsakymai">
      <Section sx={{ mb: 2 }} title="Užsakymų paieška">
        <Stack>
          <TextField
            variant="standard"
            fullWidth
            label="Paieška"
            onChange={debouncedSearch}
          />

          <TwoColumn
            leftSlot={
              <ActivitySelect
                label="Veikla"
                value={activity}
                onChange={handleActivityChange}
              />
            }
            rightSlot={
              <SessionSelect
                label="Grupė"
                value={session}
                onChange={handleSessionChange}
              />
            }
          />
        </Stack>
      </Section>

      <OrdersTable
        filter={makeFilter({ search, activity, session })}
        orders={orders.data ?? []}
        loading={orders.isPending}
        status={status}
        onStatusChange={handleStatusChange}
      />
    </Page>
  );
};

export default OrderList;
