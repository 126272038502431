import LockIcon from '@mui/icons-material/Lock';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import { ChangeEvent, useCallback, useState } from 'react';
import { useUpdateClubMemberPassword } from 'features/Club';
import { useClub } from 'components/ClubProvider';
import { ClubMemberDetails } from 'schema';

type Props = {
  member: ClubMemberDetails;
};

const UserDetailsPassword = ({ member }: Props) => {
  const [password, setPassword] = useState('');
  const change = useUpdateClubMemberPassword(useClub().id, member.id);

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setPassword(event.target.value);
    },
    []
  );

  const handleChangeClick = useCallback(() => {
    change.mutate({ password });
    setPassword('');
  }, [change, password]);

  return (
    <Box>
      <Typography variant="h6">Slaptažodis</Typography>

      <Box sx={{ display: 'flex', mt: 2, alignItems: 'center' }}>
        <TextField
          fullWidth
          type="password"
          variant="standard"
          label="Naujas slaptažodis"
          value={password}
          onChange={handleInputChange}
          sx={{ mr: 2 }}
        />

        <Box>
          <LoadingButton
            loading={change.isPending}
            disabled={password.length === 0}
            startIcon={<LockIcon />}
            onClick={handleChangeClick}>
            Keisti
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

export default UserDetailsPassword;
