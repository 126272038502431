import Box, { BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';

type Props = BoxProps & {
  label: string;
};

const InfoBoxWithLabel = ({ label: lable, children, ...props }: Props) => (
  <Box {...props}>
    <Typography variant="caption">{lable}</Typography>
    <Typography>{children}</Typography>
  </Box>
);

export default InfoBoxWithLabel;
