import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { ReactNode } from 'react';

type Props = Omit<
  AutocompleteProps<string, true, false, true>,
  'renderInput' | 'options'
> & {
  label?: string;
  error?: boolean;
  helperText?: ReactNode;
};

export type MultiChipInputProps = Props;

const MultiChipInput = ({ label, error, helperText, ...props }: Props) => (
  <Autocomplete<string, true, false, true>
    multiple
    freeSolo
    options={[]}
    renderInput={params => (
      <TextField
        {...params}
        variant="standard"
        label={label}
        error={error}
        helperText={helperText || 'Įvedimui spausti ENTER'}
      />
    )}
    {...props}
  />
);

export default MultiChipInput;
