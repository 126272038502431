import { useParams } from 'react-router-dom';

import AttendantPersonalInfo from 'components/AttendantPersonalInfo';
import Page from 'components/Page';
import PageSkeleton from 'components/PageSkeleton';
import Tabber from 'components/Tabber';
import TabberSkeleton from 'components/TabberSkeleton';

import { useAttendant } from 'features/Attendant';
import Tabs from 'components/Tabs/Tabs';
import AttendantRegistrations from 'components/AttendantRegistrations';

const AttendantDetails = () => {
  const { attendant } = useParams();
  const query = useAttendant(Number(attendant));

  if (!query.data)
    return (
      <PageSkeleton>
        <TabberSkeleton tabs={4} />
      </PageSkeleton>
    );

  return (
    <Tabs
      tabs={[
        {
          title: 'Asmens informacija',
          component: <AttendantPersonalInfo attendant={query.data} />,
          hash: 'personal',
          paper: false,
        },
        {
          title: 'Lankomos grupės',
          component: <AttendantRegistrations attendant={query.data} />,
          hash: 'sessions',
        },
        {
          title: 'Veiksmų istorija',
          component: <div>Istorija</div>,
          hash: 'history',
        },
      ]}>
      <Page title={query.data.fullName}>
        <Tabber />
      </Page>
    </Tabs>
  );
};

export default AttendantDetails;
