import Box, { BoxProps } from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import Table from 'components/Table';
import TableRow from 'components/TableRow';
import HttpRequestRow from 'components/HttpRequestRow';

import { HttpMessageView } from 'schema';

type Props = BoxProps & {
  messages: HttpMessageView[];
  loading?: boolean;
};

const HttpRequestTable = ({ messages, loading = false, ...props }: Props) => (
  <Box {...props}>
    <Typography variant="caption">HTTP Užklausos</Typography>

    <Table
      loading={loading}
      size="small"
      head={
        <TableRow>
          <TableCell>URL</TableCell>
          <TableCell align="center">Metodas</TableCell>
          <TableCell align="center">HTTP Statusas</TableCell>
          <TableCell align="right">Data</TableCell>
        </TableRow>
      }>
      {messages.map(message => (
        <HttpRequestRow message={message} />
      ))}
    </Table>
  </Box>
);

export default HttpRequestTable;
