import { useClub } from 'components/ClubProvider';
import Page from 'components/Page';
import PageSkeleton from 'components/PageSkeleton';
import UserDetails from 'components/UserDetails';
import { useClubMember } from 'features/Club';
import { useParams } from 'react-router-dom';

const UserDetailsPage = () => {
  const { user } = useParams();
  const userId = Number(user);
  const query = useClubMember(useClub().id, userId);

  if (!query.data) return <PageSkeleton>Kraunasi...</PageSkeleton>;

  return (
    <Page title={query.data.user.fullName ?? 'Vartotojas'}>
      <UserDetails member={query.data} />
    </Page>
  );
};

export default UserDetailsPage;
