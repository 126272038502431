import {
  QueryFunctionContext,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useNotification } from 'components/Notifications';
import { discountsKeys } from 'features/Discount';
import { useErrorHandler } from 'lib';
import {
  ActivityQuantityDiscountIndexInput,
  ActivityQuantityDiscountShowInput,
  QuantityDiscountForm,
  QuantityDiscountView,
  activityQuantityDiscountCreate,
  activityQuantityDiscountDelete,
  activityQuantityDiscountIndex,
  activityQuantityDiscountShow,
  activityQuantityDiscountUpdate,
} from 'schema';

export const quantityDiscountKeys = {
  all: [{ scope: 'quantity-discount' }] as const,

  lists: () => [{ ...quantityDiscountKeys.all[0], entity: 'list' }] as const,
  list: (params: ActivityQuantityDiscountIndexInput) =>
    [{ ...quantityDiscountKeys.lists()[0], params }] as const,

  details: () =>
    [{ ...quantityDiscountKeys.all[0], entity: 'detail' }] as const,
  detail: (params: ActivityQuantityDiscountShowInput) =>
    [{ ...quantityDiscountKeys.details()[0], params }] as const,
};

type QuantityDiscountListContext = QueryFunctionContext<
  ReturnType<(typeof quantityDiscountKeys)['list']>
>;

type QuantityDiscountDetailContext = QueryFunctionContext<
  ReturnType<(typeof quantityDiscountKeys)['detail']>
>;

export const useQuantityDiscounts = (activity: number) =>
  useQuery({
    queryKey: quantityDiscountKeys.list({ activity }),
    queryFn: async ({ queryKey: [{ params }] }: QuantityDiscountListContext) =>
      await activityQuantityDiscountIndex(params),
  });

export const useQuantityDiscount = (activity: number, discount: number) =>
  useQuery({
    queryKey: quantityDiscountKeys.detail({ activity, discount }),
    queryFn: async ({
      queryKey: [{ params }],
    }: QuantityDiscountDetailContext) =>
      await activityQuantityDiscountShow(params),
  });

export const useCreateQuantityDiscount = (activity: number) => {
  const client = useQueryClient();
  const queryKey = quantityDiscountKeys.list({ activity });

  return useMutation({
    mutationFn: (form: QuantityDiscountForm) =>
      activityQuantityDiscountCreate({ activity, form }),

    onSuccess: discount => {
      client.setQueryData<QuantityDiscountView[]>(queryKey, items =>
        [...(items ?? []), discount].sort((a, b) => a.quantity - b.quantity)
      );

      client.invalidateQueries({ queryKey });
    },

    onError: useErrorHandler(),
  });
};

export const useUpdateQuantityDiscount = (
  activity: number,
  discount: number
) => {
  const client = useQueryClient();
  const queryKey = quantityDiscountKeys.detail({ activity, discount });
  const { pop } = useNotification();

  return useMutation({
    mutationFn: (form: QuantityDiscountForm) =>
      activityQuantityDiscountUpdate({ activity, discount, form }),

    onSuccess: discount => {
      client.setQueryData<QuantityDiscountView>(queryKey, discount);

      client.invalidateQueries({ queryKey });
      client.invalidateQueries({
        queryKey: quantityDiscountKeys.list({ activity }),
      });

      pop('Išsaugota');
    },

    onError: useErrorHandler(),
  });
};

export const useDeleteQuantityDiscount = (
  activity: number,
  discount: number
) => {
  const client = useQueryClient();
  const queryKey = quantityDiscountKeys.list({ activity });
  const handleError = useErrorHandler();

  return useMutation({
    mutationFn: () => activityQuantityDiscountDelete({ activity, discount }),

    onMutate: async () => {
      await client.cancelQueries({ queryKey });

      const snapshot = client.getQueryData<QuantityDiscountView[]>(queryKey);

      client.setQueryData<QuantityDiscountView[]>(
        queryKey,
        items => items?.filter(item => item.id !== discount) ?? []
      );

      return { snapshot };
    },

    onError: (error: any, __, context) => {
      handleError(error);
      client.setQueryData(queryKey, context?.snapshot);
    },

    onSettled: () => {
      client.invalidateQueries({
        queryKey: discountsKeys.lists(),
      });
    },
  });
};
