import TableCell from '@mui/material/TableCell';
import AttendantSurveyTableRow from 'components/AttendantSurveyTableRow';
import Table, { TableProps } from 'components/Table';
import TableRow from 'components/TableRow';
import { AttendantSurveyListView } from 'schema';

type Props = TableProps & {
  surveys: AttendantSurveyListView[];
};

const AttendantSurveyTable = ({ surveys, ...props }: Props) => (
  <Table
    head={
      <TableRow>
        <TableCell component="th">Vardas pavardė</TableCell>

        <TableCell component="th" align="center">
          Globėjas
        </TableCell>

        <TableCell component="th" align="center">
          Tyrimo data
        </TableCell>

        <TableCell component="th" align="center">
          Komentaras
        </TableCell>

        <TableCell component="th" align="right" />
      </TableRow>
    }
    {...props}>
    {surveys.map(item => (
      <AttendantSurveyTableRow key={item.id} survey={item} />
    ))}
  </Table>
);

export default AttendantSurveyTable;
