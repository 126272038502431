import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SessionAttendantDialogForm from 'components/SessionAttendantDialogForm';
import SessionAttendantResult from 'components/SessionAttendantResult';
import AddSessionAttendantResult from 'components/AddSessionAttendantResult';
import DialogContent from 'components/DialogContent';

import { useActivitySessionRegistration } from 'features/AttendantRegistration';
import {
  CoachAttendantRegistrationListView,
  CoachSessionDetailsView,
} from 'schema';

type Props = {
  session: CoachSessionDetailsView;
  registration: CoachAttendantRegistrationListView;
};

const SessionAttendantDialogContent = ({ session, registration }: Props) => {
  const query = useActivitySessionRegistration(
    session.activity.id,
    session.id,
    registration.id
  );

  if (!query.data) {
    return <DialogContent>Kraunasi...</DialogContent>;
  }

  const { results } = query.data;

  return (
    <DialogContent>
      <Typography sx={{ fontWeight: 700 }}>Lankytojo duomenys</Typography>
      <SessionAttendantDialogForm session={session} registration={query.data} />

      <Box sx={{ mt: 2 }}>
        <Typography sx={{ fontWeight: 700 }}>Rezultatai</Typography>

        <AddSessionAttendantResult
          session={session}
          registration={registration}
        />

        <Box sx={{ mt: 2 }}>
          {results.length > 0 ? (
            results.map(result => (
              <SessionAttendantResult
                key={result.id}
                result={result}
                sx={{ mb: 1 }}
              />
            ))
          ) : (
            <>Nieko nėra</>
          )}
        </Box>
      </Box>
    </DialogContent>
  );
};

export default SessionAttendantDialogContent;
