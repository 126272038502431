import LinkIcon from '@mui/icons-material/Link';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useNotification } from 'components/Notifications';
import { useCallback } from 'react';
import { DiscountListView } from 'schema';

type Props = IconButtonProps & {
  discount: DiscountListView;
};

const DiscountLinkButton = ({ discount, ...props }: Props) => {
  const { pop } = useNotification();

  const handleClick = useCallback(() => {
    navigator.clipboard.writeText(discount.link);

    pop('Nuoroda nukopijuota');
  }, [discount.link, pop]);

  return (
    <IconButton size="small" {...props} onClick={handleClick}>
      <Tooltip title="Kopijuoti nuorodą">
        <LinkIcon fontSize="small" />
      </Tooltip>
    </IconButton>
  );
};

export default DiscountLinkButton;
