import Select, { SelectOption, SelectProps } from 'components/Select';

import { useField } from 'formik';
import { SyntheticEvent, useCallback } from 'react';

type Props<T> = SelectProps<T> & {
  name: string;
};

export type FormSelectProps<T> = Props<T>;

function FormSelect<T extends SelectOption>(props: Props<T>) {
  const { name, ...rest } = props;

  const [{ value }, { error, touched }, { setValue }] = useField<T | null>(
    name
  );

  const hasError = Boolean(error && touched);

  const handleChange = useCallback(
    (_: SyntheticEvent, value: T | null) => {
      setValue(value);
    },
    [setValue]
  );

  return (
    <Select<T>
      value={value}
      onChange={handleChange}
      error={hasError}
      helperText={hasError && error}
      {...rest}
    />
  );
}

export default FormSelect;
