import { useCallback } from 'react';
import { useField } from 'formik';

import DatePicker, { DatePickerProps } from 'components/DatePicker';

type Props = Omit<DatePickerProps, 'onChange' | 'value'> & {
  name: string;
};

function FormDatePicker({ name, ...rest }: Props) {
  const [{ value }, { error, touched }, { setValue }] = useField<Date | null>(
    name
  );
  const hasError = Boolean(error && touched);

  const handleChange = useCallback(
    (value: Date | null) => {
      setValue(value);
    },
    [setValue]
  );

  return (
    <DatePicker
      fullWidth
      error={hasError}
      helperText={hasError ? error : undefined}
      onChange={handleChange}
      value={value}
      {...rest}
    />
  );
}

export default FormDatePicker;
