import { FieldArray, useField } from 'formik';

import AddIcon from '@mui/icons-material/Add';
import InputAdornment from '@mui/material/InputAdornment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import FormTextField from 'components/FormTextField';
import EarlyBirdPricesFieldRow from 'components/EarlyBirdPricesFieldRow';
import { ShiftView } from 'schema';

export type EarlyBirdPrice = {
  validUntil: Date;
  price: string;
};

const EarlyBirdPricesField = () => {
  const [{ value: prices }] = useField<EarlyBirdPrice[]>('prices');
  const [{ value: shift }] = useField<ShiftView>('shift');

  return (
    <Table size="small">
      <TableBody>
        <TableRow sx={{ '& td': { border: 0 } }}>
          <TableCell colSpan={2} sx={{ pl: 0 }}>
            Pagrindinė kaina
          </TableCell>

          <TableCell sx={{ pr: 0 }}>
            <FormTextField
              fullWidth
              name="price"
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
              }}
            />
          </TableCell>
        </TableRow>

        {shift && (
          <FieldArray
            name="prices"
            render={({ push, remove }) => (
              <>
                {prices.map((item, i) => (
                  <EarlyBirdPricesFieldRow
                    key={i}
                    index={i}
                    onDelete={remove}
                  />
                ))}

                <TableRow sx={{ '& td': { border: 0 } }}>
                  <TableCell colSpan={3} sx={{ pr: 0 }} align="right">
                    <Button
                      startIcon={<AddIcon />}
                      onClick={() => {
                        push({ validUntil: null, price: '' });
                      }}>
                      Pridėti kainą
                    </Button>
                  </TableCell>
                </TableRow>
              </>
            )}
          />
        )}
      </TableBody>
    </Table>
  );
};

export default EarlyBirdPricesField;
