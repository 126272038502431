import TableCell from '@mui/material/TableCell';
import AttendantsTableRow from 'components/AttendantsTableRow';
import Table, { TableProps } from 'components/Table';
import TableRow from 'components/TableRow';

import { ClubAttendantListView } from 'schema';

type Props = TableProps & {
  attendants: ClubAttendantListView[];
};

const AttendantsTable = ({ attendants, ...props }: Props) => (
  <Table
    head={
      <TableRow>
        <TableCell component="th">Vardas pavardė</TableCell>

        <TableCell component="th" align="center">
          Globėjas
        </TableCell>

        <TableCell component="th" align="center">
          Paskyra
        </TableCell>

        <TableCell component="th" align="right">
          Registruotas
        </TableCell>
      </TableRow>
    }
    {...props}>
    {attendants.map(attendant => (
      <AttendantsTableRow key={attendant.id} attendant={attendant} />
    ))}
  </Table>
);

export default AttendantsTable;
