import { useCallback } from 'react';
import { useField } from 'formik';

import SimpleSelect, { SimpleSelectProps } from 'components/SimpleSelect';

import { SelectChangeEvent } from '@mui/material/Select';

type Props<T extends string = string> = Omit<
  SimpleSelectProps<T>,
  'value' | 'onChange'
> & {
  name: string;
};

export type FormSimpleSelectProps<T extends string = string> = Props<T>;

const FormSimpleSelect = ({ name, ...props }: Props) => {
  const [{ value }, { error, touched }, { setValue }] = useField(name);

  const handelChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      setValue(event.target.value);
    },
    [setValue]
  );

  return (
    <SimpleSelect
      value={value}
      onChange={handelChange}
      error={Boolean(error && touched)}
      helperText={error}
      {...props}
    />
  );
};

export default FormSimpleSelect;
