import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';

import TextField from '@mui/material/TextField';
import AttendantsTable from 'components/AttendantsTable';
import Page from 'components/Page';
import Section from 'components/Section';
import ClubAttendantsExportButton from 'components/ClubAttendantsExportButton';

import { useAttendants } from 'features/Attendant';
import { debounce } from 'lib';

const AttendantList = () => {
  const [search, setSearch] = useState('');
  const attendants = useAttendants({ search });

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  }, []);

  const debouncedResults = useMemo(
    () => debounce(handleChange, 300),
    [handleChange]
  );

  useEffect(() => () => {
    debouncedResults.cancel();
  });

  return (
    <Page title="Nariai" actions={<ClubAttendantsExportButton />}>
      <Section sx={{ mb: 2 }} title="Narių paieška">
        <TextField
          variant="standard"
          fullWidth
          label="Paieška"
          onChange={debouncedResults}
        />
      </Section>

      <AttendantsTable
        loading={attendants.isPending}
        attendants={attendants.data ?? []}
      />
    </Page>
  );
};

export default AttendantList;
