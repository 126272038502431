import { DropzoneOptions, useDropzone } from 'react-dropzone';

import UploadFileIcon from '@mui/icons-material/UploadFile';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

type Props = DropzoneOptions & {
  loading?: boolean;
  secondaryText?: string;
};

const DropZone = (props: Props) => {
  const {
    loading,
    secondaryText = 'Norėdami įkelti nuotrauką tempkite failą arba spauskite čia',
    ...rest
  } = props;

  const { getRootProps, getInputProps, isDragActive } = useDropzone(rest);

  return (
    <Box
      {...getRootProps()}
      sx={{
        borderColor: 'text.secondary',
        borderStyle: 'dashed',
        borderWidth: 1,
        textAlign: 'center',
        p: 2,
        color: 'text.secondary',
      }}>
      <input {...getInputProps()} />

      {loading ? (
        <CircularProgress size={36} />
      ) : (
        <UploadFileIcon fontSize="large" />
      )}

      {isDragActive ? <Box>Įkelti?...</Box> : <Box>{secondaryText}</Box>}
    </Box>
  );
};

export default DropZone;
