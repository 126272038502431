import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

import { TemplateVariable } from 'schema';

type Props = {
  variables: TemplateVariable[];
};

const TemplateVariables = ({ variables }: Props) => (
  <TableContainer>
    <Table>
      <TableBody>
        {variables.map(({ name, description }) => (
          <TableRow key={name}>
            <TableCell>{name}</TableCell>
            <TableCell>{description}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export default TemplateVariables;
