import AttendantRegistrationsTable from 'components/AttendantRegistrationsTable';
import { useAttendantRegistrations } from 'features/Attendant';
import { ClubAttendantDetails } from 'schema';

type Props = {
  attendant: ClubAttendantDetails;
};

const AttendantRegistrations = ({ attendant }: Props) => {
  const registrations = useAttendantRegistrations(attendant.id);

  return (
    <AttendantRegistrationsTable
      registrations={registrations.data ?? []}
      loading={registrations.isPending}
    />
  );
};

export default AttendantRegistrations;
