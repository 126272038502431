import { useCallback, useState } from 'react';

import Grid from '@mui/material/Unstable_Grid2';
import MenuEditorAddItem from 'components/MenuEditorAddItem';
import MenuEditorContent from 'components/MenuEditorContent';
import MenuEditorItems from 'components/MenuEditorItems';

import { useCreateMenuItem, useDeleteMenuItem, useMenu } from 'features/Menu';
import { MenuItemListView, MenuKey } from 'schema';
import MenuEditorUnselected from 'components/MenuEditorUnselected';

type Props = {
  menuKey: MenuKey;
};

const MenuEditor = ({ menuKey }: Props) => {
  const [selected, setSelected] = useState<MenuItemListView | null>(null);
  const menu = useMenu(menuKey);
  const add = useCreateMenuItem(menuKey);
  const destroy = useDeleteMenuItem(menuKey);

  const handleDelete = useCallback(
    (item: MenuItemListView) => {
      destroy.mutate(item.id);
    },
    [destroy]
  );

  if (!menu.data) return <>Kraunasi...</>;

  return (
    <Grid container spacing={2}>
      <Grid xs={3} sx={{ display: 'flex', flexDirection: 'column' }}>
        <MenuEditorAddItem
          menuKey={menuKey}
          onAdd={add.mutate}
          sx={{ mb: 1 }}
        />

        <MenuEditorItems
          selected={selected}
          menu={menu.data}
          onDelete={handleDelete}
          onSelectItem={setSelected}
        />
      </Grid>

      <Grid xs={9} sx={{ display: 'flex', flexDirection: 'column' }}>
        {selected ? (
          <MenuEditorContent menuKey={menuKey} item={selected} />
        ) : (
          <MenuEditorUnselected />
        )}
      </Grid>
    </Grid>
  );
};

export default MenuEditor;
