import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ToolbarButton from 'components/ToolbarButton';

import { useDownload } from 'lib';
import { useClub } from 'components/ClubProvider';

const ClubAttendantsExportButton = () => {
  const [download, isDownloading] = useDownload(
    `/api/v1/clubs/${useClub().id}/attendants/export` // TODO: @Hardcode
  );

  return (
    <ToolbarButton
      loading={isDownloading}
      tooltip="Narių exportas"
      onClick={download}>
      <FileDownloadIcon />
    </ToolbarButton>
  );
};

export default ClubAttendantsExportButton;
