import { useCallback } from 'react';
import { Form, Formik } from 'formik';
import { object, string } from 'yup';

import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import FormActions from 'components/FormActions';
import Section from 'components/Section';
import MenuEditorContentForm, {
  Fields,
} from 'components/MenuEditorContentForm';

import { useMenuItem, useUpdateMenuItem } from 'features/Menu';
import { MenuItemListView, MenuKey } from 'schema';

type Props = {
  menuKey: MenuKey;
  item: MenuItemListView;
};

const schema = object({
  name: string().required('Prašome įvesti pavadinimą'),

  type: string()
    .required('Prašome pasirinkti tipą')
    .oneOf(['page', 'external']),

  page: object().when('type', {
    is: 'page',
    then: schema => schema.required('Prašome pasirinkti meniu puslapį'),
    otherwise: schema => schema.notRequired(),
  }),

  url: string().when('type', {
    is: 'external',
    then: schema =>
      schema.required('Prašome įvesti puslapio nuorodą').test({
        test: value => value.startsWith('https://'),
        message: 'Išorinio puslapio nuoroda turi prasidėti https://',
      }),
    otherwise: schema => schema.notRequired(),
  }),
});

const MenuEditorContent = ({ menuKey, item }: Props) => {
  const menuItem = useMenuItem(menuKey, item.id);
  const update = useUpdateMenuItem(menuKey, item.id);

  const handleSubmit = useCallback(
    (values: Fields) => {
      update.mutate(values);
    },
    [update]
  );

  if (!menuItem.data) return <>Kraunasi...</>;

  const { url, ...rest } = menuItem.data;

  const initial = {
    url: url ?? '',
    ...rest,
  };

  return (
    <Formik<Fields>
      enableReinitialize
      initialValues={initial}
      onSubmit={handleSubmit}
      validationSchema={schema}>
      <Form>
        <MenuEditorContentForm item={menuItem.data} sx={{ mb: 2 }} />

        <Section>
          <FormActions>
            <LoadingButton
              type="submit"
              variant="contained"
              startIcon={<SaveIcon />}
              loading={update.isPending}>
              Saugoti
            </LoadingButton>
          </FormActions>
        </Section>
      </Form>
    </Formik>
  );
};

export default MenuEditorContent;
