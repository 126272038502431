import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Table, { TableProps } from 'components/Table';

import { ActivityDetailsView, CoachShiftListView } from 'schema';
import SessionTableShiftBlock from 'components/SessionTableShiftBlock';

type Props = TableProps & {
  activity: ActivityDetailsView;
  shifts: CoachShiftListView[];
};

const SessionsTable = ({ shifts, activity, ...props }: Props) => (
  <Table
    head={
      <TableRow>
        <TableCell component="th">Pavadinimas</TableCell>

        <TableCell component="th" align="center">
          Užpildymas
        </TableCell>

        <TableCell component="th" align="center">
          Miestas
        </TableCell>

        <TableCell component="th" align="center">
          Treneris
        </TableCell>

        <TableCell component="th" align="center">
          Kainodara
        </TableCell>

        <TableCell component="th" align="center">
          Publikuota
        </TableCell>

        <TableCell component="th" align="right"></TableCell>
      </TableRow>
    }
    {...props}>
    {shifts.map(shift => (
      <SessionTableShiftBlock
        key={shift.id}
        activity={activity}
        shift={shift}
      />
    ))}
  </Table>
);

export default SessionsTable;
