import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { ConfirmDialogProps, useConfirm } from 'components/ConfirmDialog';

type Props = ConfirmDialogProps & IconButtonProps;

const ConfirmableIconButton = (props: Props) => {
  const { onConfirm, title, text, ...rest } = props;
  const confirm = useConfirm({ onConfirm, title, text });

  return <IconButton onClick={confirm} {...rest} />;
};

export default ConfirmableIconButton;
