import { ReactNode } from 'react';

import Paper, { PaperProps } from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

type Props = PaperProps & {
  title?: ReactNode;
};

export type SectionProps = Props;

const Section = ({ sx = [], title, children, ...props }: Props) => (
  <Paper sx={[{ p: 2 }, ...(Array.isArray(sx) ? sx : [sx])]} {...props}>
    {title && <Typography variant="h6">{title}</Typography>}
    {children}
  </Paper>
);

export default Section;
