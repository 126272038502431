import { useCallback } from 'react';
import { Form, Formik } from 'formik';

import SaveIcon from '@mui/icons-material/Save';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import FormTextField from 'components/FormTextField';

import { formatDate } from 'lib';
import { useUpdateRegistration } from 'features/AttendantRegistration';

import {
  CoachAttendantRegistrationDetails,
  CoachSessionDetailsView,
} from 'schema';
import SessionAttendantDialogAgreement from 'components/SessionAttendantDialogAgreement';

type Fields = {
  notes: string;
};

type Props = {
  session: CoachSessionDetailsView;
  registration: CoachAttendantRegistrationDetails;
};

const SessionAttendantDialogForm = ({ session, registration }: Props) => {
  const { attendant, startDate, notes, orderAttendant } = registration;
  const { guardian } = attendant;

  const update = useUpdateRegistration(
    session.activity.id,
    session.id,
    registration.id
  );

  const initial = {
    notes: registration.coachNotes ?? '',
  };

  const handleSubmit = useCallback(
    (values: Fields) => {
      update.mutate(values);
    },
    [update]
  );

  return (
    <Formik<Fields> initialValues={initial} onSubmit={handleSubmit}>
      <Form>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Gimimo data</TableCell>
              <TableCell>{formatDate(attendant.dateOfBirth)}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Globėjas</TableCell>
              <TableCell>
                <Box>
                  <Typography>{guardian.fullName}</Typography>
                  <Typography>El. p.: {guardian.email}</Typography>

                  {guardian.phone && (
                    <Typography>Tel. nr.: {guardian.phone}</Typography>
                  )}
                </Box>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Grupėje nuo</TableCell>
              <TableCell>{formatDate(startDate)}</TableCell>
            </TableRow>

            {notes && (
              <TableRow>
                <TableCell>Globėjo pastabos</TableCell>
                <TableCell>{notes}</TableCell>
              </TableRow>
            )}

            {orderAttendant && (
              <TableRow>
                <TableCell>Sutartis</TableCell>

                <TableCell>
                  <SessionAttendantDialogAgreement attendant={orderAttendant} />
                </TableCell>
              </TableRow>
            )}

            <TableRow>
              <TableCell>Administratoriaus pastabos</TableCell>

              <TableCell>
                <FormTextField multiline fullWidth name="notes" rows={2} />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell colSpan={2} align="right">
                <LoadingButton
                  type="submit"
                  variant="contained"
                  size="small"
                  loading={update.isPending}
                  startIcon={<SaveIcon />}>
                  Saugoti
                </LoadingButton>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Form>
    </Formik>
  );
};

export default SessionAttendantDialogForm;
