import { useField } from 'formik';

import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';

type Props = Omit<CheckboxProps, 'checked' | 'onChange'> & {
  name: string;
};

const FormCheckbox = (props: Props) => {
  const [field] = useField(props.name);

  return <Checkbox {...props} {...field} checked={field.value} />;
};

export default FormCheckbox;
