import Link from '@mui/material/Link';
import TableCell from '@mui/material/TableCell';
import TableRow from 'components/TableRow';
import UserWithContacts from 'components/UserWithContacts';

import { formatDate } from 'lib';
import { ClubAttendantListView } from 'schema';

type Props = {
  attendant: ClubAttendantListView;
};

const AttendantsTableRow = ({ attendant }: Props) => (
  <TableRow>
    <TableCell>
      <Link href={attendant.id.toString()}>{attendant.fullName}</Link>
    </TableCell>

    <TableCell align="center">
      <UserWithContacts user={attendant.guardian} />
    </TableCell>

    <TableCell align="center">{attendant.user ? 'Taip' : '--'}</TableCell>

    <TableCell align="right">
      {attendant.createdAt && formatDate(attendant.createdAt)}
    </TableCell>
  </TableRow>
);

export default AttendantsTableRow;
