import Grid from '@mui/material/Unstable_Grid2';
import ActivityCard from 'components/ActivityCard';
import Page from 'components/Page';
import PageSkeleton from 'components/PageSkeleton';
import ActivityCardSkeleton from 'components/ActivityCardSkeleton';
import ToolbarButton from 'components/ToolbarButton';

import { useDialog } from 'components/Dialogs';
import { useCallback } from 'react';
import { useClubActivities } from 'features/Club';
import { useClub } from 'components/ClubProvider';

const ActivitiesList = () => {
  const query = useClubActivities(useClub().id);
  const [, { open }] = useDialog('activityAdd');

  const handleClick = useCallback(() => {
    open({});
  }, [open]);

  if (!query.data)
    return (
      <PageSkeleton>
        <Grid container spacing={2}>
          <ActivityCardSkeleton />
          <ActivityCardSkeleton />
          <ActivityCardSkeleton />
        </Grid>
      </PageSkeleton>
    );

  return (
    <Page
      title="Veiklos"
      actions={<ToolbarButton tooltip="Nauja veikla" onClick={handleClick} />}>
      <Grid container spacing={2}>
        {query.data.map(activity => (
          <ActivityCard key={activity.id} activity={activity} />
        ))}
      </Grid>
    </Page>
  );
};

export default ActivitiesList;
