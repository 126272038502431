import TableCell from '@mui/material/TableCell';
import AttendantSurveyTableRowDownload from 'components/AttendantSurveyTableRowDownload';
import TableRow from 'components/TableRow';

import { formatDateTime } from 'lib';
import { AttendantSurveyListView } from 'schema';

type Props = {
  survey: AttendantSurveyListView;
};

const AttendantSurveyTableRow = ({ survey }: Props) => (
  <TableRow>
    <TableCell>{survey.fullName}</TableCell>
    <TableCell align="center">{survey.guardianFullName}</TableCell>

    <TableCell align="center">
      {formatDateTime(survey.createdAt, true)}
    </TableCell>

    <TableCell align="center">{survey.comment}</TableCell>

    <TableCell align="right">
      <AttendantSurveyTableRowDownload survey={survey} />
    </TableCell>
  </TableRow>
);

export default AttendantSurveyTableRow;
