import { useCallback } from 'react';
import { object, string } from 'yup';
import { Form, Formik } from 'formik';

import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import FormTextField from 'components/FormTextField';

import { useNotification } from 'components/Notifications';
import { useAddSessionResult } from 'features/AttendantRegistration';

import {
  CoachAttendantRegistrationListView,
  CoachSessionDetailsView,
} from 'schema';

type FormFields = {
  text: string;
};

type Props = {
  session: CoachSessionDetailsView;
  registration: CoachAttendantRegistrationListView;
};

const schema = object({
  text: string()
    .required('Prašome įvesti tekstą')
    .min(3, 'Tekstas negali būti trumpesnis nei 3 simboliai'),
});

const initial = {
  text: '',
};

const AddSessionAttendantResult = ({ session, registration }: Props) => {
  const add = useAddSessionResult(
    session.activity.id,
    session.id,
    registration.id
  );

  const { pop } = useNotification();

  const handleSubmit = useCallback(
    (values: FormFields) => {
      add.mutate(values, {
        onSuccess: () => {
          pop('Rezultatas pridėtas');
        },
      });
    },
    [add, pop]
  );

  return (
    <Formik<FormFields>
      initialValues={initial}
      onSubmit={handleSubmit}
      validationSchema={schema}>
      <Form>
        <Box sx={{ mt: 2, mb: 2 }}>
          <FormTextField name="text" multiline fullWidth label="Tekstas" />

          <Box sx={{ display: 'flex', flexDirection: 'row-reverse', mt: 1 }}>
            <LoadingButton
              variant="contained"
              type="submit"
              size="small"
              startIcon={<AddIcon />}
              loading={add.isPending}>
              Pridėti naują rezultatą
            </LoadingButton>
          </Box>
        </Box>
      </Form>
    </Formik>
  );
};

export default AddSessionAttendantResult;
