import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

const SessionDetailsSkeleton = () => (
  <Paper>
    <Skeleton variant="rectangular" height={300} />

    <Box sx={{ p: 2 }}>
      <Typography variant="h6">
        <Skeleton width={250} />
      </Typography>

      <Typography sx={{ pb: 2 }} color="text.secondary">
        <Skeleton width={300} />
      </Typography>

      <Typography variant="h6">
        <Skeleton width={200} />
      </Typography>

      <Typography sx={{ pb: 2 }} color="text.secondary">
        <Skeleton width={400} />
      </Typography>

      <Typography variant="h6">
        <Skeleton width={250} />
      </Typography>

      <Typography sx={{ pb: 2 }} color="text.secondary">
        <Skeleton width={150} />
      </Typography>
    </Box>
  </Paper>
);

export default SessionDetailsSkeleton;
