import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectProps } from '@mui/material/Select';
import { useCallback } from 'react';

export type Option<T extends string> = {
  value: T;
  label: string;
};

type Props<T extends string> = Omit<
  SelectProps<T>,
  'renderValue' | 'defaultValue'
> & {
  options: readonly Option<T>[];
  helperText?: string;
};

export type SimpleSelectProps<T extends string> = Props<T>;

function SimpleSelect<T extends string>(props: Props<T>) {
  const { id, label, error, helperText, options, ...rest } = props;

  const handleChange = useCallback(() => {}, []);

  return (
    <FormControl fullWidth>
      <InputLabel id={id} sx={{ left: '-14px' }}>
        {label}
      </InputLabel>

      <Select<T>
        labelId={id}
        id={id}
        onChange={handleChange}
        variant="standard"
        error={error}
        {...rest}>
        {options.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>

      {helperText && (
        <FormHelperText error={error} sx={{ ml: 0 }}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}

export default SimpleSelect;
