import { useCallback } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import TableCell from '@mui/material/TableCell';
import ConfirmableIconButton from 'components/ConfirmableIconButton';
import SessionInvitationResendButton from 'components/SessionInvitationResendButton';
import TableRow from 'components/TableRow';
import TableRowActions from 'components/TableRowActions';
import { useDeleteInvitation } from 'features/AttendantInvitation';

import { formatDateTime } from 'lib';
import {
  CoachSessionDetailsView,
  CoachSessionInvitationListView,
} from 'schema';

type Props = {
  session: CoachSessionDetailsView;
  invitation: CoachSessionInvitationListView;
};

const SessionsInvitationsTableRow = ({ session, invitation }: Props) => {
  const { user, created } = invitation;
  const remove = useDeleteInvitation(
    session.activity.id,
    session.id,
    invitation.id
  );

  const handleRemoveClick = useCallback(() => {
    remove.mutate();
  }, [remove]);

  return (
    <TableRow>
      <TableCell>{user.fullName}</TableCell>
      <TableCell align="center">{user.email}</TableCell>
      <TableCell align="center">{formatDateTime(created)}</TableCell>

      <TableCell align="right">
        <TableRowActions>
          <SessionInvitationResendButton
            session={session}
            invitation={invitation}
          />

          <ConfirmableIconButton
            onConfirm={handleRemoveClick}
            size="small"
            text="Ar tikrai norite ištrinti šį pakvietimą">
            <DeleteIcon fontSize="small" color="error" />
          </ConfirmableIconButton>
        </TableRowActions>
      </TableCell>
    </TableRow>
  );
};

export default SessionsInvitationsTableRow;
