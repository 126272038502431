import { useCallback } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import Link from '@mui/material/Link';
import TableCell from '@mui/material/TableCell';
import ConfirmableIconButton from 'components/ConfirmableIconButton';
import TableRow, { TableRowProps } from 'components/TableRow';

import { useDeleteDiscount } from 'features/Discount';
import { useClub } from 'components/ClubProvider';
import { formatDateTime } from 'lib';

import { ClubDiscountIndexParams, DiscountListView } from 'schema';
import TableRowActions from 'components/TableRowActions';
import DiscountLinkButton from 'components/DiscountLinkButton';
import DiscountStatus from 'components/DiscountStatus';

type Props = TableRowProps & {
  discount: DiscountListView;
  searchParams: ClubDiscountIndexParams;
};

const DiscountTableRow = ({ discount, searchParams, ...props }: Props) => {
  const { id, code, discountText, createdAt, expiresAt, quantity, used } =
    discount;

  const remove = useDeleteDiscount(useClub().id, id, searchParams);

  const handleDelete = useCallback(() => {
    remove.mutate();
  }, [remove]);

  return (
    <TableRow {...props}>
      <TableCell>
        <DiscountStatus discount={discount} />
      </TableCell>

      <TableCell>
        <Link href={id.toString()}>{code}</Link>
      </TableCell>

      <TableCell align="center">{discountText}</TableCell>
      <TableCell align="center">{formatDateTime(createdAt)}</TableCell>

      <TableCell align="center">
        {expiresAt && formatDateTime(expiresAt)}
      </TableCell>

      <TableCell align="center">{quantity ?? '--'}</TableCell>
      <TableCell align="center">{used}</TableCell>

      <TableCell align="right">
        <TableRowActions>
          <DiscountLinkButton discount={discount} sx={{ mr: 1 }} />

          <ConfirmableIconButton
            text={`Ar tikrai norite ištrinti "${code}"`}
            onConfirm={handleDelete}>
            <DeleteIcon fontSize="small" color="error" />
          </ConfirmableIconButton>
        </TableRowActions>
      </TableCell>
    </TableRow>
  );
};

export default DiscountTableRow;
