import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';

import TextField from '@mui/material/TextField';
import Section from 'components/Section';
import DiscountTable from 'components/DiscountTable';
import Page from 'components/Page';
import ToolbarButton from 'components/ToolbarButton';

import { useDiscounts } from 'features/Discount';
import { useClub } from 'components/ClubProvider';
import { useDialog } from 'components/Dialogs';
import { debounce } from 'lib';

const DiscountList = () => {
  const [, { open }] = useDialog('discountAdd');
  const [search, setSearch] = useState('');
  const searchParams = search ? { s: search } : {};
  const discounts = useDiscounts(useClub().id, searchParams);

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  }, []);

  const debouncedResults = useMemo(
    () => debounce(handleChange, 300),
    [handleChange]
  );

  const handleAddClick = useCallback(() => {
    open({});
  }, [open]);

  useEffect(() => () => {
    debouncedResults.cancel();
  });

  return (
    <Page
      title="Nuolaidų kuponai"
      actions={
        <ToolbarButton tooltip="Naujas kuponas" onClick={handleAddClick} />
      }>
      <Section sx={{ mb: 2 }} title="Kuponų paieška">
        <TextField
          variant="standard"
          fullWidth
          label="Paieška"
          onChange={debouncedResults}
        />
      </Section>

      <DiscountTable
        discounts={discounts.data ?? []}
        loading={discounts.isPending}
        searchParams={searchParams}
      />
    </Page>
  );
};

export default DiscountList;
