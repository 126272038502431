import Box, { BoxProps } from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import { formatAgo } from 'lib/dates';
import { SessionResultView } from 'schema';

type Props = BoxProps & {
  result: SessionResultView;
};

const SessionAttendantResult = ({ result, ...props }: Props) => (
  <Box {...props}>
    <Typography sx={{ color: 'text.secondary' }}>
      {formatAgo(result.created)}
    </Typography>

    <Typography>{result.text}</Typography>

    <Divider sx={{ mt: 1 }} />
  </Box>
);

export default SessionAttendantResult;
