import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Divider from '@mui/material/Divider';
import MemberRoles from 'components/MemberRoles';
import ClubAvatar from 'components/ClubAvatar';
import UserAvatar from 'components/UserAvatar';

import { MemberInvitationDetails } from 'schema';

type Props = {
  invitation: MemberInvitationDetails;
};

const avatarSize = 112;

const MemberInvitationInfo = ({ invitation }: Props) => {
  const { user, club, existingRoles, roles } = invitation;
  const hasExistingRoles = existingRoles.isAdmin || existingRoles.isCoach;

  return (
    <>
      <Grid container>
        <Grid xs={5} sx={{ textAlign: 'center' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            {user && (
              <UserAvatar
                user={user}
                sx={{
                  fontSize: 64,
                  width: avatarSize,
                  height: avatarSize,
                  mt: 1,
                  mb: 2,
                }}
              />
            )}

            {user && <Typography>{user.fullName}</Typography>}
          </Box>
        </Grid>

        <Grid
          xs={2}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <ArrowForwardIosIcon />
        </Grid>

        <Grid xs={5} sx={{ textAlign: 'center' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            <ClubAvatar
              club={club}
              sx={{ width: avatarSize, height: avatarSize, mt: 1, mb: 2 }}
            />

            <Typography>{club.name}</Typography>
          </Box>
        </Grid>
      </Grid>

      <Divider sx={{ mt: 2, mb: 2 }} />

      {hasExistingRoles && (
        <MemberRoles
          roles={existingRoles}
          title="Esamos teisės:"
          sx={{ mb: 1 }}
        />
      )}

      <MemberRoles
        roles={roles}
        title={hasExistingRoles ? 'Naujos teisės:' : 'Vartotojo teisės:'}
      />

      <Divider sx={{ mt: 2, mb: 2 }} />
    </>
  );
};

export default MemberInvitationInfo;
