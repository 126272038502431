import { useClub } from 'components/ClubProvider';
import FormSelect, { FormSelectProps } from 'components/FormSelect';

import { useClubCoaches } from 'features/Club';
import { CoachBaseView } from 'schema';

type Props = Omit<FormSelectProps<CoachBaseView>, 'options'>;

const CoachSelect = (props: Props) => {
  const club = useClub();
  const query = useClubCoaches(club.id);

  return <FormSelect {...props} options={query.data ?? []} />;
};

export default CoachSelect;
